import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { FaTruck } from 'react-icons/fa';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material'
import axios from 'axios';
import { useEffect } from 'react';
import LorryWala from './ReportTable';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'Auto',
    color: theme.palette.text.secondary,
}));



const Reports = () => {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open1 = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl(null);
    };



    return (
        <Box sx={{ flexGrow: 1, width: '100%' }}>
            <Typography style={{ fontSize: '1.6rem', fontWeight: '500', color: 'black', marginBottom: 10 }} >Reports Schedule</Typography>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <div className='reportsBox' >
                        <Item style={{ height: '14.5rem' }} elevation={0}>
                            <div style={{ borderBottom: '1.1px solid lightgray', borderBottomStyle: 'dashed', height: '4.5rem', width: '100%', display: 'flex', alignItems: 'center' }} >
                                <div style={{ display: 'flex', alignItems: 'center' }} >
                                    <div style={{ width: '3rem', height: '3rem', borderRadius: '50%', border: '1.5px solid rgb(40, 251, 251) ', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                        <FaTruck size={20} style={{ color: 'rgb(40, 251, 251)' }} />
                                    </div>
                                    <Typography style={{ fontSize: 16, fontWeight: '600', color: '#132526', marginLeft: 7 }} >Transport Details</Typography>
                                </div>
                            </div>
                            <div>
                                <Typography style={{ fontSize: 16, color: 'black', marginTop: 17 }} >
                                    This report provides details of all drivers and vehicles which added by cargo Owners or cargo Brokers.
                                </Typography>
                            </div>
                            <Typography style={{ marginTop: 17, color: '#132526', fontWeight: '600', cursor: 'pointer' }} > <div>
                                <Typography
                                    style={{ marginTop: 17, color: '#132526', fontWeight: '600', cursor: 'pointer' }}
                                    id="basic-button"
                                    aria-controls={open1 ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open1 ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    Show More <KeyboardArrowRightIcon />
                                </Typography>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open1}
                                    onClose={handleClose1}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={() => {
                                        handleClickOpen()
                                        handleClose1()
                                    }}>Cargo Owners</MenuItem>
                                    <MenuItem onClick={handleClose}>Broker Associate</MenuItem>

                                </Menu>
                            </div> </Typography>
                        </Item>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className='reportsBox' >
                        <Item style={{ height: '14.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', }} elevation={0}>

                        </Item>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className='reportsBox' >
                        <Item style={{ height: '14.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', }} elevation={0}>

                        </Item>
                    </div>
                </Grid>
            </Grid>
            <div>
                <Dialog
                    open={open}
                    maxWidth='lg'
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Use Google's location service?"}
                    </DialogTitle>
                    <DialogContent style={{ width: '68rem', height: '94vh' }} >
                        <DialogContentText id="alert-dialog-description">
                            <LorryWala />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Disagree</Button>
                        <Button onClick={handleClose} autoFocus>
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Box>
    );
}


export default Reports