import React, { useState } from 'react';
import axios from 'axios';
import { FaExclamationTriangle, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import loading from '../../../GlobleImageContainer/images/Rolling.gif'
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal'
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';


const Signup = () => {

    const navigate = useNavigate()

    const [firstName, setFirstName] = useState('')
    const [mobile, setMobile] = useState('')
    const [email, setEmail] = useState('')
    const [lastName, setLastName] = useState('')
    const [userType, setUserType] = useState('Customer')
    const [isLinkSent, setIsLinkSent] = useState(false)
    const [iSLinkWait, setIsLinkWait] = useState()
    const [location, setLocation] = useState('Hyderabad')

    const UserExistError = (err) => {
        Swal.fire({
            title: "Error",
            text: 'User is already existing !',
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
        }).then((result) => {
            if (result.value) {
                return
            } else {
                return
            }
        });
    }


    const registerCreate = () => {
        setIsLinkWait(true)
        axios.post('https://pmapi.emedha.in/user/register',

            { firstName, lastName, mobile, email, userType, location }).then((res) => {
                if (res.data.status === 200) {
                    sessionStorage.setItem('client_id', res.data.client_id)
                    setIsLinkSent(true)
                    setIsLinkWait(false)
                }
                if (res.data.status === 409) {
                    UserExistError()
                    setIsLinkWait(false)
                }
            }).catch((err) => {
                alert(err, 'please contect to admin')
                setIsLinkWait(false)
            })
    }



    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {

        event.preventDefault()

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            registerCreate()
        }
        setValidated(true);
    };

    const [error, setError] = useState(false)


    return (
        <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
            {
                (error !== false) && (
                    <center>
                        <div className='error1 mb-4' >
                            <FaTimes onClick={() => setError(false)} style={{ position: 'absolute', top: '0.4rem', right: '0.4rem', cursor: 'pointer' }} />
                            <p className='text-start'><FaExclamationTriangle className='text-danger' />  <span style={{ position: 'relative', top: '0.13rem', left: '0.2rem' }}>{error}</span></p>
                        </div>
                    </center>
                )
            }
            <a href='../' ></a>
            <div className='p-3'>
                <div className='col-8 mx-auto' style={{ marginTop: '14rem' }} >
                    <div className='row signup-con' style={{ background: 'white', borderRadius: '6px' }} >
                        <div className='col-lg-6' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgb(41, 174, 227)', borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px' }} >
                            <div>
                                <div className='d-none d-md-flex' style={{ width: '100%', display: 'flex', justifyContent: 'center' }} >
                                    <center><img style={{ width: '300px', borderRadius: '50%' }} src="https://img.freepik.com/free-vector/sign-page-abstract-concept-illustration_335657-3875.jpg?w=1380&t=st=1693134615~exp=1693135215~hmac=8aeb0c9d03bdbbb414b4ff7cf4f98fe4a3411663917be0cbb1f1cac9a27d2e68" /></center>
                                </div>
                                <div className='d-md-none d-flex' >
                                    <center><img style={{ width: '290px', borderRadius: '50%' }} src='https://img.freepik.com/free-vector/sign-page-abstract-concept-illustration_335657-3875.jpg?w=1380&t=st=1693134615~exp=1693135215~hmac=8aeb0c9d03bdbbb414b4ff7cf4f98fe4a3411663917be0cbb1f1cac9a27d2e68' /></center>
                                </div>
                                <h4 style={{ fontWeight: '700', fontSize: '1.2rem', textAlign: 'center', marginBottom: 13, color: 'white', marginTop: 13 }} >Our trucks save you bucks</h4>
                                <Typography style={{ padding: 17 }} >
                                    <Typography style={{ fontSize: '0.9rem', color: 'white', textAlign: 'center', lineHeight: '1.2rem' }} >Find truck loads online PAN-India and maximize the profits of your transport business with return loads for</Typography>
                                    <Typography style={{ fontSize: '0.9rem', color: 'white', textAlign: 'center', lineHeight: '1.2rem' }} >all your trucks!</Typography>
                                </Typography>
                            </div>
                        </div>
                        <div className='col-lg-6' style={{ borderRadius: '5px', padding: 46 }} >
                            <center><img src={require('../../../GlobleImageContainer/images/PrimeMoovers.png')} style={{ width: '100px' }} /></center>
                            <h3 style={{ textAlign: 'center', color: '#023A51', fontSize: '28px', marginBottom: '1.3rem', fontWeight: '900', fontSize: '1.4rem', textShadow: '0.3px 0.5px 0.3px rgb(41, 174, 227)' }} >Prime Moovers Signup</h3>
                            <Form noValidate validated={validated} onSubmit={handleSubmit} >
                                <Row>
                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Form.Label style={{ marginBottom: '0.7rem', fontWeight: '500', color: 'black' }} >First name</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="First name"
                                            style={{ padding: '0.9rem', height: '52px', border: '2px solid lightgray', color: '#023A51', fontWeight: '500', letterSpacing: '0.08rem', outline: 'none', borderRadius: '5px' }}
                                            onChange={(e) => {
                                                setFirstName(e.target.value)
                                            }}


                                        />

                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                                        <Form.Label style={{ marginBottom: '0.7rem', fontWeight: '500', color: 'black' }} >Last name</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Last name"
                                            style={{ padding: '0.9rem', height: '52px', border: '2px solid lightgray', color: '#023A51', fontWeight: '500', letterSpacing: '0.08rem', outline: 'none', borderRadius: '5px' }}
                                            onChange={(e) => {
                                                setLastName(e.target.value)
                                            }}

                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                                        <Form.Label style={{ marginBottom: '0.7rem', fontWeight: '500', color: 'black' }} >Email</Form.Label>
                                        <InputGroup hasValidation>

                                            <Form.Control
                                                type="email"
                                                placeholder="Email"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                style={{ padding: '0.9rem', height: '52px', border: '2px solid lightgray', color: '#023A51', fontWeight: '500', letterSpacing: '0.08rem', outline: 'none', borderRadius: '5px' }}
                                                onChange={(e) => {
                                                    setEmail(e.target.value)
                                                }}

                                            />

                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                                        <Form.Label style={{ marginBottom: '0.7rem', fontWeight: '500', color: 'black' }} >Mobile</Form.Label>
                                        <Form.Control
                                            required
                                            type="number"
                                            placeholder="Mobile"
                                            style={{ padding: '0.9rem', height: '52px', border: '2px solid lightgray', color: '#023A51', fontWeight: '500', letterSpacing: '0.08rem', outline: 'none', borderRadius: '5px' }}
                                            onChange={(e) => {
                                                setMobile(e.target.value)
                                            }}

                                        />

                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom05">
                                        <Form.Label style={{ marginBottom: '0.7rem', fontWeight: '500', color: 'black' }} >Location</Form.Label>
                                        <Form.Select style={{ paddingLeft: '0.9rem', height: '52px', border: '2px solid lightgray', color: '#023A51', fontWeight: '500', letterSpacing: '0.08rem', outline: 'none', borderRadius: '5px' }}
                                            onChange={(e) => {
                                                setLocation(e.target.value)
                                            }}
                                            aria-label="Default select example">
                                            <option value='' selected disabled >Location</option>
                                            <option value="Hyderabad">Hyderabad</option>
                                            <option value="Bengaluru">Bengaluru</option>
                                            <option value="Chennai">Chennai</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom05">
                                        <Form.Label style={{ marginBottom: '0.7rem', fontWeight: '500', color: 'black' }} >User Type</Form.Label>
                                        <Form.Select style={{ paddingLeft: '0.9rem', height: '52px', border: '2px solid lightgray', color: '#023A51', fontWeight: '500', letterSpacing: '0.08rem', outline: 'none', borderRadius: '5px' }}
                                            onChange={(e) => {
                                                setUserType(e.target.value)
                                            }}

                                            aria-label="Default select example">
                                            <option value='' selected disabled >Select User Type</option>
                                            <option value="Customer">Retail Customer</option>
                                            <option value="Customer">Corporate Customer</option>
                                            <option value="LorryBroker">Broker Associate</option>
                                            <option value="LorryWala">Cargo Owner</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Row>


                                <div style={{ marginTop: 34 }} >
                                    {
                                        isLinkSent && (
                                            <Fade className='shdo' >
                                                <div style={{ width: '100%', height: '3.2rem', paddingLeft: 11, paddingRight: 11, borderLeft: '3px solid rgb(41, 174, 227)', marginBottom: 6, backgroundColor: 'rgb(241, 250, 253)', display: 'flex', alignItems: 'center', position: 'relative' }} >
                                                    <div onClick={() => setIsLinkSent(false)} style={{ cursor: 'pointer', position: 'absolute', right: '-2rem', top: '0', width: '30px', height: '30px', backgroundColor: 'rgb(241, 250, 253)', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                                        <CloseTwoToneIcon color='red' />
                                                    </div>
                                                    <p style={{ fontSize: 14, letterSpacing: '0.07rem' }} className='text-success mt-3'>Dear, {firstName} {lastName} Email SuccessFully Sent to your email</p>
                                                </div>
                                            </Fade>
                                        )
                                    }
                                    <button disabled={isLinkSent} style={{ width: '100%', height: '55px', borderRadius: '0.13rem', backgroundColor: !iSLinkWait ? 'rgb(41, 174, 227)' : 'gray', color: 'white', border: 'none', fontWeight: 'bold' }} >{
                                        iSLinkWait ? (
                                            <img src={loading} height='43px' width='43px' style={{ opacity: '0.8' }} />
                                        ) : (
                                            "Signup"
                                        )

                                    }</button>
                                    <br />
                                    <br />
                                    <br />
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '-0.4rem' }} >
                                        <div style={{ width: '45%', borderBottom: '1.8px solid #004952' }} ></div>
                                        <p onClick={() => navigate('/sent-password-link')} style={{ position: 'relative', top: 6, color: 'rgb(69, 75, 75)', fontWeight: '400' }} >or</p>
                                        <div style={{ width: '45%', borderBottom: '1.8px solid #004952' }} ></div>
                                    </div>

                                    <p onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: '16px', textAlign: 'center', fontWeight: '400', color: '#023A51', marginTop: '1.1rem' }} >You have an account? <span style={{ color: 'rgb(41, 174, 227)', fontSize: '16px' }} >Login</span></p>

                                </div>
                            </Form>

                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default Signup