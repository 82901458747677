import React from 'react'
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Signup from './Container/Auth/Signup/Signup/Signup'
import './App.css'
import LorryBrokerDashboard from './Container/dashboard/lorry_broker_dashboard/LorryBrokerDashboard'
import CostomerDashboard from './Container/dashboard/customer_dashboard/CostomerDashboard'
import WelcomeAdmin from './Container/AdminDashBoard/WelcomeAdmin.js/WelcomeAdmin'
import ResetPassword from './Container/Auth/Credential/resetPassword/ResetPassword'
import UserEmail from './Container/Auth/Credential/EmailPassword/EmailPassword'
import ChangePassword from './Container/Auth/Credential/setPassword/ChangePassword'
import UserRegLinkVerification from './Container/Auth/userregisterlinkverifiaction/UserRegLinkVerification'
import ForgotPassLinkVerification from './Container/Auth/forgotpasswordlinkverification/PasswordLinkVerification'
import { UserLogin } from './Container/Auth/userLogin/UserLogin'

const App = () => {




   return (
      <div className='app_style' >

         <BrowserRouter>
            <Routes>
               <Route path='/admin-dashboard' element={<WelcomeAdmin />} />
               <Route path='/customer-dashboard' element={<CostomerDashboard />} />
               <Route path='/lorry-broker-dashboard' element={<LorryBrokerDashboard />} />
               <Route path='/lorry-wala-dashboard' element={<LorryBrokerDashboard />} />
               <Route path='/' element={<UserLogin />} />
               <Route path='/signup' element={<Signup />} />
               <Route path='/sent-password-link' element={<UserEmail />} />
               <Route path='/user-verification/:userType/:token/:cur_pass/:client_id' element={<UserRegLinkVerification />} />
               <Route path='/password-link-verification/:userType/:client_id/:token' element={<ForgotPassLinkVerification />} />
               <Route path='/reset-password/:client_id/:userType' element={<ResetPassword />} />
               <Route path='/change-password/:client_id/:userType' element={<ChangePassword />} />
            </Routes>
         </BrowserRouter>
      </div>
   )
}

export default App