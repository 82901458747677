import React from 'react'
import { BsPersonCircle } from 'react-icons/bs'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
 
import './index.css'
import DashShowProfile from '../DashBoardShowProfile/DashboardShowProfile'
import { Typography } from '@mui/material'
import { FaMapMarkerAlt } from 'react-icons/fa'
const Profile = (props) => {

    const navigation = useNavigate()

    const [profile, setProfile] = useState('0')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [userType, setUserType] = useState('')
    const [aadhar, setAadhar] = useState('')
    const [gst, setGst] = useState('')
    const [firm, setFirm] = useState('')
    const [business, setBusiness] = useState()
    const [newGst, setNewGst] = useState('')
    const [type, setType] = useState(sessionStorage.getItem('type'))

    const [newEmail, setNewEmail] = useState()
    const [newMobile, setNewMbile] = useState()



    const updateProfile = () => {
        axios.post('https://pmapi.emedha.in/update/profile', {
            email,
            firstName,
            lastName,
            mobile,
            userType
        }).then((res) => {
            if (res.data.status === 200) {
                alert(res.data.message)
                sessionStorage.setItem('email', email)
            }
        })
    }


    return (
        <div className='p-3' >
            <div className='card' style={{ alignItems: 'center', justifyContent: 'center', height: '100vh', marginTop: 32, boxShadow: 'none', flexDirection: 'row' }}  >
                <div className='card' style={{ borderLeft: '5px solid #30d0fc', alignItems: 'center', width: '30rem', height: '38rem', justifyContent: 'center', paddingTop: 30, paddingLeft: 10, paddingRight: 10 }} >
                    <h3 style={{ position: 'relative', top: '-0.7rem', fontWeight: 'bold' }} >User Profile</h3>
                    <img src='https://th.bing.com/th/id/OIP.OH-uY0l4j27fxLaa_Q8hGwHaHa?pid=ImgDet&w=178&h=178&c=7&dpr=2.5' width='150px' />
                    <Typography style={{color:'#232363'}} ><FaMapMarkerAlt/> {props.location}</Typography>-
                    <div className='w-100' ><DashShowProfile /></div>
                    <h1 style={{ fontSize: 25 }}>{props.firstName} {props.lastName}</h1>
                    <Typography style={{ fontSize: 17 }}>{sessionStorage.getItem('type') === 'Customer' && (
                        'Corporate Customer'
                    ) }
                    {sessionStorage.getItem('type') === 'LorryWala' && (
                        'Cargo Owner'
                    ) }
                    {sessionStorage.getItem('type') === 'LorryBroker' && (
                        'Broker Associate'
                    ) }</Typography>
                    
                </div>
                <div className='card' style={{ alignItems: 'center', width: '30rem', height: '38rem', justifyContent: 'center', paddingTop: 30, paddingLeft: 10, paddingRight: 10, backgroundColor: '#30d0fc', borderRight: '5px solid white' }} >
                    <div style={{marginBottom:20}}>
                        <h3 style={{ position: 'relative', top: '-0.7rem', fontWeight: 'bold', color: 'white' }} >User Information</h3>
                        <div style={{ width: '7.2rem', borderBottom: '3px solid white',position:'relative',top:-13 }} ></div>
                         
                    </div>
                    <div>
                        <h3 style={{ fontSize: 14, marginBottom: 45, borderBottom: '1px solid lightgray', fontWeight: 'bold', color: 'white' }}> <span style={{ fontSize: 16 }} >GST Number:</span>  <span style={{ fontSize: 16, marginLeft: 38 }}> {props.newGst}</span></h3>
                        <h3 style={{ fontSize: 14, marginBottom: 45, borderBottom: '1px solid lightgray', fontWeight: 'bold', color: 'white' }}><span style={{ fontSize: 16 }} >Aadhar Number:</span>  <span style={{ fontSize: 16, marginLeft: 38 }}> {props.aadhar}</span></h3>
                        <h3 style={{ fontSize: 14, marginBottom: 45, borderBottom: '1px solid lightgray', fontWeight: 'bold', color: 'white' }}> <span style={{ fontSize: 16 }}>Email:</span>  <span style={{ fontSize: 16, marginLeft: 38 }}> {props.newEmail}</span></h3>
                        <h3 style={{ fontSize: 14, marginBottom: 45, borderBottom: '1px solid lightgray', fontWeight: 'bold', color: 'white' }}> <span style={{ fontSize: 16 }} >Mobile Number:</span>  <span style={{ fontSize: 16, marginLeft: 38 }}> {props.newMobile}</span></h3>
                        <h3 style={{ fontSize: 14, marginBottom: 45, borderBottom: '1px solid lightgray', fontWeight: 'bold', color: 'white' }}> <span style={{ fontSize: 16 }}>Business:</span>  <span style={{ fontSize: 16, marginLeft: 38 }}> {props.business}</span></h3>
                        <h3 style={{ fontSize: 14, marginBottom: 45, borderBottom: '1px solid lightgray', fontWeight: 'bold', color: 'white' }}> <span style={{ fontSize: 16 }}>Firm:</span>  <span style={{ fontSize: 16, marginLeft: 38 }}> {props.firm}</span></h3>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Profile