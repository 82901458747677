import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FaCheck, FaEdit, FaHandPeace, FaPencilAlt, FaSearchLocation, FaUser } from 'react-icons/fa';
import { useAppStore } from '../AppStore/AppStore';
import CustomerDashBody from '../CustomerDashBody/CustomerDashBody';
import UpdateKyc from '../../../UpdateKyc/UpdateKyc';
import Profile from '../../../Profile/Profile';
import CustomerDashboardHomePage from '../CustomerDashboardHomePage/CustomerDashboardHomePage';
import { Button, Grid } from '@mui/material';
import { useEffect } from 'react'
import axios from 'axios';
import { Typography } from '@mui/material';
import CreateBids from '../../../create bids/CreateBids';
import SimpleBackdrop from '../../../BackDrop/BackDrop';
import TransporterConfirmedBids from '../TransporterConfirmedBids/TransporterConfirmedBids';
import Paper from 'material-ui/Paper';
import DashShowProfile from '../../../DashBoardShowProfile/DashboardShowProfile';


const drawerWidth = 256;

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'Auto',
    color: theme.palette.text.secondary,
}));



const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});




const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


export default function Sidebar() {


    const [open3, setOpen3] = React.useState(false);

    const [isAlert, setAlert] = React.useState()



    useEffect(() => {
        kycStatus()
        setOpen3(true)
        setOpen3(true)
    }, [])

    const [isUpdateKyc, setIsUpdateKyc] = useState(false)
    const [isCreateBids, setisCreateBids] = useState(false)
    const [isProfile, setIsProfile] = useState(false)
    const [lastName, setLastName] = useState()
    const [profileItem, setIsprfileItem] = useState(false)

    useEffect(() => {

        setTimeout(() => {

        })
    }, [])


    const [mobile, setMobile] = useState('')
    const [firstName, setFirstName] = useState('')
    const [aadhar, setAadhar] = useState('')
    const [firm, setFirm] = useState('')
    const [business, setBusiness] = useState()
    const [newGst, setNewGst] = useState('')
    const [newEmail, setNewEmail] = useState()
    const [newMobile, setNewMbile] = useState()
    const [email, setEmail1] = useState()
    const [location, setLocation] = useState()

    const userName = () => {
        const email = sessionStorage.getItem('email')
        axios.post('https://pmapi.emedha.in/profile', {
            email
        }).then(async (res) => {
            setFirstName(res.data.first_name)
            setLastName(res.data.last_name)
            await setFirstName(res.data.first_name)
            setLastName(res.data.last_name)
            const CCEncoded2 = "XXXX " + res.data.email.substring(res.data.email.length + 4, 6);
            setEmail1(res.data.email)
            setNewEmail(CCEncoded2)
            const CCEncoded3 = "XX XXXX " + res.data.mobile.substring(res.data.mobile.length + 4, 6);
            setNewMbile(CCEncoded3)
            setMobile(res.data.mobile)
            setLocation(res.data.location)
            setBusiness(res.data.business)
            setFirm(res.data.firm)
            setAadhar(res.data.aadhar)
            const CCEncoded = "XXX XXXX XXXX " + res.data.gst.substring(res.data.gst.length + 4, 10);
            setNewGst(CCEncoded)

        })
    }


    useEffect(() => {
        userName()
    }, [])

    const [wait, setWait] = useState(true)

    setTimeout(() => setWait(false), 1000)


    const [isDashboard, setIsDashboard] = useState(true)

    const [open, setOpen] = useState(true)

    const [isBidoffer, setIsBidoffer] = useState(false)
    const [isConfirmedBids, setIsConfirmedBids] = useState(false)
    const [isKeySuccess, setIsKycSuccess] = useState(false)

    const [isAv, setIsAv] = useState(true)
    const [isAg, setIsAg] = useState(true)

    const kycStatus = () => {
        const email = sessionStorage.getItem('email')
        const type = sessionStorage.getItem('type')
        axios.post('https://pmapi.emedha.in/users/kyc/status', {
            email,
            type
        }).then((res) => {
            if ((res.data.isAadhar === 1 && res.data.isAddGst === 1) || (res.data.isAadhar === 1 && res.data.isAddGst === 2)) {
            } else {
                setTimeout(() => setAlert(true))
            }

            if ((res.data.isAadhar === 1) && (res.data.isAddGst === 1 || res.data.isAddGst === 2)) {
                setIsKycSuccess(true)
            } else {
                if (res.data.isAadhar === 0) {
                    setTimeout(() => setAlert(true))
                    setIsKycSuccess(false)
                    setIsAv(false)
                }
                if (res.data.isAddGst === 0) {
                    setTimeout(() => setAlert(true))
                    setIsKycSuccess(false)
                    setIsAg(false)
                }
            }
        })
    }


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Drawer elevation={0} variant="permanent" open={true}>
                <List style={{ height: '100vh', width: '100%', background: '#282c34' }} >
                    <div style={{ padding: 7, position: 'absolute', top: 0, width: '100%' }}>
                        <Typography style={{ fontWeight: '600', color: 'white', fontSize: 23, position: 'relative', left: '0.8rem', top: '1rem' }} >Prime <span style={{ color: 'rgb(41, 174, 227)' }} >Moovers</span></Typography>
                    </div>
                    <br />
                    <br />
                    <br />
                    <ListItem onClick={() => {

                        setIsProfile(false)
                        setisCreateBids(false)
                        setIsUpdateKyc(false)
                        setIsBidoffer(false)
                        setIsprfileItem(false)
                        setIsBidoffer(false)
                        setWait(true)
                        window.location.reload()
                        setIsConfirmedBids(false)
                        setWait(false)
                        setIsDashboard(true)

                    }} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                backgroundColor: isDashboard ? 'white' : '',
                                borderLeft: isDashboard ? '5px solid black' : ''

                            }}
                            className='menuButton'

                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}

                            >
                                <FaPencilAlt fontSize={20} style={{ color: isDashboard ? 'black' : 'white' }} />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" sx={{ opacity: open ? 1 : 0, color: isDashboard ? 'black' : 'white' }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{ display: 'block' }} onClick={() => {
                        setIsDashboard(false)
                        setIsProfile(false)
                        setIsConfirmedBids(false)
                        setIsUpdateKyc(false)
                        setIsBidoffer(false)
                        setIsprfileItem(false)
                        setIsBidoffer(false)
                        setWait(true)

                        setWait(false)
                        setisCreateBids(true)

                    }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                backgroundColor: isCreateBids ? 'white' : '',
                                borderLeft: isCreateBids ? '5px solid black' : ''
                            }}
                            className='menuButton'
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}

                            >
                                <FaHandPeace fontSize={20} style={{ color: isCreateBids ? 'black' : 'white' }} />
                            </ListItemIcon>
                            <ListItemText primary="Create Bids" sx={{ opacity: open ? 1 : 0, color: isCreateBids ? 'black' : 'white' }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{ display: 'block' }} onClick={() => {
                        setIsDashboard(false)
                        setIsProfile(false)
                        setisCreateBids(false)
                        setIsUpdateKyc(false)
                        setIsBidoffer(false)
                        setIsprfileItem(false)

                        setWait(true)
                        setIsConfirmedBids(false)
                        setWait(false)
                        setIsBidoffer(true)

                    }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                backgroundColor: isBidoffer ? 'white' : '',
                                borderLeft: isBidoffer ? '5px solid black' : ''
                            }}
                            className='menuButton'
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <FaHandPeace fontSize={20} style={{ color: isBidoffer ? 'black' : 'white' }} />
                            </ListItemIcon>
                            <ListItemText primary="Bids Received" sx={{ opacity: open ? 1 : 0, color: isBidoffer ? 'black' : 'white' }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{ display: 'block' }} onClick={() => {
                        setIsDashboard(false)
                        setIsProfile(false)
                        setisCreateBids(false)
                        setIsUpdateKyc(false)
                        setIsBidoffer(false)
                        setIsprfileItem(false)
                        setWait(true)
                        setWait(false)
                        setIsBidoffer(false)
                        setIsConfirmedBids(true)

                    }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                backgroundColor: isConfirmedBids ? 'white' : '',
                                borderLeft: isConfirmedBids ? '5px solid black' : ''
                            }}
                            className='menuButton'
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <FaCheck fontSize={20} style={{ color: isConfirmedBids ? 'black' : 'white' }} />
                            </ListItemIcon>
                            <ListItemText primary="Confirmed Bids" sx={{ opacity: open ? 1 : 0, color: isConfirmedBids ? 'black' : 'white' }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{ display: 'block' }} onClick={() => {
                        setIsDashboard(false)
                        setisCreateBids(false)
                        setIsProfile(false)
                        setIsBidoffer(false)

                        setIsprfileItem(false)
                        setIsBidoffer(false)
                        setWait(true)
                        setIsConfirmedBids(false)
                        setWait(false)
                        setIsUpdateKyc(true)

                    }} >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                backgroundColor: isUpdateKyc ? 'white' : '',
                                borderLeft: isUpdateKyc ? '5px solid black' : ''
                            }}
                            className='menuButton'
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}

                            >
                                <FaEdit fontSize={20} style={{ color: isUpdateKyc ? 'black' : 'white' }} />
                            </ListItemIcon>
                            <ListItemText primary="Update Kyc" sx={{ opacity: open ? 1 : 0, color: isUpdateKyc ? 'black' : 'white' }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{ display: 'block' }} >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                // backgroundColor: isCreateBids ? 'white' : '',
                                // borderLeft: isCreateBids ? '5px solid black' : ''
                            }}
                            className='menuButton'
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <FaSearchLocation fontSize={20} style={{ color: 'white' }} />
                            </ListItemIcon>
                            <ListItemText primary="Track Consignment" sx={{ opacity: open ? 1 : 0, color: 'white' }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{ display: 'block', marginTop: 17 }} onClick={() => {
                        setIsDashboard(false)
                        setisCreateBids(false)
                        setIsProfile(false)
                        setIsBidoffer(false)
                        setIsUpdateKyc(false)

                        setIsBidoffer(false)
                        setWait(true)
                        setIsConfirmedBids(false)
                        setWait(false)
                        setIsprfileItem(true)

                    }} >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                backgroundColor: isProfile ? 'white' : '',
                                borderLeft: isProfile ? '5px solid black' : ''
                            }}
                            className='menuButton'
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <FaUser fontSize={20} style={{ color: isProfile ? 'black' : 'white' }} />
                            </ListItemIcon>
                            <ListItemText primary="Profile" sx={{ opacity: open ? 1 : 0, color: isProfile ? 'black' : 'white' }} />
                        </ListItemButton>
                    </ListItem>
                </List>
                <Divider />
            </Drawer>

            <Box className='no-args w-100 mt-5'>

                {
                    isProfile && (

                        <Profile />

                    )
                }

                {
                    isCreateBids && (
                        <CreateBids firstName={firstName} lastName={lastName} />
                    )
                }
                {
                    isDashboard && (
                        <Box sx={{ width: '100%', padding: '0.8rem' }}>
                            <Box>
                                <div>
                                    <DashShowProfile />
                                </div>
                            </Box>
                            {
                                isAlert && (
                                    <Grid md={12} sx={{ mt: 2, borderRadius: '0.04rem', p: 0.4 }} >
                                        <div className='kycAlertCus' style={{ backgroundColor: 'white', borderRadius: '0.06rem', padding: '0.8rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderLeft: '1.6px solid #30d0fc' }} >
                                            <div style={{ display: 'flex', justifyContent: 'center' }} >
                                                <div style={{ width: '26rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRight: '1ps soild gray', borderRightStyle: 'dashed', paddingRight: 3 }} >
                                                    <div style={{ display: 'flex' }}>
                                                        <Typography><img src='https://i.pinimg.com/originals/76/05/2e/76052e4c0db271656da0cf58d826543e.jpg' width='14px' /> Aadhar Kyc</Typography>
                                                        {
                                                            isAv && <Typography color='rgb(30, 225, 30)' style={{ fontSize: '0.8rem' }} >verified<img src='https://th.bing.com/th/id/OIP.vg8_-0X783jk--lcR8S3EAHaHa?w=183&h=183&c=7&r=0&o=5&dpr=2.5&pid=1.7' width='20px' /></Typography>
                                                        }
                                                        {
                                                            !isAv && <Typography color='red' style={{ fontSize: '0.8rem' }} >Not Verified</Typography>
                                                        }
                                                    </div>
                                                    <div style={{ width: '3rem', borderTop: '1px solid lightgray', borderStyle: 'dashed' }} ></div>
                                                    <div style={{ display: 'flex' }} >
                                                        <Typography> <img src='https://img.freepik.com/free-vector/tax-form-concept-illustration_114360-7378.jpg?size=626&ext=jpg&ga=GA1.2.415759594.1681714138&semt=robertav1_2_sidr' width='30px' height='14px' /> Gst Kyc</Typography>
                                                        {
                                                            isAg && <Typography color='rgb(30, 225, 30)' style={{ fontSize: '0.8rem' }} >verified<img src='https://th.bing.com/th/id/OIP.vg8_-0X783jk--lcR8S3EAHaHa?w=183&h=183&c=7&r=0&o=5&dpr=2.5&pid=1.7' width='20px' /></Typography>
                                                        }
                                                        {
                                                            !isAg && <Typography color='red' style={{ fontSize: '0.8rem' }} >Not Verified</Typography>
                                                        }
                                                    </div>
                                                </div>
                                                <Typography style={{ fontSize: '0.8rem', position: 'relative', top: 3, marginLeft: 3 }} >Your Kyc is pending please complete kyc to further process.</Typography>
                                            </div>
                                            <button onClick={() => {
                                                setIsDashboard(false)
                                                setisCreateBids(false)
                                                setIsProfile(false)
                                                setIsBidoffer(false)
                                                setIsConfirmedBids(false)
                                                setIsprfileItem(false)
                                                setIsBidoffer(false)
                                                setWait(true)


                                                setIsUpdateKyc(true)

                                            }} style={{ width: '7.3rem', border: '1px solid red', height: '2.2rem', borderRadius: '0.2rem', fontWeight: '500', color: 'red', fontSize: '0.9rem', position: 'relative' }} >Complete Kyc</button>
                                        </div>
                                    </Grid>

                                )
                            }

                        </Box>
                    )
                }

                {
                    isDashboard && (
                        <Box style={{ position: 'relative', top: '-3.5rem' }}>
                            <CustomerDashboardHomePage />
                        </Box>
                    )
                }

                {
                    isUpdateKyc && (
                        <UpdateKyc />
                    )
                }
                {
                    isBidoffer && (
                        <CustomerDashBody />
                    )
                }
                {
                    isConfirmedBids && (
                        <Box style={{ paddingLeft: 20, paddingRight: 20, padding: 10 }}>
                            <TransporterConfirmedBids />
                        </Box>
                    )
                }
                {
                    profileItem && (
                        <Profile location={location} firstName={firstName} lastName={lastName} newEmail={newEmail} newMobile={newMobile} newGst={newGst} aadhar={aadhar} firm={firm} business={business} mobile={mobile} email={email} />
                    )
                }
            </Box>
            {
                wait && (
                    <SimpleBackdrop />
                )
            }
        </Box>
    );
}
