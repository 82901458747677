import axios from 'axios'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FaUnlockAlt } from 'react-icons/fa'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

const UserEmail = () => {



    const [email, setEmail] = useState('')
    const [isLinkSent, setIsLinkSent] = useState(false)
    const [iSLinkWait, setIsLinkWait] = useState()


    const sentEmail = () => {
        setIsLinkWait(true)
        axios.post('https://pmapi.emedha.in/password/sent/link', {
            email,
            linkType:'forgotPassword'
        }).then((res) => {
            if (res.data.status === 200) {
                setIsLinkSent(true)
                setTimeout(() => setIsLinkSent(false), 20000)
                setIsLinkWait(false)
            }
        }).catch(() => {
            alert('Email Is Not Exist')
            setIsLinkWait(false)
        })
    }

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            sentEmail()
        }

        setValidated(true);
    };

    return (
        <div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className='p-3'>
                <div className='col-lg-4 mx-auto signup-container uinfo' >
                    <center><FaUnlockAlt fontSize={90} /></center>
                    <Form noValidate validated={validated} onSubmit={handleSubmit} >
                        <Row className="mb-3">
                            <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                                <Form.Label>Email</Form.Label>
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                    <Form.Control
                                        type="email"
                                        placeholder="Email"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        style={{ padding: '0.6rem' }}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Valid Email.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        {
                            isLinkSent && (
                                <p className='text-success'>Email SuccessFully Sent to your email</p>
                            )
                        }
                        <button className="signupBtn" type='submit'>Submit</button>
                        <br />
                        <p style={{ fontWeight: '500', color: '#181849', marginTop: '0.4rem' }}><Link to='/' style={{ textDecoration: 'none', color: 'rgb(85, 84, 82)', fontSize: '1rem', textDecorationLine: 'underline' }}>Already have an Account?</Link></p>
                    </Form>
                    <p></p>
                </div>
            </div>
            <p className='text-center'>@2023 prime moovers, All rights reserved</p>
             
        </div>
    )
}

export default UserEmail