import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FaSearch, FaUserCircle } from 'react-icons/fa';
import TabUserVerify from '../UserVerifyTab/UserVerifyTab';
import { Box, Typography } from '@mui/material';

export default function MainCorporateCustomer() {


    const rows1 = [

    ];

    const [isVerify, setIsVerify] = React.useState(false)

    return (
        <Paper elevation={0}>
            <div className='card' style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', boxShadow: 'none',border:'none' }} >
                <h5 > Corporate Customer</h5>

                <FaSearch style={{ position: 'absolute', right: 10, zIndex: 1000, top: 18, color: 'gray', fontSize: 19 }} />
                
                <h6 ><input  className='card shadow' style={{ backgroundColor: 'white', borderRadius: '0.1rem', height: '3.1rem', border: 'none', outline: 'none', width: '29rem', paddingLeft: '0.7rem' }} placeholder='Search...' ></input></h6>
            </div>
            <TableContainer sx={{ height: 73 + "vh" }} >
                {
                    !isVerify && (
                        <div>

                            <Table style={{ position: 'relative', top: '-0.7rem' }}>
                                <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                                    <TableRow>
                                        <TableCell
                                            align={"center"}
                                            style={{ minWidth: 30, fontSize: 12, fontWeight: 'bold' }}
                                        >
                                            User
                                        </TableCell>
                                        <TableCell
                                            align={"center"}
                                            style={{ top: 57, minWidth: 60, fontWeight: 'bold' }}
                                        >
                                            Name
                                        </TableCell>
                                        <TableCell
                                            align={"center"}
                                            style={{ top: 57, minWidth: 60, fontWeight: 'bold' }}
                                        >
                                            Mobile
                                        </TableCell>
                                        <TableCell
                                            align={"center"}
                                            style={{ top: 57, minWidth: 60, fontWeight: 'bold' }}
                                        >
                                            Industry
                                        </TableCell>
                                        <TableCell
                                            align={"center"}
                                            style={{ top: 57, minWidth: 60, fontWeight: 'bold' }}
                                        >
                                            Email
                                        </TableCell>
                                        <TableCell
                                            align={"center"}
                                            style={{ top: 57, minWidth: 60, fontWeight: 'bold' }}
                                        >
                                            Location
                                        </TableCell>
                                        <TableCell

                                            align={"center"}
                                            style={{ top: 57, minWidth: 120, fontWeight: 'bold' }}
                                        >
                                            Date
                                        </TableCell>
                                        <TableCell

                                            align={"left"}
                                            style={{ top: 57, minWidth: 100, fontWeight: 'bold' }}
                                        >
                                            Status
                                        </TableCell>

                                        <TableCell

                                            align={"center"}
                                            style={{ top: 57, minWidth: 100, fontWeight: 'bold' }}
                                        >
                                            Action
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows1
                                        .map((row) => {
                                            let status = ''
                                            let color = ''
                                            if (row.status === 0) {
                                                status = 'decline'
                                                color = 'rgb(239, 122, 122)'
                                            } else {
                                                if (row.status === 2) {
                                                    status = 'progress'
                                                    color = 'rgb(233, 198, 108)'
                                                } else {
                                                    status = 'active'
                                                    color = 'rgb(107, 213, 107)'
                                                }
                                            }
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                    <TableCell align={"center"}
                                                        style={{ top: 57, minWidth: 30, fontSize: 42, color: color, }}><FaUserCircle /></TableCell>
                                                    <TableCell align={"center"}
                                                        style={{ top: 57, minWidth: 60, fontSize: 12 }}>{row.name}</TableCell>
                                                    <TableCell align={"center"}
                                                        style={{ top: 57, minWidth: 60, fontSize: 12 }}>{row.mobile}</TableCell>
                                                    <TableCell align={"center"}
                                                        style={{ top: 57, minWidth: 60, fontSize: 12 }}>{row.email}</TableCell>
                                                    <TableCell align={"center"}
                                                        style={{ top: 57, minWidth: 120, fontSize: 12 }}>{row.date}</TableCell>
                                                    <TableCell align={"left"}
                                                        style={{ top: 57, minWidth: 100, flexDirection: 'row', alignItems: 'left' }}>
                                                        <span style={{ cursor: 'pointer', }}>
                                                            <button style={{ backgroundColor: color, fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} >{status}</button>
                                                        </span>
                                                    </TableCell>
                                                    <TableCell align={"center"}
                                                        style={{ top: 57, minWidth: 100, flexDirection: 'row', alignItems: 'left' }}>
                                                        <span style={{ cursor: 'pointer', }}>
                                                            <button onClick={() => setIsVerify(true)} style={{ backgroundColor: 'rgb(107, 213, 107)', fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} >Verify</button>
                                                        </span>
                                                        <span style={{ cursor: 'pointer', marginLeft: '0.1rem', marginRight: '0.1rem' }}>
                                                            <button style={{ backgroundColor: 'rgb(239, 122, 122)', fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} >Delete</button>
                                                        </span>
                                                        <span style={{ cursor: 'pointer', }}>
                                                            <button style={{ backgroundColor: 'rgb(233, 198, 108)', fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} >Edit</button>
                                                        </span>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </div>
                    )
                }
                {
                    isVerify && (
                        <TabUserVerify />
                    )
                }
                {
                    rows1.length === 0 && (
                        <Box className='card' style={{ height: '64vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center',border:'none' }} >
                            <img src='https://img.freepik.com/free-vector/no-data-concept-illustration_114360-2506.jpg?size=626&ext=jpg&ga=GA1.1.415759594.1681714138&semt=ais' width='145px' />
                            <Typography>0 users</Typography>
                        </Box>
                    )
                }
            </TableContainer>
        </Paper>
    );
}