import { Typography } from '@mui/material';
import React from 'react';
import { useTime, useTimer } from 'react-timer-hook';

function MyTimer({ expiryTimestamp }) {

  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });


  return (
    <div>

      <Typography style={{fontStyle:'italic', marginTop:-2.6}} > <span style={{ fontSize: '0.7rem', color: 'white', marginRight:10, marginLeft:3.3 }}>Bid Expire In:- </span><span style={{ fontSize: '0.7rem', borderRadius: '0.9rem', padding: 1.7 }} >{hours} hrs</span>:<span style={{ fontSize: '0.7rem', borderRadius: '0.9rem', padding: 1.7 }} >{minutes} min</span>:<span style={{ fontSize: '0.7rem', borderRadius: '0.9rem', padding: 1.7 }}>{seconds} sec</span></Typography>

    </div>
  );
}

export default MyTimer