import React, { PureComponent, useState } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useEffect } from 'react';
import axios from 'axios';
import { Grid, Box, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'Auto',
  color: theme.palette.text.secondary,
}));


const PieComponent = () => {



  const [city1, setCity1] = useState()
  const [city2, setCity2] = useState()
  const [city3, setCity3] = useState()
  const [city4, setCity4] = useState()
  const [city5, setCity5] = useState()
  const [city6, setCity6] = useState()

  const [city11, setCity11] = useState()
  const [city12, setCity12] = useState()
  const [city13, setCity13] = useState()
  const [city14, setCity14] = useState()
  const [city15, setCity15] = useState()
  const [city16, setCity16] = useState()



  const pieCartData = () => (
    axios.post('https://pmapi.emedha.in/pie-chart/place-bid-value/data').then((res) => {

      setCity1(res.data[0].cityName)
      setCity2(res.data[1].cityName)
      setCity3(res.data[2].cityName)
      setCity4(res.data[3].cityName)
      setCity5(res.data[4].cityName)
      setCity6(res.data[5].cityName)

      setCity11(res.data[0].allTimeCount)
      setCity12(res.data[1].allTimeCount)
      setCity13(res.data[2].allTimeCount)
      setCity14(res.data[3].allTimeCount)
      setCity15(res.data[4].allTimeCount)
      setCity16(res.data[5].allTimeCount)

    })
  )

  const updatePieChart = () => (
    axios.post('https://pmapi.emedha.in/pie-chart/place-bid-value/count').then((res) => {
    })
  )

  useEffect(() => {
    pieCartData()
    updatePieChart()
  }, [])

  return (
    <Box sx={{ flexGrow: 1, width: '100%' }}>
      <Grid container>
        <Grid item xs={6}>
          <Item style={{ height: '14.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight:'1px solid lightgray', borderRightStyle:'dashed',borderBottom:'1px solid lightgray', borderBottomStyle:'dashed' }} elevation={0}>
            <div>

              <Typography style={{ color: 'rgb(37, 48, 50)', fontSize: 21, fontWeight: '600', textAlign: 'center' }} >{city11} <span style={{fontSize:'0.7rem'}} >Bids</span></Typography>

              <Typography style={{ color: 'rgb(37, 48, 50)', fontSize: 18, fontWeight: '600' }} >{city1}</Typography>
            </div>
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item style={{ height: '14.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center',borderLeft:'1px solid lightgray', borderLeftStyle:'dashed',borderBottom:'1px solid lightgray', borderBottomStyle:'dashed'}} elevation={0}>
            <div>
              <Typography style={{ color: 'rgb(37, 48, 50)', fontSize: 21, fontWeight: '600', textAlign: 'center' }} >{city12} <span style={{fontSize:'0.7rem'}} >Bids</span></Typography>

              <Typography style={{ color: 'rgb(37, 48, 50)', fontSize: 18, fontWeight: '600' }} >{city2}</Typography>
            </div>
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item style={{ height: '14.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight:'1px solid lightgray', borderRightStyle:'dashed', borderTop:'1px solid lightgray', borderTopStyle:'dashed' }} elevation={0}>
            <div>

              <Typography style={{ color: 'rgb(37, 48, 50)', fontSize: 21, fontWeight: '600', textAlign: 'center' }} >{city13} <span style={{fontSize:'0.7rem'}} >Bids</span></Typography>

              <Typography style={{ color: 'rgb(37, 48, 50)', fontSize: 18, fontWeight: '600' }} >{city3}</Typography>
            </div>
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item style={{ height: '14.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center',borderLeft:'1px solid lightgray', borderLeftStyle:'dashed' }} elevation={0}>
            <div>

              <Typography style={{ color: 'rgb(37, 48, 50)', fontSize: 21, fontWeight: '600', textAlign: 'center' }} >{city14} <span style={{fontSize:'0.7rem'}} >Bids</span></Typography>

              <Typography style={{ color: 'rgb(37, 48, 50)', fontSize: 18, fontWeight: '600' }} >{city4}</Typography>
            </div>
          </Item>
        </Grid>

      </Grid>
    </Box>
  );
}


export default PieComponent