import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { FaCheckCircle, FaEdit, FaEye, FaTrashAlt, FaUser, FaWindowClose } from 'react-icons/fa'
import SimpleBackdrop from '../../../../BackDrop/BackDrop';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import vrfid from '../../../../GlobleImageContainer/images/ver.png'


function GST({ id }) {

    const [data, setData] = useState([])
    const [address, set_address] = useState()
    const [business_name, set_business_name] = useState()
    const [center_jurisdiction, set_center_jurisdiction] = useState()
    const [constitution_of_business, set_constitution_of_business] = useState()
    const [date_of_cancellation, set_date_of_cancellation] = useState()
    const [date_of_registration, set_date_of_registration] = useState()
    const [field_visit_conducted, set_field_visit_conducted] = useState()
    const [gstin, set_gstin] = useState()
    const [nature_of_core_business_activity_description, set_nature_of_core_business_activity_description] = useState()
    const [pan_number, set_pan_number] = useState()
    const [state_jurisdiction, set_state_jurisdiction] = useState()
    const [isActionToVerify, setIsActionToVerify] = useState(false)
    const [localData, setLocalData] = useState()
    const [client_id, set_client_id] = useState()
    const [isBackDrop, setIsBackDrop] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)

    const customer_business_details = (client_id = id) => {
        const userType = 'Customer'
        axios.post('https://pmapi.emedha.in/admin/user-business-details', {
            client_id,
            userType
        }).then((res) => {
            setData(res.data.response)
            setIsActionToVerify(true)
            setGstDataView(false)
            setIsView(false)
            setIsBackDrop(false)
        })
    }


    useEffect(() => {
        customer_business_details()
    }, [])

    const [openViewModel, setOpenViewModel] = useState(false)
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const gstVerify = () => {
        const isVerify = 1
        const userType = 'Customer'
        if (window.confirm('Confirm To Verify GST Number')) {
            axios.post('https://pmapi.emedha.in/admin/user-business-details/verify', {
                isVerify,
                client_id,
                userType,
            }).then((res) => {
                alert('Verified')
                setIsActionToVerify(false)
                customer_business_details()
                setIsAdmin(false)
            })
        }
    }

    const gstVerification = (id_number) => {
        setOpenViewModel(false)
        setIsBackDrop(true)
        set_client_id(id_number)
        const filing_status_get = true
        const webApiUrl = 'https://kyc-api.surepass.io/api/v1/corporate/gstin'
        const tokenStr = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY3MzMyODQwMywianRpIjoiMmYzMmMzMmQtMjMwMS00ZDg1LTkwNzktODUxMzMwMWMzZTJhIiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LnByaW1lbW92ZXJzQHN1cmVwYXNzLmlvIiwibmJmIjoxNjczMzI4NDAzLCJleHAiOjE5ODg2ODg0MDMsInVzZXJfY2xhaW1zIjp7InNjb3BlcyI6WyJ3YWxsZXQiXX19.9u2NZdFGzWOXpt6UNq8wvtrxTljuZ8lkw4r_K2K6d7I'
        axios.post(webApiUrl, {
            id_number,
            filing_status_get
        },
            {
                headers: {
                    'Accept': 'application/json, text/plain',
                    'content-type': 'application/json',
                    "Authorization": `Bearer ${tokenStr}`
                }
            }).then((res) => {
                if (res.data.status_code === 200) {
                    set_address(res.data.data.address)
                    set_business_name(res.data.data.business_name)
                    set_center_jurisdiction(res.data.data.center_jurisdiction)
                    set_constitution_of_business(res.data.data.constitution_of_business)
                    set_date_of_cancellation(res.data.data.date_of_cancellation)
                    set_date_of_registration(res.data.data.set_date_of_registration)
                    set_field_visit_conducted(res.data.data.field_visit_conducted)
                    set_gstin(res.data.data.gstin)
                    set_nature_of_core_business_activity_description(res.data.data.nature_of_core_business_activity_description)
                    set_pan_number(res.data.data.pan_number)
                    set_state_jurisdiction(res.data.data.state_jurisdiction)
                    customer_business_details(id)
                    setIsAdmin(true)
                }
            }).catch((err) => {
                alert('please try again')
            })
    }

    const [gstDataView, setGstDataView] = useState(true)
    const [isView, setIsView] = useState(false)

    const gstView = (id_number) => {
        setOpenViewModel(true)
        handleClickOpen()
        setIsBackDrop(true)
        set_client_id(id_number)
        const filing_status_get = true
        const webApiUrl = 'https://kyc-api.surepass.io/api/v1/corporate/gstin'
        const tokenStr = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY3MzMyODQwMywianRpIjoiMmYzMmMzMmQtMjMwMS00ZDg1LTkwNzktODUxMzMwMWMzZTJhIiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LnByaW1lbW92ZXJzQHN1cmVwYXNzLmlvIiwibmJmIjoxNjczMzI4NDAzLCJleHAiOjE5ODg2ODg0MDMsInVzZXJfY2xhaW1zIjp7InNjb3BlcyI6WyJ3YWxsZXQiXX19.9u2NZdFGzWOXpt6UNq8wvtrxTljuZ8lkw4r_K2K6d7I'
        axios.post(webApiUrl, {
            id_number,
            filing_status_get
        },
            {
                headers: {
                    'Accept': 'application/json, text/plain',
                    'content-type': 'application/json',
                    "Authorization": `Bearer ${tokenStr}`
                }
            }).then((res) => {
                if (res.data.status_code === 200) {
                    set_address(res.data.data.address)
                    set_business_name(res.data.data.business_name)
                    set_center_jurisdiction(res.data.data.center_jurisdiction)
                    set_constitution_of_business(res.data.data.constitution_of_business)
                    set_date_of_cancellation(res.data.data.date_of_cancellation)
                    set_date_of_registration(res.data.data.set_date_of_registration)
                    set_field_visit_conducted(res.data.data.field_visit_conducted)
                    set_gstin(res.data.data.gstin)
                    set_nature_of_core_business_activity_description(res.data.data.nature_of_core_business_activity_description)
                    set_pan_number(res.data.data.pan_number)
                    set_state_jurisdiction(res.data.data.state_jurisdiction)
                    setIsActionToVerify(true)
                    setGstDataView(true)
                    setIsView(true)
                    setIsBackDrop(false)
                }
            }).catch((err) => {

            })
    }

    const [showPerPage, setShowPerPage] = useState(10)
    const [pagination, setPagination] = useState(
        {
            start: 0,
            end: showPerPage
        }
    )

    useEffect(() => {
        customer_business_details()
    }, [])

    return (
        <div>
            {
                <div className=''>
                    {
                        (!isActionToVerify || !isAdmin) && (
                            <Paper elevation={0}>
                                <TableContainer sx={{ height: 68 + "vh", overflowY: 'scroll', width: '54rem' }} >
                                    <div>
                                        <h5 style={{ position: 'relative', top: '0.4rem' }}>GST  VERIFICATION</h5>
                                        <br />
                                        <br />
                                        <span style={{ fontSize: '1rem' }}>GST verification is a process by which a taxpayer gets registered under the Goods and Service Tax (GST) and receives a 15 digit GSTIN number from the Central Government of India.g</span>
                                        <br />
                                        <br />
                                        <br />
                                        <Table style={{ position: 'relative', top: '-0.7rem' }}>
                                            <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                                                <TableRow>
                                                    <TableCell
                                                        align={"center"}
                                                        style={{ minWidth: 30, fontSize: 12, fontWeight: 'bold' }}
                                                    >
                                                        User
                                                    </TableCell>
                                                    <TableCell
                                                        align={"center"}
                                                        style={{ minWidth: 120, fontSize: 12, fontWeight: 'bold' }}
                                                    >
                                                        GST NUMBER
                                                    </TableCell>
                                                    <TableCell
                                                        align={"center"}
                                                        style={{ top: 57, minWidth: 50, fontWeight: 'bold' }}
                                                    >
                                                        Nature Of Business
                                                    </TableCell>


                                                    <TableCell

                                                        align={"center"}
                                                        style={{ top: 57, minWidth: 80, fontWeight: 'bold' }}
                                                    >
                                                        Firm
                                                    </TableCell>
                                                    <TableCell

                                                        align={"center"}
                                                        style={{ top: 57, minWidth: 60, fontWeight: 'bold' }}
                                                    >
                                                        Pan Card Number
                                                    </TableCell>
                                                    <TableCell

                                                        align={"left"}
                                                        style={{ top: 57, minWidth: 100, fontWeight: 'bold' }}
                                                    >
                                                        Status
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {
                                                data.map((data) => {
                                                    let verify = ''
                                                    let addGst = ''
                                                    if (data.isVerify === '1') {
                                                        verify = true
                                                    } else {
                                                        verify = false
                                                    }
                                                    if(data.isAddGst === 1){
                                                        addGst = true
                                                    }else {
                                                        addGst=false
                                                    }
                                                    return (
                                                        <TableBody>
                                                            {
                                                                verify && (
                                                                    <TableRow hover role="checkbox" tabIndex={-1}>
                                                                        <TableCell align={"center"}
                                                                            style={{ top: 57, minWidth: 30, fontSize: 12 }}><FaUser fontSize={26} color='rgb(107, 213, 107)' /></TableCell>
                                                                        <TableCell align={"center"}
                                                                            style={{ top: 57, minWidth: 120, fontSize: 12, textDecoration: 'underline', cursor: 'pointer' }} onClick={() => gstView(data.gst)} >XX XXXX XXXX {data.gst.substring(data.gst.length + 4, 10)}</TableCell>
                                                                        <TableCell align={"center"}
                                                                            style={{ top: 57, minWidth: 50, fontSize: 12 }}>{data.natureOfBusiness} </TableCell>
                                                                        <TableCell align={"center"}
                                                                            style={{ top: 57, minWidth: 80, fontSize: 12 }}>{data.firm}</TableCell>
                                                                        <TableCell align={"center"}
                                                                            style={{ top: 57, minWidth: 80, fontSize: 12 }}>{data.pan_card}</TableCell>
                                                                        <TableCell align={"left"}
                                                                            style={{ top: 57, minWidth: 100, flexDirection: 'row', alignItems: 'left' }}>
                                                                            <span style={{ cursor: 'pointer', }}>
                                                                                <button disabled={true} style={{ backgroundColor: 'rgb(107, 213, 107)', fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} >verified</button>
                                                                            </span>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            }
                                                            {
                                                                (!verify && addGst) && (
                                                                    <TableRow hover role="checkbox" tabIndex={-1}>
                                                                        <TableCell align={"center"}
                                                                            style={{ top: 57, minWidth: 60, fontSize: 12 }}><FaUser fontSize={26} color='rgb(107, 213, 107)' /></TableCell>
                                                                        <TableCell align={"center"}
                                                                            style={{ top: 57, minWidth: 60, fontSize: 12, }} >XX XXXX XXXX {data.gst.substring(data.gst.length + 4, 10)}</TableCell>
                                                                        <TableCell align={"center"}
                                                                            style={{ top: 57, minWidth: 50, fontSize: 12 }}>{data.natureOfBusiness} </TableCell>
                                                                        <TableCell align={"center"}
                                                                            style={{ top: 57, minWidth: 80, fontSize: 12 }}>{data.firm}</TableCell>
                                                                        <TableCell align={"center"}
                                                                            style={{ top: 57, minWidth: 80, fontSize: 12 }}>{data.pan_card}</TableCell>
                                                                        <TableCell align={"left"}
                                                                            style={{ top: 57, minWidth: 100, flexDirection: 'row', alignItems: 'left' }}>
                                                                            <span style={{ cursor: 'pointer', }}>
                                                                                <button style={{ backgroundColor: 'rgb(107, 213, 107)', fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} onClick={() => gstVerification(data.gst)} >verify</button>
                                                                            </span>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            }
                                                        </TableBody>
                                                    )
                                                })
                                            }
                                        </Table>
                                    </div>
                                    <br />
                                    <br />
                                    <div style={{ height: '4rem', width: '23rem', backgroundColor: 'rgb(243, 242, 242)', marginBottom: '0.5rem', borderRadius: '0.4rem', padding: '0.2rem' }} >
                                        <span style={{ fontWeight: 'bold', color: 'black', fontSize: 18 }} >Note:</span> <span style={{ color: 'gray', fontSize: 13 }}>GST verification is critical to ensure taxpayer validity and prevent tax cascading</span>
                                    </div>
                                </TableContainer>
                            </Paper>
                        )
                    }
                </div >
            }
            {
                (isActionToVerify && !openViewModel && isAdmin) && (
                    <div style={{ width: '54rem', maxHeight: '68vh', overflowY: 'scroll' }}>
                        {
                            (!gstDataView && !isView) && (
                                <div>
                                    {
                                        data.map((data) => {
                                            return (
                                                <div className='col-11 col-md-8 col-lg-12 mx-auto bg-white'>
                                                    <div>
                                                        <button style={{ height: '2.5rem', width: '9rem', borderRadius: '0.2rem', backgroundColor: 'green' }} className='signupBtn' onClick={() => gstVerify()} >Verify</button>
                                                        <button style={{ height: '2.5rem', width: '9rem', borderRadius: '0.2rem', backgroundColor: 'red' }} className='signupBtn text-white' >Decline</button>
                                                    </div>
                                                    <div style={{ border: '3px solid lightgray', borderRadius: '0.4rem', padding: 3, marginTop: 10 }}>
                                                        <h4 style={{ fontWeight: 'bold' }}>User Insert Data With Copy</h4>
                                                        <hr />
                                                        <img src={data.gstImage} style={{ marginBottom: 10 }} width='100%' />
                                                        <div style={{ border: '2px solid lightgray', padding: 7, marginTop: 3 }}>
                                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Business Name:</p>
                                                                <p>{data.firm}</p>
                                                            </div>
                                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Gst Number:</p>
                                                                <p> {data.gst}</p>
                                                            </div>

                                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Nature Of Business:</p>
                                                                <p>{data.natureOfBusiness}</p>
                                                            </div>

                                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Pan Card Number:</p>
                                                                <p>{data.pan_card}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        }
                        <br />
                        {
                            (gstDataView || !isView) && (
                                <div>
                                    <div className='col-11 col-md-8 col-lg-12 mx-auto bg-white '>
                                        {
                                            (gstDataView) && (
                                                <div>
                                                    <button style={{ border: 'none' }} onClick={() => { setIsActionToVerify(false) }}><FaWindowClose color='#232363' fontSize={20} /></button>
                                                    <h1 style={{ color: '#e4e4fa', textDecoration: 'textUnderlineOffset', textAlign: 'center' }}>GST DATA</h1>
                                                </div>
                                            )
                                        }
                                        <br />

                                        <div style={{ border: '3px solid lightgray', borderRadius: '0.5rem', padding: 10 }}>
                                            <h4 style={{ fontWeight: 'bold' }}>Gov. Verified Gst Data <img src={vrfid} width='47px' /></h4>
                                            <hr />
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Business Name:</p>
                                                <p>{business_name}</p>
                                            </div>

                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Gst Number:</p>
                                                <p>{gstin}</p>
                                            </div>
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Address:</p>
                                                <p>{address}</p>
                                            </div>
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Center Jurisdiction:</p>
                                                <p>{center_jurisdiction}</p>
                                            </div>
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Constitution Of Business:</p>
                                                <p>{constitution_of_business}</p>
                                            </div>
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Date Of Cancellation:</p>
                                                <p>{date_of_cancellation}</p>
                                            </div>
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Date Of Registration:</p>
                                                <p>{date_of_registration}</p>
                                            </div>

                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Nature Of Core Business Activity Description:</p>
                                                <p>{nature_of_core_business_activity_description}</p>
                                            </div>
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>State Jurisdiction:</p>
                                                <p>{state_jurisdiction}</p>
                                            </div>
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Pan Number:</p>
                                                <p>{pan_number}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }
            <div>
                {
                    isBackDrop && (
                        <SimpleBackdrop />
                    )
                }
            </div>
            <div>
                <Dialog
                    open={open}
                >
                    <DialogContent>
                        <DialogContentText>
                            {
                                (gstDataView || !isView) && (
                                    <div>
                                        <div className='col-12 mx-auto bg-white '>
                                            {
                                                (gstDataView) && (
                                                    <div>
                                                        <h1 style={{ color: '#e4e4fa', textDecoration: 'textUnderlineOffset', textAlign: 'center' }}>GST DATA</h1>
                                                    </div>
                                                )
                                            }
                                            <br />

                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Business Name:</p>
                                                <p>{business_name}</p>
                                            </div>

                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Gst Number:</p>
                                                <p>{gstin}</p>
                                            </div>
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Address:</p>
                                                <p>{address}</p>
                                            </div>
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Center Jurisdiction:</p>
                                                <p>{center_jurisdiction}</p>
                                            </div>
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Constitution Of Business:</p>
                                                <p>{constitution_of_business}</p>
                                            </div>
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Date Of Cancellation:</p>
                                                <p>{date_of_cancellation}</p>
                                            </div>
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Date Of Registration:</p>
                                                <p>{date_of_registration}</p>
                                            </div>

                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Nature Of Core Business Activity Description:</p>
                                                <p>{nature_of_core_business_activity_description}</p>
                                            </div>
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>State Jurisdiction:</p>
                                                <p>{state_jurisdiction}</p>
                                            </div>
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Pan Number:</p>
                                                <p>{pan_number}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={() => {
                            handleClose()
                            setOpenViewModel(false)
                            setGstDataView(false)
                            setIsActionToVerify(false)
                        }}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div >
    )
}

export default GST
