import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FaSearch, FaUserCircle, FaWindowClose } from 'react-icons/fa';
import TabUserVerify from '../UserVerifyTab/UserVerifyTab';
import axios from 'axios';
import { useState } from 'react';
import { Box, Typography } from '@mui/material';

export default function MainCustomer({ data }) {


    const [id, setId] = useState()
    const [fName, setFname] = useState()
    const [lName, setlName] = useState()
    const [email, setEmail] = useState()
    const [mobile, setMobile] = useState()
    const [gst, setGst] = useState()
    const [verified, setVerified] = useState()



    const verify = (id, fname, lname, email, mobile, gst, verify) => {
        setId(id)
        setFname(fname)
        setlName(lname)
        setMobile(mobile)
        setGst(gst)
        setEmail(email)
        setIsVerify(true)
        setVerified(verify)
    }

    const [customerUsers, setCustomerUsers] = useState([])
    const [text, setText] = useState('')

    const searchCustomer = (text) => {
        setText(text)
        const userType = 'Customer'
        axios.post('https://pmapi.emedha.in/admin/users-search', {
            text,
            userType
        }).then((res) => {
            setCustomerUsers(res.data.users)
        })
    }

    const [isVerify, setIsVerify] = React.useState(false)

    return (
        <Paper elevation={0}>
            {
                !isVerify && (
                    <div className='card' style={{ paddingLeft:10,width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', boxShadow: 'none', border: 'none',paddingRight:20 }} >
                        <h5 > Retail Clients</h5>
                        {
                            text === '' && (
                                <FaSearch style={{ position: 'absolute', right: 28, zIndex: 1000, top: 18, color: 'gray', fontSize: 19 }} />
                            )
                        }
                        {
                            text !== '' && (
                                <FaWindowClose onClick={() => {
                                    setCustomerUsers([])
                                    setText('')
                                }} style={{ position: 'absolute', right: 28, zIndex: 1000, top: 18, color: 'gray', fontSize: 19, cursor: 'pointer' }} />
                            )
                        }
                        <h6 ><input value={text} onChange={(e) => searchCustomer(e.target.value)} className='shadow' style={{ backgroundColor: 'white', borderRadius: '0.1rem', height: '3.1rem', border: 'none', outline: 'none', width: '29rem', paddingLeft: '0.7rem' }} placeholder='Search...' ></input></h6>
                    </div>
                )
            }
            <TableContainer sx={{ height: isVerify ? 80 + "vh" : 72 + "vh" }} >
                {
                    !isVerify && (
                        <div>
                            <Table style={{ position: 'relative', top: '-0.7rem' }}>
                                <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                                    <TableRow>
                                        <TableCell
                                            align={"center"}
                                            style={{ minWidth: 30, fontSize: 12, fontWeight: 'bold' }}
                                        >
                                            User
                                        </TableCell>
                                        <TableCell
                                            align={"start"}
                                            style={{ top: 57, minWidth: 60, fontWeight: 'bold' }}
                                        >
                                            Name
                                        </TableCell>
                                        <TableCell
                                            align={"start"}
                                            style={{ top: 57, minWidth: 60, fontWeight: 'bold' }}
                                        >
                                            Mobile
                                        </TableCell>
                                        <TableCell
                                            align={"start"}
                                            style={{ top: 57, minWidth: 60, fontWeight: 'bold' }}
                                        >
                                            Industry
                                        </TableCell>
                                        <TableCell
                                            align={"start"}
                                            style={{ top: 57, minWidth: 60, fontWeight: 'bold' }}
                                        >
                                            Email
                                        </TableCell>
                                        <TableCell
                                            align={"start"}
                                            style={{ top: 57, minWidth: 60, fontWeight: 'bold' }}
                                        >
                                            Location
                                        </TableCell>
                                        <TableCell

                                            align={"start"}
                                            style={{ top: 57, minWidth: 120, fontWeight: 'bold' }}
                                        >
                                            Date
                                        </TableCell>
                                        <TableCell

                                            align={"left"}
                                            style={{ top: 57, minWidth: 100, fontWeight: 'bold' }}
                                        >
                                            Status
                                        </TableCell>

                                        <TableCell

                                            align={"start"}
                                            style={{ top: 57, minWidth: 100, fontWeight: 'bold' }}
                                        >
                                            Action
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {
                                    text === '' && (
                                        <TableBody>
                                            {data
                                                .map((row) => {
                                                    let status = ''
                                                    let color = ''
                                                    if (row.status === '0') {
                                                        status = 'decline'
                                                        color = 'rgb(239, 122, 122)'
                                                    } else {
                                                        if (row.isVerify === '1') {
                                                            status = 'active'
                                                            color = 'rgb(107, 213, 107)'
                                                        } else {
                                                            status = 'progress'
                                                            color = 'rgb(233, 198, 108)'
                                                        }
                                                    }
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                            <TableCell align={"center"}
                                                                style={{ top: 57, minWidth: 30, fontSize: 42, color: color, }}><FaUserCircle /></TableCell>
                                                            <TableCell align={"start"}
                                                                style={{ top: 57, minWidth: 60, fontSize: 12 }}>{row.first_name} {row.last_name}</TableCell>
                                                            <TableCell align={"start"}
                                                                style={{ top: 57, minWidth: 60, fontSize: 12 }}>{row.mobile}</TableCell>
                                                            <TableCell align={"start"}
                                                                style={{ top: 57, minWidth: 60, fontSize: 12 }}>{row.firm}</TableCell>
                                                            <TableCell align={"start"}
                                                                style={{ top: 57, minWidth: 60, fontSize: 12 }}>{row.email}</TableCell>
                                                            <TableCell align={"start"}
                                                                style={{ top: 57, minWidth: 60, fontSize: 12 }}>{row.location}</TableCell>
                                                            <TableCell align={"start"}
                                                                style={{ top: 57, minWidth: 120, fontSize: 12 }}>{row.date}</TableCell>
                                                            <TableCell align={"left"}
                                                                style={{ top: 57, minWidth: 100, flexDirection: 'row', alignItems: 'left' }}>
                                                                <span style={{ cursor: 'pointer', }}>
                                                                    <button style={{ backgroundColor: color, fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} >{status}</button>
                                                                </span>
                                                            </TableCell>
                                                            <TableCell align={"start"}
                                                                style={{ top: 57, minWidth: 100, flexDirection: 'row', alignItems: 'left' }}>
                                                                <span style={{ cursor: 'pointer', }}>
                                                                    <button onClick={() => {
                                                                        verify(row.client_id, row.first_name, row.last_name, row.email, row.mobile, row.gst, row.isVerify)
                                                                    }} style={{ backgroundColor: 'rgb(107, 213, 107)', fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} >Verify</button>
                                                                </span>
                                                                <span style={{ cursor: 'pointer', marginLeft: '0.1rem', marginRight: '0.1rem' }}>
                                                                    <button style={{ backgroundColor: 'rgb(239, 122, 122)', fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} >Delete</button>
                                                                </span>
                                                                <span style={{ cursor: 'pointer', }}>
                                                                    <button style={{ backgroundColor: 'rgb(233, 198, 108)', fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} >Edit</button>
                                                                </span>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    )
                                }

                                {
                                    text !== '' && (
                                        <TableBody>
                                            {customerUsers
                                                .map((row) => {
                                                    let status = ''
                                                    let color = ''
                                                    if (row.status === '0') {
                                                        status = 'decline'
                                                        color = 'rgb(239, 122, 122)'
                                                    } else {
                                                        if (row.isVerify === '1') {
                                                            status = 'active'
                                                            color = 'rgb(107, 213, 107)'
                                                        } else {
                                                            status = 'progress'
                                                            color = 'rgb(233, 198, 108)'
                                                        }
                                                    }
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                            <TableCell align={"center"}
                                                                style={{ top: 57, minWidth: 30, fontSize: 42, color: color, }}><FaUserCircle /></TableCell>
                                                            <TableCell align={"center"}
                                                                style={{ top: 57, minWidth: 60, fontSize: 12 }}>{row.first_name} {row.last_name}</TableCell>
                                                            <TableCell align={"center"}
                                                                style={{ top: 57, minWidth: 60, fontSize: 12 }}>{row.mobile}</TableCell>
                                                            <TableCell align={"center"}
                                                                style={{ top: 57, minWidth: 60, fontSize: 12 }}>{row.email}</TableCell>
                                                            <TableCell align={"center"}
                                                                style={{ top: 57, minWidth: 120, fontSize: 12 }}>{row.date}</TableCell>
                                                            <TableCell align={"left"}
                                                                style={{ top: 57, minWidth: 100, flexDirection: 'row', alignItems: 'left' }}>
                                                                <span style={{ cursor: 'pointer', }}>
                                                                    <button style={{ backgroundColor: color, fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} >{status}</button>
                                                                </span>
                                                            </TableCell>
                                                            <TableCell align={"center"}
                                                                style={{ top: 57, minWidth: 100, flexDirection: 'row', alignItems: 'left' }}>
                                                                <span style={{ cursor: 'pointer', }}>
                                                                    <button onClick={() => {
                                                                        verify(row.client_id, row.first_name, row.last_name, row.email, row.mobile, row.gst, row.isVerify)
                                                                    }} style={{ backgroundColor: 'rgb(107, 213, 107)', fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} >Verify</button>
                                                                </span>
                                                                <span style={{ cursor: 'pointer', marginLeft: '0.1rem', marginRight: '0.1rem' }}>
                                                                    <button style={{ backgroundColor: 'rgb(239, 122, 122)', fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} >Delete</button>
                                                                </span>
                                                                <span style={{ cursor: 'pointer', }}>
                                                                    <button style={{ backgroundColor: 'rgb(233, 198, 108)', fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} >Edit</button>
                                                                </span>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    )
                                }

                            </Table>
                        </div>
                    )
                }
                {
                    isVerify && (
                        <TabUserVerify id={id} fName={fName} lName={lName} email={email} gst={gst} mobile={mobile} isVerify={verified} />
                    )
                }
                {
                    data.length === 0 && (
                        <Box className='card' style={{ height: '64vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center' }} >
                            <img src='https://www.bing.com/images/blob?bcid=S90DZlUfIZkFqxcxoNWLuD9SqbotqVTdP7w' width='145px' />
                            <Typography>0 users</Typography>
                        </Box>
                    )
                }
            </TableContainer>
        </Paper>
    );
}