import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FaUserCircle } from 'react-icons/fa';
import { Box, Typography } from '@mui/material';

export default function LorryBroker({ lb }) {


    return (
        <Paper elevation={0}>
            <TableContainer sx={{ height: 65 + "vh" }} >
                <h5> Lorry Brokers</h5>
                <Table style={{ position: 'relative', top: '0.2rem' }}>
                    <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                        <TableRow>
                            <TableCell
                                align={"center"}
                                style={{ minWidth: 30, fontSize: 12, fontWeight: 'bold' }}
                            >
                                User
                            </TableCell>
                            <TableCell
                                align={"center"}
                                style={{ top: 57, minWidth: 120, fontWeight: 'bold' }}
                            >
                                Name
                            </TableCell>

                            <TableCell

                                align={"center"}
                                style={{ top: 57, minWidth: 120, fontWeight: 'bold' }}
                            >
                                Date
                            </TableCell>
                            <TableCell

                                align={"left"}
                                style={{ top: 57, minWidth: 100, fontWeight: 'bold' }}
                            >
                                Status
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {lb
                            .map((row) => {
                                let color = ''
                                let status = ''

                                if (row.isVerify === '1' && row.isDriver === 2 && row.isVehicle === 2) {
                                    status = 'active'
                                    color = 'rgb(107, 213, 107)'
                                } else {
                                    status = 'progress'
                                    color = 'rgb(233, 198, 108)'
                                }

                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                        <TableCell align={"center"}
                                            style={{ top: 57, minWidth: 30, fontSize: 42, color: color, }}><FaUserCircle /></TableCell>
                                        <TableCell align={"center"}
                                            style={{ top: 57, minWidth: 120, fontSize: 12 }}>{row.first_name} {row.last_name}</TableCell>
                                        <TableCell align={"center"}
                                            style={{ top: 57, minWidth: 120, fontSize: 12 }}>{row.date}</TableCell>
                                        <TableCell align={"left"}
                                            style={{ top: 57, minWidth: 100, flexDirection: 'row', alignItems: 'left' }}>
                                            <span style={{ cursor: 'pointer', }}>
                                                <button style={{ backgroundColor: color, fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} >{status}</button>
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
                {
                    lb.length === 0 && (
                        <Box className='card' style={{ height: '64vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center' }} >
                            <img src='https://www.bing.com/images/blob?bcid=S90DZlUfIZkFqxcxoNWLuD9SqbotqVTdP7w' width='145px' />
                            <Typography>0 users</Typography>
                        </Box>
                    )
                }
            </TableContainer>
        </Paper>
    );
}