 import React from 'react'
import Navbar from './TopNav/TopNav'
import Sidebar from './Sidebar/Sidebar'

const WelcomeAdmin = () => {
  return (
    <div>
      <Navbar/>
      <Sidebar/>
    </div>
  )
}

export default WelcomeAdmin