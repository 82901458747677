import { PieChart, Pie, Legend, Cell, ResponsiveContainer } from "recharts"

const data = [
  {
    count: 100,
    location: "Chennai",
  },
  {
    count: 80,
    location: "Hyderabad",
  },
  {
    count: 60,
    location: "Mumbai",
  },
  {
    count:50,
    location:'Banglore',
  }
]

const Chart = () => {
  return (
    <ResponsiveContainer width="100%" height={500}>
      <PieChart>
        <Pie
          cx="50%"
          cy="50%"
          data={data}
          startAngle={0}
          endAngle={360}
          nameKey="count"
          dataKey="count"
          label
        >
          <Cell name="Chennai" fill="#f8d50d" />
          <Cell name="Hyderabad" fill= "rgb(13,10,70)" />
          <Cell name="Mumbai" fill="#17a2b8" />
          <Cell name="Banglore" fill="gray" />
        </Pie>
        <Legend
          iconType="circle"
          layout="vertical"
          verticalAlign="middle"
          align="right"
        />
      </PieChart>
    </ResponsiveContainer>
  )
}

export default Chart