import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button } from '@mui/material';
import { FaUserCircle, FaWindowClose } from 'react-icons/fa';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SimpleBackdrop from '../../../BackDrop/BackDrop';


export default function AccBids() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [lwAcceptBids, setLwAcceptBids] = React.useState([])
    const [lbAcceptBids, setLbAcceptBids] = React.useState([])
    const [vehicle, setVehicle] = React.useState()
    const [capacity, setCapacity] = React.useState()
    const [material, setMaterial] = React.useState()
    const [endLocation, setEndLocation] = React.useState()
    const [startLocation, setSatrtLocation] = React.useState()
    const [bidId, setBidId] = React.useState()
    const [name, setName] = React.useState()

    const accBidsRes = () => {
        const email = sessionStorage.getItem('email')
        axios.post('https://pmapi.emedha.in/customer/bids-status/accept', {
            email
        }).then((res) => {
            setLwAcceptBids(res.data.res)
        })
    }

    React.useEffect(() => {
        accBidsRes()
    }, [])

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [wait, setWait] = React.useState(false)

    const [dispatch, setDispatch] = React.useState()
    const [distance, setDistance] = React.useState()

    const bidsDetails = (bidId, startLocation, endLocation, material, capacity, vehicle, name, distance, dispatch) => {

        setWait(true)
        setTimeout(() => {
            setMaterial(material)
            setSatrtLocation(startLocation)
            setDispatch(dispatch)
            setEndLocation(endLocation)
            setBidId(bidId)
            setCapacity(capacity)
            setVehicle(vehicle)
            setName(name)
            setWait((false))
            setOpen(true)
            setDistance(distance)
        }, 500)
    }

    const [isShowName, setIsShowName] = React.useState(false)

    return (
        <Paper elevation={0}>
            <h4>Failed Bids</h4>
            <TableContainer sx={{ height: 61 + "vh" }} >
                <Table style={{ position: 'relative', top: '0.2rem' }}>
                    <TableHead >
                        <TableRow>

                            <TableCell

                                align={"start"}
                                style={{ top: 57, minWidth: 120, fontWeight: 'bold' }}
                            >
                                Bid Id
                            </TableCell>

                            <TableCell

                                align={"start"}
                                style={{ top: 57, minWidth: 120, fontWeight: 'bold' }}
                            >
                                Dispatch Date
                            </TableCell>

                            <TableCell

                                align={"start"}
                                style={{ top: 57, minWidth: 120, fontWeight: 'bold' }}
                            >
                                Product
                            </TableCell>

                            <TableCell

                                align={"start"}
                                style={{ top: 57, minWidth: 120, fontWeight: 'bold' }}
                            >
                                Destination
                            </TableCell>

                            <TableCell

                                align={"start"}
                                style={{ top: 57, minWidth: 23, fontWeight: 'bold' }}
                            >
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lwAcceptBids
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>

                                        <TableCell align={"start"}
                                            style={{ top: 57, minWidth: 120, fontSize: 12 }}>{row.send_bids_id.substring(row.send_bids_id.length + -16, 0) + "........"}</TableCell>

                                        <TableCell align={"start"}
                                            style={{ top: 57, minWidth: 120, fontSize: 12 }}>{row.dispatchTime}</TableCell>

                                        <TableCell align={"start"}
                                            style={{ top: 57, minWidth: 120, fontSize: 12 }}>{row.material}</TableCell>
                                        <TableCell align={"start"}
                                            style={{ top: 57, minWidth: 120, fontSize: 12 }}>{row.endLocation}</TableCell>

                                        <TableCell align={"start"}
                                            style={{ top: 57, minWidth: 23, flexDirection: 'row', alignItems: 'center' }}>
                                            <span onClick={() => bidsDetails(row.send_bids_id, row.startLocation, row.endLocation, row.material, row.capacity, row.vehicle, row.customerName, row.distance, row.dispatchTime)} style={{ cursor: 'pointer' }}>
                                                <button style={{ backgroundColor: 'rgb(36, 145, 36)', fontSize: '0.9rem', border: 'none', borderRadius: '0.9rem', color: 'white' }} >View</button>
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>

                </Table>
                <Box sx={{ mt: 14 }} >
                    {
                        (lwAcceptBids.length === 0) && (
                            <div className='card' style={{ alignItems: 'center', boxShadow: 'none', justifyContent: 'center', border: 'none' }}>
                                <img width='80px' src='https://img.freepik.com/free-vector/auction-house-abstract-concept-vector-illustration-residential-commercial-property-auction-buy-sell-assets-online-exclusive-bid-consecutive-biddings-business-auctions-abstract-metaphor_335657-1956.jpg?size=626&ext=jpg&ga=GA1.1.415759594.1681714138&semt=robertav1_2_sidr' />
                                <h5>No Confirm Bids</h5>
                            </div>
                        )
                    }
                </Box>
            </TableContainer>
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='lg'
                >
                    <DialogContent style={{ padding: 20 }}>
                        <div className='card' style={{ height: '4.8rem', width: '67rem', flexDirection: 'row', alignItems: 'center', borderRadius: '0.1rem' }} >
                            <FaWindowClose onClick={() => setOpen(false)} style={{ fontSize: 42, color: 'lightgray', marginLeft: 10, cursor: 'pointer' }} />
                            <h4 style={{ marginTop: 9.6, marginLeft: 7 }}>Bid Details</h4>
                        </div>
                        <div style={{ marginTop: 32 }}>
                            <h5 style={{ fontSize: 17, fontWeight: 'bold' }}>Bid ID: {bidId}</h5>

                            <h5>Name: {
                                isShowName && (
                                    <span style={{ fontSize: 19 }} >
                                        {name}
                                    </span>
                                )
                            }
                                <span style={{ fontSize: 12, color: 'rgb(225, 174, 78)', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setIsShowName(!isShowName)} > {
                                    isShowName ? <span style={{ fontSize: 12, color: 'rgb(225, 174, 78)', textDecoration: 'underline', cursor: 'pointer' }} >Hide</span> : <span style={{ fontSize: 12, color: 'rgb(225, 174, 78)', textDecoration: 'underline', cursor: 'pointer' }}>Show</span>
                                } Name</span>  </h5>
                            <br />
                            <div style={{ backgroundColor: 'rgb(249, 234, 197)', marginTop: 20, paddingLeft: 10, borderRadius: '0.2rem' }}>
                                <h5>Your Route</h5>
                                <div style={{ borderBottom: '2px solid white' }} ></div>
                                <div style={{ display: 'flex', position: 'relative', top: 16 }}>
                                    <p style={{ marginRight: 40 }}>From: {startLocation}</p>
                                    <p>To: {endLocation}</p>
                                </div>
                            </div>
                            <div style={{ marginTop: 10, backgroundColor: 'rgb(240, 239, 239)', paddingLeft: 10, paddingTop: 5, paddingBottom: 5 }}>
                                <h6>Vehicle Type:- {vehicle}</h6>
                                <h6>Vehicle Capacity:- {capacity}</h6>
                                <h6>Product Category / Product Type:- {material}</h6>
                                <h6>DIstance:- {distance}</h6>
                                <h6>Dispatch Date:- {dispatch}</h6>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
            {
                wait && (
                    <SimpleBackdrop />
                )
            }
        </Paper>
    );
}