import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import 'react-circular-progressbar/dist/styles.css';
import DashShowProfile from '../../../DashBoardShowProfile/DashboardShowProfile';
import AccBids from '../AllConFirmBids/AcceptBids';
import Footer from '../Footer/Footer';
import AllSuccBids from '../all_successfull_bids/AllSuccessFullBids';
import AllExpireBids from '../AllExpireBids/AllExpirBids';
import Users from '../customer_current_bids/Customer_Current_Bids';
import Chart from '../Chart/Chart';
import BarCharts from '../BarCharts';
import CustomerDashboard from '../CustomerDashboard';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'auto',
    color: theme.palette.text.secondary,
}));


export default function FullWidthGrid() {

    return (
        <Box sx={{ flexGrow: 1, p: 0, mt: 5 }} className='ps-3 pe-3 pt-4' >
            
            <Grid container spacing={2} sx={{ mb: 4 }}>
                <Grid sx={{ height: 8.6 + 'rem' }} xs={12} md={12}>
                    <CustomerDashboard />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid sx={{ mt: 1 }} xs={12} md={12}>
                    <Item sx={{ height: 70 + 'vh', p: 2 }} elevation={1} >
                        <Users />
                    </Item>
                </Grid>
                <Grid sx={{ mt: 1 }} xs={12} md={12}>
                    <Item elevation={1} sx={{ p: 2, height: 70 + 'vh' }} >
                        <AccBids />
                    </Item>
                </Grid>

                <Grid xs={12} md={12}>
                    <Item sx={{ height: 70 + 'vh', p: 2 }} elevation={1} >
                        <AllSuccBids />
                    </Item>
                </Grid>
                <Grid xs={12} md={12}>
                    <Item sx={{ height: 70 + 'vh', p: 2 }} elevation={1} >
                        <AllExpireBids />
                    </Item>
                </Grid>
            </Grid>
            <Footer></Footer>
        </Box>
    );
}