import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import axios from 'axios';
import { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Swal from "sweetalert2";
import MyTimer from './Timer';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function TopThreeLowestBids() {

    const time = new Date();
    // 10 minutes timer



    const [open5, setOpen5] = React.useState(false);
    const [open3, setOpen3] = useState(false)



    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'auto',
        color: theme.palette.text.secondary,
    }));

    const [open2, setOpen2] = React.useState(false);

    const [isAlert, setAlert] = React.useState()

    const kycStatus = () => {
        const email = sessionStorage.getItem('email')
        const type = sessionStorage.getItem('type')
        axios.post('https://pmapi.emedha.in/users/kyc/status', {
            email,
            type
        }).then((res) => {
            if ((res.data.isAadhar === 1 && res.data.isAddGst === 1) || (res.data.isAadhar === 1 && res.data.isAddGst === 2)) {
            } else {
                setTimeout(() => setAlert(true), 400)
            }
        })
    }


    useEffect(() => {
        kycStatus()
        setOpen3(true)
    }, [])

    const handleClose = () => {
        setOpen(false);
    };


    const [name, setName] = useState()
    const [lastName, setLastName] = useState()

    const [open, setOpen] = React.useState(false);
    const [money, setMoney] = useState([])
    const [isPayment, setIsPayment] = useState('')
    const [rMoney, setRMoney] = useState('')
    const [open1, setOpen1] = React.useState(false);
    const [bidId, setBidId] = useState()
    const [halfAmm, setHalfAmm] = useState('')
    const [transporterId, setTransporterId] = useState()

    const payHalfAmmount = () => {
        setOpen1(false)
        setOpen2(true)
        setHalfAmm(isPayment)
    }


    // const payment = (hamm, client_id) => {

    //     setWait1(true)
    //     axios.post('https://pmapi.emedha.in/payment/successful/10pre', {
    //         bidId,
    //         hamm,
    //         rMoney,
    //         transporterId,
    //         client_id
    //     }).then((res) => {
    //         if (res.data.status === 200) {
    //             bidSuccessFullySend()
                // setOpen1(false)
                // setOpen(false)
                // setOpen2(false)
    //             acceptedBidsPrice()
    //             setWait1(false)
    //         }
    //     })
    // }

    const [payUrl, setPayUrl] = useState('');
    const [isPayConfirm, setIsPayConfirm] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [loading, setLoading] = useState(false);
   
    const payment = (hamm, client_id) => {
        if (loading) return; // Prevent multiple requests if already loading
        setLoading(true);
    
    
        axios.post('https://pmapi.emedha.in/payment/pay/v1/pay', {
          payText: hamm,
          clientId:transporterId, // Uncomment if needed
          bId: bidId,
        })
          .then((res) => {
            if (res.data.success) {
              setPayUrl(res.data.url);
              window.open(res.data.url);
              setIsPayConfirm(true);
              setOpen4(true);
              setOpen1(false)
              setOpen(false)
              setOpen2(false)
              setWait1(false)
            //   bidSuccessFullySend()
            } else {
              console.error('API Response Error:', res.data.message || 'Unknown error');
              alert('Something went wrong');
            }
          })
          .catch((error) => {
            console.error('API Request Error:', error);
            setOpen4(false);
            alert('Payment Error');
          })
          .finally(() => {
            setLoading(false); 
          });
    
  
    }


    const acceptedBidsPrice = () => {
        const email = sessionStorage.getItem('email')
        const show = 'noaccept'
        axios.post('https://pmapi.emedha.in/send/bids/top-three/price', {
            email,
            show
        }).then((res) => {
            setMoney(res.data.result)
        })
    }

    useEffect(() => {

        acceptedBidsPrice()

    }, [])

    const userName = () => {
        const email = sessionStorage.getItem('email')
        axios.post('https://pmapi.emedha.in/profile', {
            email
        }).then((res) => {
            setName(res.data.first_name)
            setLastName(res.data.last_name)
        })
    }

    useEffect(() => {
        userName()
    }, [])

    const [wait1, setWait1] = useState(false)

    const bidSuccessFullySend = () => {
        Swal.fire({
            title: "Bid Successful",
            text: "Your 10%  payment successful, thank you !...",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Continue",
        }).then((result) => {
            if (result.value) {
                setWait1(false)
                setOpen1(false)
                setOpen(false)
                setOpen2(false)
            } else {
                window.location.reload()
            }
        });
    }

    const [check180, setCheck180] = useState(false)

    const check180min = (bidId) => {
        axios.post('https://pmapi.emedha.in/customer/bids-status/check180min', {
            bidId
        }).then((res) => {
            if (res.data.status === 409) {
                Swal.fire({
                    title: "Wait..!",
                    text: `Your bid is Live please wait: ${ res.data.minutus } min `,
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ok",
                })
            } else {
                if (res.data.status === 429) {
                    acceptedBidsPrice()
                    Swal.fire({
                        title: "Wait..!",
                        text: ` Your bid is Expired `,
                        showCancelButton: false,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Ok",
                    })
                } else {
                    setCheck180(false)
                    setOpen1(true)
                }
            }
        })
    }



    return (
        <Box>
            {
                (money.length > 0) && (
                    <Box sx={{ mt: 4, minWidth: 400 }}>
                        {
                            check180 && (
                                <Typography style={{ color: 'red', position: 'relative', top: 10 }}>Your bid is Live</Typography>
                            )
                        }
                        <div>
                            {
                                money.map((data, index) => {
                                    let flm = ''
                                    let flmPaySucc = ''
                                    let slmPaySucc = ''
                                    let tlmPaySucc = ''
                                    let tlm = ''
                                    let slm = ''
                                    if (index === 0) {
                                        flm = data.money
                                        if (data.is5Pre === 1) {
                                            flmPaySucc = true
                                        } else {
                                            flmPaySucc = false
                                        }
                                    } else {
                                        if (index === 1) {
                                            slm = data.money
                                            if (data.is5Pre === 1) {
                                                slmPaySucc = true
                                            } else {
                                                slmPaySucc = false
                                            }
                                        } else {
                                            if (index === 2) {
                                                tlm = data.money
                                                sessionStorage.setItem('lastLowestBids', data.money)
                                                if (data.is5Pre === 1) {
                                                    tlmPaySucc = true
                                                } else {
                                                    tlmPaySucc = false
                                                }
                                            } else {
                                                return
                                            }
                                        }
                                    }
                                    return (

                                        <div className='mb-2 mt-4'>

                                            {
                                                flm !== '' && (
                                                    <div>

                                                        <Card elevation={1} sx={{ maxWidth: 100 + '%', mb: 2, backgroundColor: 'rgb(208, 241, 253)', position: 'relative' }}>
                                                            <div>
                                                                <MyTimer expiryTimestamp={time.setSeconds(data.currentMinuts * 60)} />
                                                            </div>
                                                            <Typography style={{ fontSize: 11, marginLeft:16, marginTop:10 }}> Bid ID: {data.acceptedBids_id}</Typography>
                                                            <CardContent>
                                                                <Typography gutterBottom variant="h5" component="div">
                                                                    <Typography style={{ width: '15rem' }} variant='outlined'>RS {flm} Bid Price </Typography>
                                                                </Typography>
                                                                <Typography variant='h6' sx={{ marginBottom: 1.3, marginTop: 2 }} >Number of vehicle: {data.vehicleReqNum} Vehicle</Typography>
                                                                <Typography variant="body2" color="text.secondary">
                                                                    Accept Bid And Grow Your Business Fastlly with your trusted partner
                                                                </Typography>
                                                            </CardContent>
                                                            <CardActions>
                                                                {
                                                                    flmPaySucc && (
                                                                        <Button disabled={true} variant='outlined' onClick={() => {
                                                                            setBidId(data.acceptedBids_id)
                                                                            setOpen(false)
                                                                            const pay = (data.money) * (10 / 100)
                                                                            const rMoney = (data.money) * (90 / 100)
                                                                            setRMoney(rMoney)
                                                                            setIsPayment(pay)
                                                                            setOpen1(true)
                                                                        }} >Payment SuccessFull</Button>
                                                                    )
                                                                }
                                                                {
                                                                    !flmPaySucc && (
                                                                        <div>
                                                                            <Button style={{ width: '7.5rem', backgroundColor: 'white', color: '#1f1f46', border: '1.1px solid #1f1f46', borderRadius: '1rem' }} variant='outlined' onClick={() => {
                                                                                setBidId(data.acceptedBids_id)
                                                                                setOpen(false)
                                                                                const pay = (data.money) * (10 / 100)
                                                                                const rMoney = (data.money) * (90 / 100)
                                                                                setRMoney(rMoney)
                                                                                setIsPayment(pay)
                                                                                check180min(data.acceptedBids_id)
                                                                                setTransporterId(data.client_id)
                                                                            }} >Pay</Button>
                                                                        </div>
                                                                    )
                                                                }
                                                            </CardActions>
                                                            <img src="https://th.bing.com/th/id/OIP.751eQBsm0kWu65L0EbLZ6wHaG4?w=187&h=180&c=7&r=0&o=5&dpr=2.5&pid=1.7" width='120px' style={{ position: 'absolute', right: '6px', bottom: '6px', borderRadius: '50%' }} />
                                                        </Card>
                                                    </div>
                                                )
                                            }
                                            {
                                                slm !== '' && (
                                                    <div>

                                                        <Card elevation={1} sx={{ maxWidth: 100 + '%', mb: 2, backgroundColor: 'rgb(208, 241, 253)', position: 'relative' }}>
                                                            <div>
                                                                <MyTimer expiryTimestamp={time.setSeconds(data.currentMinuts * 60)} />
                                                            </div>
                                                            <Typography style={{ fontSize: 11, marginLeft:16, marginTop:10 }}> Bid ID: {data.acceptedBids_id}</Typography>
                                                            <CardContent>
                                                                <Typography gutterBottom variant="h5" component="div">
                                                                    <Typography style={{ width: '15rem' }} variant='outlined'>RS {slm} Bid Price </Typography>
                                                                </Typography>
                                                                <Typography variant='h6' sx={{ marginBottom: 1.3, marginTop: 2 }} >Number of vehicle: {data.vehicleReqNum} Vehicle</Typography>
                                                                <Typography variant="body2" color="text.secondary">
                                                                    Accept Bid And Grow Your Business Fastlly with your trusted partner
                                                                </Typography>
                                                            </CardContent>
                                                            <CardActions>
                                                                {
                                                                    slmPaySucc && (
                                                                        <Button disabled={true} variant='outlined' onClick={() => {
                                                                            setBidId(data.acceptedBids_id)
                                                                            setOpen(false)
                                                                            const pay = (data.money) * (10 / 100)
                                                                            const rMoney = (data.money) * (90 / 100)
                                                                            setRMoney(rMoney)
                                                                            setIsPayment(pay)
                                                                            check180min(data.acceptedBids_id)
                                                                        }} >Payment SuccessFull</Button>
                                                                    )
                                                                }
                                                                {
                                                                    !slmPaySucc && (
                                                                        <div>
                                                                            <Button style={{ width: '7.5rem', backgroundColor: 'white', color: '#1f1f46', border: '1.1px solid #1f1f46', borderRadius: '1rem' }} variant='outlined' onClick={() => {
                                                                                setBidId(data.acceptedBids_id)
                                                                                setOpen(false)
                                                                                const pay = (data.money) * (10 / 100)
                                                                                const rMoney = (data.money) * (90 / 100)
                                                                                setRMoney(rMoney)
                                                                                setIsPayment(pay)
                                                                                check180min(data.acceptedBids_id)
                                                                                setTransporterId(data.client_id)
                                                                            }} >Pay</Button>
                                                                        </div>
                                                                    )
                                                                }

                                                            </CardActions>
                                                            <img src="https://th.bing.com/th/id/OIP.751eQBsm0kWu65L0EbLZ6wHaG4?w=187&h=180&c=7&r=0&o=5&dpr=2.5&pid=1.7" width='120px' style={{ position: 'absolute', right: '6px', bottom: '6px', borderRadius: '50%' }} />
                                                        </Card>
                                                    </div>
                                                )
                                            }
                                            {
                                                tlm !== '' && (
                                                    <div>
                                                        <Card elevation={1} sx={{ maxWidth: 100 + '%', mb: 2, backgroundColor: 'rgb(208, 241, 253)', position: 'relative' }} >
                                                            <div>
                                                                <MyTimer expiryTimestamp={time.setSeconds(data.currentMinuts * 60)} />
                                                            </div>
                                                            <Typography style={{ fontSize: 11, marginLeft:16, marginTop:10 }}> Bid ID: {data.acceptedBids_id}</Typography>
                                                            <CardContent>
                                                                <Typography gutterBottom variant="h5" component="div">
                                                                    <Typography style={{ width: '15rem' }} variant='outlined'>RS {tlm} Bid Price  </Typography>
                                                                </Typography>
                                                                <Typography variant='h6' sx={{ marginBottom: 1.3, marginTop: 2 }} >Number of vehicle: {data.vehicleReqNum} Vehicle</Typography>
                                                                <Typography variant="body2" color="text.secondary">
                                                                    Accept Bid And Grow Your Business Fastlly with your trusted partner
                                                                </Typography>
                                                            </CardContent>
                                                            <CardActions>

                                                                {
                                                                    tlmPaySucc && (
                                                                        <Button disabled={true} variant='outlined' onClick={() => {
                                                                            setBidId(data.acceptedBids_id)
                                                                            setOpen(false)
                                                                            const pay = (data.money) * (10 / 100)
                                                                            const rMoney = (data.money) * (90 / 100)
                                                                            setRMoney(rMoney)
                                                                            setIsPayment(pay)
                                                                            setOpen1(true)
                                                                        }} >Payment SuccessFull</Button>
                                                                    )
                                                                }
                                                                {
                                                                    !tlmPaySucc && (
                                                                        <div>
                                                                            <Button style={{ width: '7.5rem', backgroundColor: 'white', color: '#1f1f46', border: '1.1px solid #1f1f46', borderRadius: '1rem' }} variant='outlined' onClick={() => {
                                                                                setBidId(data.acceptedBids_id)
                                                                                setOpen(false)
                                                                                const pay = (data.money) * (10 / 100)
                                                                                const rMoney = (data.money) * (90 / 100)
                                                                                setRMoney(rMoney)
                                                                                setIsPayment(pay)
                                                                                check180min(data.acceptedBids_id)
                                                                                setTransporterId(data.client_id)
                                                                            }} >Pay</Button>
                                                                        </div>
                                                                    )
                                                                }

                                                            </CardActions>
                                                            <img src="https://th.bing.com/th/id/OIP.751eQBsm0kWu65L0EbLZ6wHaG4?w=187&h=180&c=7&r=0&o=5&dpr=2.5&pid=1.7" width='120px' style={{ position: 'absolute', right: '6px', bottom: '6px', borderRadius: '50%' }} />
                                                        </Card>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>


                    </Box>
                )
            }
            {
                (money.length === 0) && (
                    <Grid item xs={12}>
                        <Item elevation={0} style={{ height: 65 + 'vh', alignItems: 'center', paddingRight: 4 }}>
                            <div className='card' style={{ height: 64 + 'vh', alignItems: 'center', boxShadow: 'none', justifyContent: 'center', border: 'none' }}>
                                <img width='150px' src='https://img.freepik.com/free-vector/auction-house-abstract-concept-vector-illustration-residential-commercial-property-auction-buy-sell-assets-online-exclusive-bid-consecutive-biddings-business-auctions-abstract-metaphor_335657-1956.jpg?size=626&ext=jpg&ga=GA1.1.415759594.1681714138&semt=robertav1_2_sidr' />
                                <h3>0 Bids Offer</h3>
                            </div>
                        </Item>
                    </Grid>
                )
            }
            <div>
                <Dialog
                    open={open1}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Payment"}</DialogTitle>
                    <DialogContent style={{width:'31rem'}} >
                        <DialogContentText id="alert-dialog-slide-description">
                            Make Payment to the further
                        </DialogContentText>
                        <Button style={{ color: 'gray', width:'100%' }} variant='outlined'>10% Payment money is {isPayment}</Button>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={payHalfAmmount}>Payment</Button>
                        <Button onClick={() => setOpen1(false)}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </div>
            <div>
                <Dialog open={open2} >
                    <DialogTitle style={{ fontSize: 20, textAlign: 'center' }} >Payment Getway</DialogTitle>
                    <DialogTitle>Pay 10% amount to the bid confirmation</DialogTitle>
                    <DialogContent style={{width:'35rem'}} >

                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Bid Confirmation Price"
                            type="email"
                            fullWidth
                            variant="standard"
                            value={halfAmm}
                        />
                    </DialogContent>
                    <DialogActions>
                        {
                            !wait1 && (
                                <Button style={{ width: '7.8rem', backgroundColor: '#1f1f46', color: 'white' }} onClick={() => {
                                    payment(halfAmm)
                                }} >Pay</Button>
                            )
                        }
                        {
                            wait1 && (
                                <Button disabled={true} variant='contained' style={{ width: '7.5' }} >Paying...</Button>
                            )
                        }
                        <Button onClick={() => {
                            setOpen1(false)
                            setOpen(false)
                            setOpen2(false)
                        }} style={{ width: '7.8rem', backgroundColor: 'red', color: 'white' }} >Cancel</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Box>
    );
}