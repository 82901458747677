import { useState } from 'react';
import { FaBusinessTime, FaIdCard } from 'react-icons/fa';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography } from '@material-ui/core';
import DashShowProfile from '../DashBoardShowProfile/DashboardShowProfile';
import { FaBusAlt, FaUser } from 'react-icons/fa';
import LorryWalaGst from '../LorryWalaGst/LorryWalaGst';
import LorryWalaAadhar from '../LorryWalaAadhar/LorryWalaAadhar';
import LorryWalaDriver from '../LorryWalaDriver/LorryWalaDriver';
import LorryWalaVehicle from '../LorryWalaVehicle/LorryWalaVehicle';

const UpdateKyc = (props) => {

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'auto',
        color: theme.palette.text.secondary,
    }));

    const [isgst, setIsGst] = useState(false)
    const [isKyc, setIsKyc] = useState(true)
    const [isVehicle, setIsVehicle] = useState(false)
    const [isDriver, setIsDriver] = useState(false)

    return (
        <div>
            <Box sx={{ flexGrow: 1, p: 0, mt: 4 }}>
                
                <Grid container spacing={2}>
                    <Grid xs={8} md={3}>
                        <Item elevation={1} sx={{ height: '86vh' }} >
                            <Card elevation={0} sx={{ maxWidth: '100%' }}>
                                <CardContent>
                                    <Typography style={{ fontSize: '0.7rem' }} variant="body2" color="text.secondary">
                                        KYC means Know Your Customer and sometimes Know Your Client. KYC or KYC check is the mandatory process of identifying and verifying the client's identity when opening an account and periodically over time. In other words, banks must make sure that their clients are genuinely who they claim to be
                                    </Typography>
                                    <hr />
                                    <Typography>
                                        <div width={{ width: '100%' }} >
                                            <ul>
                                                <li
                                                    style={{ backgroundColor: isKyc ? '#12124e' : '', color: isKyc ? 'white' : '' }} className='kyc-link' onClick={() => {
                                                        setIsGst(false)
                                                        setIsKyc(true)
                                                        setIsDriver(false)
                                                        setIsVehicle(false)

                                                    }}>
                                                    <FaIdCard style={{ position: 'relative', top: '-0.1rem' }} fontSize={20} /> Aadhar Details
                                                </li>

                                                <li style={{ backgroundColor: isgst ? '#12124e' : '', color: isgst ? 'white' : '' }} className='kyc-link' onClick={() => {
                                                    setIsGst(true)
                                                    setIsKyc(false)
                                                    setIsDriver(false)
                                                    setIsVehicle(false)

                                                }}>
                                                    <FaBusinessTime style={{ position: 'relative', top: '-0.1rem' }} fontSize={20} /> Business Details
                                                </li>
                                                <li
                                                    style={{ backgroundColor: isVehicle ? '#12124e' : '', color: isVehicle ? 'white' : '' }} className='kyc-link' onClick={() => {
                                                        setIsGst(false)
                                                        setIsKyc(false)
                                                        setIsDriver(false)
                                                        setIsVehicle(true)
                                                    }}>
                                                    <FaBusAlt style={{ position: 'relative', top: '-0.1rem' }} fontSize={20} /> Vehicle Details
                                                </li>

                                                <li style={{ backgroundColor: isDriver ? '#12124e' : '', color: isDriver ? 'white' : '' }} className='kyc-link' onClick={() => {
                                                    setIsGst(false)
                                                    setIsKyc(false)
                                                    setIsDriver(true)
                                                    setIsVehicle(false)
                                                }}>
                                                    <FaUser style={{ position: 'relative', top: '-0.1rem' }} fontSize={20} /> Driver Details
                                                </li>
                                            </ul>
                                        </div>
                                    </Typography>
                                </CardContent>

                            </Card>
                        </Item>
                    </Grid>
                    <Grid xs={9}>
                        <Item elevation={1} sx={{ height: '86vh', overflowY: 'scroll' }}>
                            <Card elevation={0} >
                                <CardContent>
                                    <Typography variant="body2" color="text.secondary">
                                        {
                                            isgst && (
                                                <div>
                                                    <LorryWalaGst />
                                                </div>
                                            )
                                        }
                                        {
                                            isKyc && (
                                                <div>
                                                    <LorryWalaAadhar aadhar_card={props.aadhar_card} aIsAdd={props.aIsAdd} />
                                                </div>
                                            )
                                        }

                                        {
                                            isDriver && (
                                                <div>
                                                    <LorryWalaDriver AllDriver = {props.AllDriver} />
                                                </div>
                                            )
                                        }

                                        {
                                            isVehicle && (
                                                <div>
                                                    <LorryWalaVehicle />
                                                </div>
                                            )
                                        }
                                    </Typography>
                                </CardContent>
                            </Card>

                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default UpdateKyc