import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FaUserCircle } from 'react-icons/fa';
import { Box, Typography } from '@mui/material';

export default function CorporateCustomer({ sbids }) {


    const rows = [

    ];

    return (
        <Paper elevation={0}>
            <TableContainer sx={{ height: 65 + "vh" }} >
                <h5> Corporate Customer</h5>
                <Table style={{ position: 'relative', top: '0.2rem' }}>
                    <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                        <TableRow>
                            <TableCell
                                align={"center"}
                                style={{ minWidth: 30, fontSize: 12, fontWeight: 'bold' }}
                            >
                                User
                            </TableCell>
                            <TableCell
                                align={"center"}
                                style={{ top: 57, minWidth: 120, fontWeight: 'bold' }}
                            >
                                Name
                            </TableCell>

                            <TableCell

                                align={"center"}
                                style={{ top: 57, minWidth: 120, fontWeight: 'bold' }}
                            >
                                Date
                            </TableCell>
                            <TableCell

                                align={"left"}
                                style={{ top: 57, minWidth: 100, fontWeight: 'bold' }}
                            >
                                Status
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {rows
                            .map((row) => {
                                let status = ''
                                let color = ''
                                if (row.status === 0) {
                                    status = 'decline'
                                    color = 'rgb(239, 122, 122)'
                                } else {
                                    if (row.status === 2) {
                                        status = 'progress'
                                        color = 'rgb(233, 198, 108)'
                                    } else {
                                        status = 'active'
                                        color = 'rgb(107, 213, 107)'
                                    }
                                }
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                        <TableCell align={"center"}
                                            style={{ top: 57, minWidth: 30, fontSize: 42, color: color, }}><FaUserCircle /></TableCell>
                                        <TableCell align={"center"}
                                            style={{ top: 57, minWidth: 120, fontSize: 12 }}>Sonu</TableCell>
                                        <TableCell align={"center"}
                                            style={{ top: 57, minWidth: 120, fontSize: 12 }}>15/07/2001</TableCell>
                                        <TableCell align={"left"}
                                            style={{ top: 57, minWidth: 100, flexDirection: 'row', alignItems: 'left' }}>
                                            <span style={{ cursor: 'pointer', }}>
                                                <button style={{ backgroundColor: color, fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} >{status}</button>
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
                {
                    rows.length === 0 &&(
                        <Box className='card' style={{ height: '52vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center',border:'none' }} >
                            <img src='https://img.freepik.com/free-vector/no-data-concept-illustration_114360-2506.jpg?size=626&ext=jpg&ga=GA1.1.415759594.1681714138&semt=ais' width='145px' />
                            <Typography>0 Corporate users</Typography>
                        </Box>
                    )
                }
            </TableContainer>
        </Paper>
    );
}