 import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import SimpleBackdrop from '../../BackDrop/BackDrop'

const ForgotPassLinkVerification = () => {
    
    const { userType, token, client_id } = useParams()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)

    const linkValidation = () => {
        axios.post('https://pmapi.emedha.in/password/link/verification', {
            token,
        }).then((res) => {
            if (res.data.status === 200) {
                setIsLoading(true)
                setTimeout(() => setIsLoading(false), 2000)
                setTimeout(() => navigate(`/reset-password/${client_id}/${userType}`), 2001)

            }else {
                alert("link expire")
            }
        })
    }

    useEffect(() => {
        linkValidation()
    }, [])

    return (
        <div>
            {
                isLoading && (
                    <center>
                         <SimpleBackdrop/>
                    </center>
                )
            }
        </div>
    )
}

export default ForgotPassLinkVerification