import React, { useState, useEffect } from 'react'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { FaTimes, FaExclamationTriangle, FaLeaf } from 'react-icons/fa';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import ver from '../GlobleImageContainer/images/ver.png'
import busin from '../GlobleImageContainer/images/bus.jpg'
import Button from '@mui/material/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { FaEdit, FaWindowClose } from 'react-icons/fa';
import Swal from "sweetalert2";

const CustomerGst = () => {

    const [wait7, setWait7] = useState(false)

    const GstUpdate = () => {
        Swal.fire({
            title: "Successful..!",
            text: "Your business data successfully updated...!",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Dashboard",
        }).then((result) => {
            if (result.value) {
                window.location.reload()
            } else {
                setOpen(false)
                setWait7(false)
            }
        });
    }

    const NoGstAl = () => {
        Swal.fire({
            title: "No GST..!",
            cancelButtonText: 'Cancel',
            text: "Add business details and improve you profile...!",
            showCancelButton: 'Cancel',
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Dashboard",
        }).then((result) => {
            if (result.value) {
                window.location.reload()
            } else {
                setOpen(false)
                setIsUpdateGst(false)
                kycStatus()
            }
        });
    }

    const GstError = (err) => {
        Swal.fire({
            title: "Error..!",
            text: err,
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
        }).then((result) => {
            if (result.value) {
                setWait7(false)
            } else {
                setOpen(false)
                setWait7(false)
            }
        });
    }



    const [GstValidated, setGstValidated] = useState(false);
    const [GstValidated1, setGstValidated1] = useState(false);

    const handleSubmitGst = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            gstPanValidation()
        }
        setGstValidated(true);
    };

    const handleSubmitGst1 = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            gstPanValidation()
        }
        setGstValidated1(true);
    };




    const [error, setError] = useState(false)
    const [isGst, setIsGst] = useState(false)
    const [GST, setGst] = useState('')
    const [pan, setPan] = useState('')
    const [isAadhar, setIsAadhar] = useState(false)
    const [isGstOpt, setIsGstOpt] = useState(true)
    const [isOtp, setIsOtp] = useState(false)
    const [isForm, setIsForm] = useState(false)

    const [firm, setFirm] = useState('')
    const [business, setBusiness] = useState('')
    const [isProfileSubMenu, setIsprofileSubmenu] = useState(false)
    const [isBank, setIsBank] = useState(false)


    const [newGst, setNewGst] = useState()
    const [newGst1, setNewGst1] = useState()
    const [newGst2, setNewGst2] = useState()
    const [newPan, setNewPan] = useState()

    const gstPanValidation = () => {
        const CCEncoded = GST.substring(GST.length + -3, 2);
        const CCEncoded1 = GST.substring(GST.length + 0, 12);
        const CCEncoded2 = GST.substring(GST.length + -13, 0);

        if (CCEncoded === pan) {
            customerBusinessDatAdd()
        } else {
            setNewGst(CCEncoded)
            setNewGst1(CCEncoded1)
            setNewGst2(CCEncoded2)
            setNewPan(pan)
            setError('yes')
        }

    }



    const [gstImage, setGstImage] = useState()

    const customerBusinessDatAdd = () => {
        setWait7(true)
        const client_id = sessionStorage.getItem('client_id')

        const formData = new FormData();
        formData.append('file', gstImage);
        formData.append('firm', firm);
        formData.append('pan', pan);
        formData.append('gst', GST);
        formData.append('business', business);
        formData.append('client_id', client_id);
        formData.append('userType', 'Customer');

        axios.post('https://pmapi.emedha.in/business/data/add', formData).then((res) => {
            if (res.data.status === 200) {
                GstUpdate()
                setOpen(false)
                setWait7(false)
            } else {
                GstError(res.data.message)
                setOpen(false)
            }
        })
    }

    const gstNo = () => {
        const email = sessionStorage.getItem('email')
        const userType = "Customer"
        axios.post('https://pmapi.emedha.in/business/data/no-gst', {
            email,
            userType
        }).then((res) => {
            NoGstAl()
            setIsForm(false)
            setIsBank(false)
            setIsGst(false)
            setIsGstOpt(false)
            setIsprofileSubmenu(false)
        })
    }

    const [gstAvle, setGstAvle] = useState('')

    const yesNoGst = (gst) => {
        if (gst === 'Yes') {
            setIsGstOpt(false)
            setIsGst(true)
            setIsForm(true)
            setIsAadhar(false)
            setIsBank(false)
            setIsOtp(false)
            setGstAvle('Yes')
            kycStatus()
        } else {
            gstNo()
            setGstAvle('No')
            kycStatus()
            setIsUpdateGst(false)
        }
    }


    const [isKyc, setIsKyc] = React.useState()
    const [isGstDisabled, setIsGstDisabled] = useState(false)

    const kycStatus = () => {
        const email = sessionStorage.getItem('email')
        const type = sessionStorage.getItem('type')
        axios.post('https://pmapi.emedha.in/users/kyc/status', {
            email,
            type
        }).then((res) => {
            setIsKyc(res.data.isAddGst)
            if (res.data.isAddGst === 1) {
                setGstAvle('Yes')
                setIsGstDisabled(true)
            }
        })
    }

    useEffect(() => {
        kycStatus()
    }, []);

    const showGst = () => {
        const client_id = sessionStorage.getItem('client_id')
        const type = 'Customer'
        axios.post('https://pmapi.emedha.in/business/data/show', {
            client_id,
            type
        }).then((res) => {
            setGst(res.data.gst)
            setBusiness(res.data.business)
            setFirm(res.data.firm)
            setPan(res.data.pan)
        })
    }

    useEffect(() => {
        showGst()
    }, [])

    const [open, setOpen] = React.useState(false);


    const handleClose = () => {
        setOpen(false);
        setIsOtp(false)
        setWait7(false)
    };

    const [isUpdateGst, setIsUpdateGst] = useState(false)

    return (
        <div className='p-3'>
            {
                ((isKyc === 1) || (isKyc === 0) || isUpdateGst) && (
                    <div className='col-lg-12 mx-auto '>
                        {
                            (isGstOpt && isKyc === 0 && gstAvle === '') && (
                                <Form.Group as={Col} controlId="validationCustom02">
                                    <Form.Select style={{ padding: '0.8rem' }}
                                        onChange={(e) => {
                                            yesNoGst(e.target.value)
                                        }}
                                        aria-label="Default select example">
                                        <option >GST Number</option>
                                        <option value="Yes">Yes </option>
                                        <option value="No">No</option>
                                    </Form.Select>
                                </Form.Group>
                            )
                        }

                        {
                            (gstAvle === 'Yes') && (
                                <div className='col-lg-12 mx-auto p-1' >
                                    {
                                        (error !== false) && (
                                            <center>
                                                <div className='errorgst mb-2 mt-2' >
                                                    <FaTimes onClick={() => setError(false)} style={{ position: 'absolute', top: '0.4rem', right: '0.4rem', cursor: 'pointer' }} />
                                                    <p className='text-start'><FaExclamationTriangle className='text-danger' />  <span style={{ position: 'relative', top: '0.13rem', left: '0.2rem' }}>
                                                        Gst number  <span style={{ fontSize: '1.3rem' }} >{newGst2} <span className='text-danger' >{newGst}</span> {newGst1}</span> must matched with pan card number
                                                        <span className='text-danger' style={{ fontSize: '1.3rem' }}>  {newPan}  </span> Please enter valid pan number
                                                    </span>
                                                    </p>
                                                </div>
                                            </center>
                                        )
                                    }
                                    {
                                        isGstDisabled && <div onClick={() => setOpen(true)} className='card' style={{ height: '1.5rem', border: '1px solid #12124e', borderRadius: '0.2rem', color: '#12124e', fontWeight: 'bold', width: '12rem', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', position: 'relative', top: 29, left: 200 }}><FaEdit size={13} style={{ position: 'relative', top: '-0.1rem' }} />Update Gst Number</div>
                                    }
                                    <h4>Business Details</h4>
                                    <p style={{ fontSize: '0.8rem' }}>A business description is a high-level overview of your company that you include in your business plan. Your business description should entice readers—like lenders and investors—to look through the rest of your business plan to learn more about your company.</p>
                                    <Form noValidate validated={GstValidated} onSubmit={handleSubmitGst} >
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Label>Gst Number</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Gst Number"
                                                    value={GST || ''}
                                                    disabled={isGstDisabled}
                                                    style={{ padding: '0.8rem' }}
                                                    onChange={(e) => {
                                                        setGst(e.target.value)
                                                    }}

                                                />
                                                {
                                                    isKyc === 1 && (
                                                        <div className='d-flex' >
                                                            <button disabled={true} style={{ position: 'relative', right: '0.6rem', fontWeight: 'bold', color: 'rgb(19, 192, 19)', border: 'none', backgroundColor: 'white', marginTop: '0.2rem', borderRadius: '1rem' }} > <img style={{ height: '22px', width: '22px', position: 'relative', top: '-0.12rem' }} src={ver} />Verified</button>

                                                        </div>
                                                    )
                                                }

                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Valid GST Number
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom02">
                                                <Form.Label>Firm Name</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Firm Name"
                                                    value={firm || ''}
                                                    disabled={isGstDisabled}
                                                    style={{ padding: '0.8rem' }}
                                                    onChange={(e) => {
                                                        setFirm(e.target.value)
                                                    }}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Valid Firm Name
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                                                <Form.Label>Nature Of Business</Form.Label>
                                                <InputGroup hasValidation>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Nature Of Business"
                                                        aria-describedby="inputGroupPrepend"
                                                        required
                                                        value={business || ''}
                                                        disabled={isGstDisabled}
                                                        style={{ padding: '0.8rem' }}
                                                        onChange={(e) => {
                                                            setBusiness(e.target.value)
                                                        }}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter Valid Nature Of Business.
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group as={Col} md="12" controlId="validationCustom02">
                                                <Form.Label>Pan Card Number</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Pan Card Number"
                                                    aria-describedby="inputGroupPrepend"
                                                    required
                                                    value={pan || ''}
                                                    disabled={isGstDisabled}
                                                    style={{ padding: '0.8rem' }}
                                                    onChange={(e) => {
                                                        setPan(e.target.value)
                                                    }}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Valid Pan Card Number .
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                                                <Form.Label>GST Image </Form.Label>

                                                <InputGroup hasValidation>
                                                    <Form.Control
                                                        type="file"
                                                        aria-describedby="inputGroupPrepend"
                                                        required
                                                        style={{ padding: '0.8rem' }}
                                                        disabled={isGstDisabled}
                                                        onChange={(e) => {
                                                            setGstImage(e.target.files[0])
                                                        }}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter Valid GST Image .
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                        </Row>
                                        <br />
                                        <div className='d-flex'>
                                            <Button disabled={isKyc ? true : false} className='mr-2' type='submit' onClick={() => setIsUpdateGst(true)} variant="contained" style={{ backgroundColor: !isKyc ? '#12124e' : '', padding: '0.6rem', width: '9rem' }}>
                                                {
                                                    (isKyc === 1 || isKyc === 2) ? (
                                                        "Verified"
                                                    ) : (
                                                        "Submit"
                                                    )
                                                }
                                            </Button>
                                        </div>
                                    </Form>
                                </div>
                            )
                        }
                    </div>
                )
            }
            <div>
                {
                    ((isKyc === 2 && !isUpdateGst)) && (
                        <center>
                            <br />
                            <br />
                            <br />
                            <br />
                            <img width='120px' src={busin} />
                            <p style={{ fontSize: '1.3rem', color: '#12124e' }}>No GST Number</p>
                            <div style={{ width: '19rem' }} >
                                <p>You can add your business details to emprove your profile. </p>
                            </div>
                            <button onClick={() => {
                                setGstAvle('Yes')
                                setIsUpdateGst(true)
                            }} style={{ height: '2.6rem', border: '1px solid #12124e', width: '8rem', borderRadius: '0.6rem', color: '#12124e' }} variant='outlined'>Business Details</button>
                        </center>
                    )
                }
            </div>
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='md'
                >
                    <DialogContent style={{ padding: 20 }}>
                        <div className='card' style={{ height: '4.8rem', width: '100%', flexDirection: 'row', alignItems: 'center', borderRadius: '0.1rem' }} >
                            <FaWindowClose onClick={() => handleClose()} style={{ fontSize: 42, color: 'lightgray', marginLeft: 10, cursor: 'pointer' }} />
                            <h4 style={{ marginTop: 9.6, marginLeft: 7, fontWeight: 'bold' }}>Update Business Details</h4>
                        </div>
                        <h4 style={{ marginTop: 20 }}>Business Details</h4>
                        <p style={{ fontSize: '0.8rem' }}>A business description is a high-level overview of your company that you include in your business plan. Your business description should entice readers—like lenders and investors—to look through the rest of your business plan to learn more about your company.</p>
                        {
                            (error !== false) && (
                                <center>
                                    <div className='errorgst mb-2 mt-2' >
                                        <FaTimes onClick={() => setError(false)} style={{ position: 'absolute', top: '0.4rem', right: '0.4rem', cursor: 'pointer' }} />
                                        <p className='text-start'><FaExclamationTriangle className='text-danger' />  <span style={{ position: 'relative', top: '0.13rem', left: '0.2rem' }}>
                                            Gst number  <span style={{ fontSize: '1.3rem' }} >{newGst2} <span className='text-danger' >{newGst}</span> {newGst1}</span> must matched with pan card number
                                            <span className='text-danger' style={{ fontSize: '1.3rem' }}>  {newPan}  </span> Please enter valid pan number
                                        </span>
                                        </p>
                                    </div>
                                </center>
                            )
                        }
                        <Form noValidate validated={GstValidated1} onSubmit={handleSubmitGst1} >
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Gst Number</Form.Label>
                                    <Form.Control
                                        required
                                        value={GST || ''}
                                        type="text"
                                        placeholder="Gst Number"
                                        style={{ padding: '0.8rem' }}
                                        onChange={(e) => {
                                            setGst(e.target.value)
                                        }}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Valid GST Number
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom02">
                                    <Form.Label>Firm Name</Form.Label>
                                    <Form.Control
                                        required
                                        value={firm || ''}
                                        type="text"
                                        placeholder="Firm Name"
                                        style={{ padding: '0.8rem' }}
                                        onChange={(e) => {
                                            setFirm(e.target.value)
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Valid Firm Name
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="7" controlId="validationCustomUsername">
                                    <Form.Label>Nature Of Business</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control
                                            type="text"
                                            value={business || ''}
                                            placeholder="Nature Of Business"
                                            aria-describedby="inputGroupPrepend"
                                            required
                                            style={{ padding: '0.8rem' }}
                                            onChange={(e) => {
                                                setBusiness(e.target.value)
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Nature Of Business.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md="7" controlId="validationCustom02">
                                    <Form.Label>Pan Card Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={pan || ''}
                                        placeholder="Pan Card Number"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        style={{ padding: '0.8rem' }}
                                        onChange={(e) => {
                                            setPan(e.target.value)
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Valid Pan Card Number .
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="7" controlId="validationCustomUsername">
                                    <Form.Label>GST Image </Form.Label>

                                    <InputGroup hasValidation>
                                        <Form.Control
                                            type="file"
                                            aria-describedby="inputGroupPrepend"
                                            required
                                            style={{ padding: '0.8rem' }}
                                            onChange={(e) => {
                                                setGstImage(e.target.files[0])
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid GST Image .
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Row>
                            <br />
                            <div className='d-flex'>

                                {
                                    !wait7 && (
                                        <Button className='mr-2' type='submit' onClick={() => setIsUpdateGst(true)} variant="contained" style={{ backgroundColor: '#12124e', padding: '0.6rem', width: '9rem' }}>
                                            Update
                                        </Button>
                                    )
                                }
                                {
                                    wait7 && (
                                        <Button className='mr-2' type='submit' variant="contained" disabled={true} style={{ padding: '0.6rem', width: '10rem' }}>
                                            Updating..
                                        </Button>
                                    )
                                }
                            </div>
                        </Form>
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    )
}

export default CustomerGst