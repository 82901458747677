import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FaSearch, FaUserCircle, FaWindowClose } from 'react-icons/fa';
import axios from 'axios';
import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import LorryBrokerVerify from '../LorryBrokerDataVerify/LorryBrokerVerify';

export default function MainLorryWala({ data }) {



    const [id, setId] = React.useState()

    const getId = (id) => {
        setId(id)

        setIsVerify(true)
    }

    const [lorryWalaUsers, setLorryWalaUsers] = useState([])
    const [text, setText] = useState('')

    const searchLorryWala = (text) => {
        setText(text)
        const userType = 'LorryBroker'
        axios.post('https://pmapi.emedha.in/admin/users-search', {
            text,
            userType
        }).then((res) => {
            setLorryWalaUsers(res.data.users)
        })
    }
 

    const [isVerify, setIsVerify] = React.useState(false)

    return (
        <Paper elevation={0}>
             {
                !isVerify && (
                    <div className='card' style={{ paddingLeft: 10, width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', boxShadow: 'none', border: 'none', paddingRight: 20 }} >
                        <h5 >Cargo Owners</h5>
                        {
                            text === '' && (
                                <FaSearch style={{ position: 'absolute', right: 28, zIndex: 1000, top: 18, color: 'gray', fontSize: 19 }} />
                            )
                        }
                        {
                            text !== '' && (
                                <FaWindowClose onClick={() => {
                                    setLorryWalaUsers([])
                                    setText('')
                                }} style={{ position: 'absolute', right: 28, zIndex: 1000, top: 18, color: 'gray', fontSize: 19, cursor: 'pointer' }} />
                            )
                        }
                        <h6 ><input value={text} onChange={(e) => searchLorryWala(e.target.value)} className='shadow' style={{ backgroundColor: 'white', borderRadius: '0.1rem', height: '3.1rem', border: 'none', outline: 'none', width: '29rem', paddingLeft: '0.7rem' }} placeholder='Search...' ></input></h6>
                    </div>
                )
            }
            <TableContainer sx={{ height: isVerify? 80 + "vh" : 72 + "vh" }} >
                {
                    !isVerify && (
                        <div>

                            <Table style={{ position: 'relative', top: '-0.7rem' }}>
                                <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                                <TableRow>
                                    <TableCell
                                        align={"center"}
                                        style={{ minWidth: 30, fontSize: 12, fontWeight: 'bold' }}
                                    >
                                        User
                                    </TableCell>
                                    <TableCell
                                        align={"start"}
                                        style={{ top: 57, minWidth: 60, fontWeight: 'bold' }}
                                    >
                                        Name
                                    </TableCell>
                                    <TableCell
                                        align={"start"}
                                        style={{ top: 57, minWidth: 60, fontWeight: 'bold' }}
                                    >
                                        Mobile
                                    </TableCell>
                                    <TableCell
                                        align={"start"}
                                        style={{ top: 57, minWidth: 60, fontWeight: 'bold' }}
                                    >
                                        Industry
                                    </TableCell>
                                    <TableCell
                                        align={"start"}
                                        style={{ top: 57, minWidth: 60, fontWeight: 'bold' }}
                                    >
                                        Email
                                    </TableCell>
                                    <TableCell
                                        align={"start"}
                                        style={{ top: 57, minWidth: 60, fontWeight: 'bold' }}
                                    >
                                        Location
                                    </TableCell>
                                    <TableCell

                                        align={"start"}
                                        style={{ top: 57, minWidth: 120, fontWeight: 'bold' }}
                                    >
                                        Date
                                    </TableCell>
                                    <TableCell

                                        align={"start"}
                                        style={{ top: 57, minWidth: 100, fontWeight: 'bold' }}
                                    >
                                        Status
                                    </TableCell>

                                    <TableCell

                                        align={"start"}
                                        style={{ top: 57, minWidth: 100, fontWeight: 'bold' }}
                                    >
                                        Action
                                    </TableCell>
                                </TableRow>
                                </TableHead>
                                {
                                    text === '' && (
                                        <TableBody>
                                            {data
                                                .map((row) => {

                                                    let color = ''
                                                    let status = ''

                                                    if (row.isVerify === '1' && row.isDriver === 2 && row.isVehicle === 2) {
                                                        status = 'active'
                                                        color = 'rgb(107, 213, 107)'
                                                    } else {
                                                        status = 'progress'
                                                        color = 'rgb(233, 198, 108)'
                                                    }

                                                    let AadharisVerify = ''
                                                    if (data.isAadhar === 1) {
                                                        AadharisVerify = 'Verified'
                                                    } else {
                                                        AadharisVerify = 'No Verify'
                                                    }
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                            <TableCell align={"center"}
                                                                style={{ top: 57, minWidth: 30, fontSize: 42, color: color, }}><FaUserCircle /></TableCell>
                                                            <TableCell align={"start"}
                                                                style={{ top: 57, minWidth: 60, fontSize: 12 }}>{row.first_name} {row.last_name}</TableCell>
                                                            <TableCell align={"start"}
                                                                style={{ top: 57, minWidth: 60, fontSize: 12 }}>{row.mobile}</TableCell>
                                                                <TableCell align={"start"}
                                                                style={{ top: 57, minWidth: 60, fontSize: 12 }}>{row.firm}</TableCell>
                                                            <TableCell align={"start"}
                                                                style={{ top: 57, minWidth: 60, fontSize: 12 }}>{row.email}</TableCell>
                                                                 <TableCell align={"start"}
                                                                style={{ top: 57, minWidth: 60, fontSize: 12 }}>{row.location}</TableCell>
                                                            <TableCell align={"start"}
                                                                style={{ top: 57, minWidth: 120, fontSize: 12 }}>{row.date}</TableCell>
                                                            <TableCell align={"start"}
                                                                style={{ top: 57, minWidth: 100, flexDirection: 'row', alignItems: 'left' }}>
                                                                <span style={{ cursor: 'pointer', }}>
                                                                    <button style={{ backgroundColor: color, fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} >{status}</button>
                                                                </span>
                                                            </TableCell>
                                                            <TableCell align={"start"}
                                                                style={{ top: 57, minWidth: 100, flexDirection: 'row', alignItems: 'left' }}>
                                                                <span style={{ cursor: 'pointer', }}>
                                                                    <button onClick={() => {
                                                                        getId(row.client_id)
                                                                    }} style={{ backgroundColor: 'rgb(107, 213, 107)', fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} >Verify</button>
                                                                </span>
                                                                <span style={{ cursor: 'pointer', marginLeft: '0.1rem', marginRight: '0.1rem' }}>
                                                                    <button style={{ backgroundColor: 'rgb(239, 122, 122)', fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} >Delete</button>
                                                                </span>
                                                                <span style={{ cursor: 'pointer', }}>
                                                                    <button style={{ backgroundColor: 'rgb(233, 198, 108)', fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} >Edit</button>
                                                                </span>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    )
                                }
                                {
                                    text !== '' && (
                                        <TableBody>
                                            {lorryWalaUsers
                                                .map((row) => {

                                                    let color = ''
                                                    let status = ''

                                                    if (row.isVerify === '1' && row.isDriver === 2 && row.isVehicle === 2) {
                                                        status = 'active'
                                                        color = 'rgb(107, 213, 107)'
                                                    } else {
                                                        status = 'progress'
                                                        color = 'rgb(233, 198, 108)'
                                                    }

                                                    // let AadharisVerify = ''
                                                    // if (data.isAadhar === 1) {
                                                    //     AadharisVerify = 'Verified'
                                                    // } else {
                                                    //     AadharisVerify = 'No Verify'
                                                    // }
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                            <TableCell align={"left"}
                                                                style={{ top: 57, minWidth: 30, fontSize: 42, color: color, }}><FaUserCircle /></TableCell>
                                                            <TableCell align={"center"}
                                                                style={{ top: 57, minWidth: 60, fontSize: 12 }}>{row.first_name} {row.last_name}</TableCell>
                                                            <TableCell align={"center"}
                                                                style={{ top: 57, minWidth: 60, fontSize: 12 }}>{row.mobile}</TableCell>
                                                            <TableCell align={"center"}
                                                                style={{ top: 57, minWidth: 60, fontSize: 12 }}>{row.email}</TableCell>
                                                            <TableCell align={"center"}
                                                                style={{ top: 57, minWidth: 120, fontSize: 12 }}>{row.date}</TableCell>
                                                            <TableCell align={"left"}
                                                                style={{ top: 57, minWidth: 100, flexDirection: 'row', alignItems: 'left' }}>
                                                                <span style={{ cursor: 'pointer', }}>
                                                                    <button style={{ backgroundColor: color, fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} >{status}</button>
                                                                </span>
                                                            </TableCell>
                                                            <TableCell align={"center"}
                                                                style={{ top: 57, minWidth: 100, flexDirection: 'row', alignItems: 'left' }}>
                                                                <span style={{ cursor: 'pointer', }}>
                                                                    <button onClick={() => {
                                                                        getId(row.client_id)
                                                                    }} style={{ backgroundColor: 'rgb(107, 213, 107)', fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} >Verify</button>
                                                                </span>
                                                                <span style={{ cursor: 'pointer', marginLeft: '0.1rem', marginRight: '0.1rem' }}>
                                                                    <button style={{ backgroundColor: 'rgb(239, 122, 122)', fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} >Delete</button>
                                                                </span>
                                                                <span style={{ cursor: 'pointer', }}>
                                                                    <button style={{ backgroundColor: 'rgb(233, 198, 108)', fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} >Edit</button>
                                                                </span>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    )
                                }
                            </Table>
                        </div>
                    )
                }
                {
                    isVerify && (

                        <LorryBrokerVerify id={id} />
                    )
                }
                {
                    data.length === 0 && (
                        <Box className='card' style={{ height: '64vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center' }} >
                            <img src='https://www.bing.com/images/blob?bcid=S90DZlUfIZkFqxcxoNWLuD9SqbotqVTdP7w' width='145px' />
                            <Typography>0 users</Typography>
                        </Box>
                    )
                }
            </TableContainer>
        </Paper>
    );
}