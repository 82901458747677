import  Typography  from '@mui/material/Typography'
import { Box } from '@mui/system'
import React from 'react'

const Footer = () => {
  return (
    <div sx={{height:0,width:'100%',alignItems:'center',justifyContent:'center'}} >
        <Typography sx={{textAlign:'center',fontSize:17,marginTop:5}}>© 2023 Prime Moovers India Private Limited. All rights reserved.</Typography>
    </div>
  )
}

export default Footer