import React, { useRef, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FaBars, FaDotCircle, FaEdit, FaFile, FaPencilAlt, FaSignOutAlt, FaTruck, FaUser, FaWindowClose } from 'react-icons/fa';
import ListSubheader from '@mui/material/ListSubheader';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Profile from '../../../Profile/Profile'
import { Collapse, Menu } from '@mui/material';
import { useEffect } from 'react'
import TuneTwoToneIcon from '@mui/icons-material/TuneTwoTone';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import UpdateKycBroker from '../../../updateKycBroker/UpdateKycBroker';
import { BsCalendar, BsDice3, BsWallet } from 'react-icons/bs';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import { FaMapMarker } from 'react-icons/fa';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import BrokersCard from '../BrokerCard';
import Footer from '../Footer/Footer';
import DashShowProfile from '../../../DashBoardShowProfile/DashboardShowProfile';
import logo from '../../../GlobleImageContainer/images/PrimeMoovers.png'
import LorryWalaUpdateKyc from '../../../LorryWalaUpdateKyc/LorryWalaupdateKyc';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Timeline from '../TimeLine/TimeLine';
import { useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import SimpleBackdrop from '../../../BackDrop/BackDrop';
import Swal from 'sweetalert2';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import moment from 'moment';
import Transit from '../Transit/Transit';
import MyTimer from './Timer';
import { BorderStyle } from '@material-ui/icons';


const drawerWidth = 260;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'Auto',
    color: theme.palette.text.secondary,
}));

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        marginTop: '3rem',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function Sidebar() {


    const [open18, setOpen18] = React.useState(false);

    const handleClickOpen18 = () => {
        setOpen18(true);
    };

    const handleClose18 = () => {
        setOpen18(false);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const open5 = Boolean(anchorEl);
    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
    };

    const handleClose5 = () => {
        setAnchorEl(null);
    };

    const [isConfirming, setIsConfirming] = useState(false)

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyDIhf9a0TCregc8lSd8KdK4RDfRN8wyhbk",
        libraries: ['places']
    })

    const navigate = useNavigate()

    const [loadingImage, setLoadingImage] = useState('')


    const confirmBid = (send_bids_id, email, toRoute, fRoute) => {
        if (vehicle === '' || driver === '') {
            alert('fields Can not be empty')
        } else {
            confirmBid1(send_bids_id, email, toRoute, fRoute)
            setIsConfirming(true)
        }
    }


    const confirmBid1 = (send_bids_id, email, toRoute, fRoute) => {
        axios.post('https://pmapi.emedha.in/transporter-bids/confirm', {
            send_bids_id,
            vehicle,
            driver,
            email,
            toRoute,
            fRoute
        }).then((res) => {
            if (res.data.status === 200) {
                if (res.data.status === 200) {
                    setIsConfirming(false)
                    setOpen10(false)
                    allSuccBids()
                    Swal.fire({
                        title: "Successful..!",
                        text: "Your bid successfully confimed to the load...!",
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Continue",
                    }).then((result) => {
                        if (result.value) {
                            setIsConfirming(false)
                            setOpen10(false)
                            allSuccBids()
                        }
                    });
                }
            }
        })
    }



    const [vehicleData, setVehicleData] = useState([])

    const acceptBidValidation1 = () => {
        if (sessionStorage.getItem('userType') === 'Customer') {
            return
        }
        const client_id = sessionStorage.getItem('client_id')
        const type = sessionStorage.getItem('type')
        axios.post('https://pmapi.emedha.in/transporter-bids/vehicle-check-validation', {
            client_id,
            type
        }).then((res) => {
            setVehicleData(res.data.data)
        })
    }


    const [AllDriver, setAllDriver] = useState([])

    const driverData = () => {
        const client_id = sessionStorage.getItem('client_id')
        const type = sessionStorage.getItem('type')
        axios.post('https://pmapi.emedha.in/driver/data/show-all', {
            client_id,
            type
        }).then((res) => {
            setAllDriver(res.data.result)
        })
    }

    useEffect(() => {
        driverData()
        acceptBidValidation()
    }, [])



    const handleChange = (event) => {
        setDriver(event.target.value);
    };


    const handleChange1 = (event) => {
        setVehicle(event.target.value);
    };


    const email = sessionStorage.getItem('email')

    const [sbids, setSBids] = useState([])
    const [sbidsLen, setsBidsLen] = useState()

    const allSuccBids = () => {
        const client_id = sessionStorage.getItem('client_id')
        setsBidsLen(0)
        axios.post('https://pmapi.emedha.in/transporter/bids-status/successful', {
            client_id
        }).then((res) => {
            setSBids(res.data.result)
            setsBidsLen(res.data.result.length)
        })
    }

    useEffect(() => {
        allSuccBids()
    }, [])

    const [open9, setOpen9] = React.useState(true);

    const handleClick = () => {
        setOpen9(!open9);
    };

    const [open3, setOpen3] = React.useState(false);

    const [isAlert, setAlert] = React.useState()
    const [aadharStatus, setAadharStatus] = useState()

    const [aIsAdd, setAisAdd] = useState(false)
    const [isKeySuccess, setIsKycSuccess] = useState(false)

    const [isAv, setIsAv] = useState(true)
    const [isAg, setIsAg] = useState(true)

    const isKyc = () => {
        const email = sessionStorage.getItem('email')
        const type = sessionStorage.getItem('type')
        axios.post('https://pmapi.emedha.in/users/kyc/status', {
            email,
            type
        }).then((res) => {
            setAadharStatus(res.data.isAadhar)
            if ((res.data.isAadhar === 1) && (res.data.isAddGst === 1 || res.data.isAddGst === 2)) {
                setIsKycSuccess(true)
            } else {
                if (res.data.isAadhar === 0) {
                    setTimeout(() => setAlert(true))
                    setIsKycSuccess(false)
                    setIsAv(false)
                }
                if (res.data.isAddGst === 0) {
                    setTimeout(() => setAlert(true))
                    setIsKycSuccess(false)
                    setIsAg(false)
                }
            }
            if (res.data.isAadhar === 1) {
                setAisAdd(true)
            }
        })
    }

    useEffect(() => {
        setOpen3(true)
        setOpen3(true)
        acceptBidValidation1()
    }, [])

    const [isUpdateKyc, setIsUpdateKyc] = useState(false)
    const [isCreateBids, setisCreateBids] = useState(false)
    const [isProfile, setIsProfile] = useState(false)
    const [name, setName] = useState()
    const [lastName, setLastName] = useState()
    const [profileItem, setIsprfileItem] = useState(false)
    const [money, setMoney] = useState()



    const [email1, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [firstName, setFirstName] = useState('')
    const [userType, setUserType] = useState('')
    const [aadhar, setAadhar] = useState('')
    const [gst, setGst] = useState('')
    const [firm, setFirm] = useState('')
    const [business, setBusiness] = useState()
    const [newGst, setNewGst] = useState('')
    const [newEmail, setNewEmail] = useState()
    const [newMobile, setNewMbile] = useState()
    const [type, setType] = useState(sessionStorage.getItem('type'))
    const [location, setLocation] = useState('')

    const userName = () => {
        const email = sessionStorage.getItem('email')
        axios.post('https://pmapi.emedha.in/profile', {
            email
        }).then(async (res) => {
            setFirstName(res.data.first_name)
            setLastName(res.data.last_name)
            await setFirstName(res.data.first_name)
            setLastName(res.data.last_name)
            const CCEncoded2 = "XXXX " + res.data.email.substring(res.data.email.length + 4, 6);
            setEmail(res.data.email)
            setNewEmail(CCEncoded2)
            const CCEncoded3 = "XX XXXX " + res.data.mobile.substring(res.data.mobile.length + 4, 6);
            setNewMbile(CCEncoded3)
            setMobile(res.data.mobile)
            setUserType(res.data.type)
            setBusiness(res.data.business)
            setFirm(res.data.firm)
            setGst(res.data.gst)
            setLocation(res.data.location)
            try {
                const CCEncoded = "XXX XXXX XXXX " + res.data.gst.substring(res.data.gst.length + 4, 10);
                setNewGst(CCEncoded)
            }
            catch {
                setNewGst('')
            }
            setGst()
            setAadhar(res.data.aadhar)
            setType(res.data.type)
        })
    }


    useEffect(() => {
        userName()
        isKyc()
    }, [])

    function refreshPage() {
        window.location.reload(false);
    }

    const [isDashboard, setIsDashboard] = useState(true)

    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const [open10, setOpen10] = React.useState(false);

    const [isBidoffer, setIsBidoffer] = useState(false)

    useState(() => {
        setOpen3(true)
    }, [])


    const [isCurrentBids, setisCurrentBids] = useState(false)
    const [isSuccessfulBids, setIsSuccessFulBids] = useState(false)
    const [isCloseBids, setIsCloseBids] = useState(false)

    const [isBidsforMonth, setIsBidsForMonth] = useState(false)

    useEffect(() => {
        userName()
        isKyc()
    }, [])

    function refreshPage() {
        window.location.reload(false);
    }


    const [driver, setDriver] = React.useState('');



    const [vehicle, setVehicle] = React.useState('');



    useEffect(() => {
        driverData()
        acceptBidValidation()
    }, [])

    const [bids, setBids] = useState([])
    const [expLen, setExpLen] = useState()

    const allBids = () => {
        setExpLen(0)
        axios.post('https://pmapi.emedha.in/transporter/bids-status/expired', {
            email
        }).then((res) => {
            setBids(res.data)
            setExpLen(res.data.length)
        })
    }

    const [bidCurrent, setCurrentBids] = useState([])
    const [currentBidLen, setCurrentBidLen] = useState()

    const [isBidsLoad, setIsBidsLoad] = useState(false)

    const allBidss = () => {
        setIsBidsLoad(true)
        setCurrentBidLen(0)
        const email = sessionStorage.getItem('email')
        axios.post('https://pmapi.emedha.in/transporter/bids-status/current', {
            email,
        }).then((res) => {
            setCurrentBids(res.data)
            setCurrentBidLen(res.data.length)
            setTimeout(() => setIsBidsLoad(false), 1000)
        })
    }

    const originRef = useRef()
    const destiationRef = useRef()

    const [from, setFrom] = useState('')
    const [to, setTo] = useState('')

    const [seacrhWait, setSearchWait] = useState(false)

    const searchBids = () => {
        setSearchWait(true)
        axios.post('https://pmapi.emedha.in/transporter-bids/search-by-route', {
            from,
            to
        }).then((res) => {
            handleClose5()
            setTimeout(() => {
                setCurrentBids(res.data.bids)
                setSearchWait(false)
            }, 1000)
        })
    }

    const [monthBid, setMonthBid] = useState([])
    const [monthDate, setMonthDate] = useState('')

    const bidForMonth = (date) => {

        const newDate = `${moment(date).date()}/${moment(date).month() + 1}/${moment(date).year()}`

        setSearchWait(true)
        axios.post('https://pmapi.emedha.in/transporter-bids/bid-for-month', {
            newDate
        }).then((res) => {
            handleClose5()
            setTimeout(() => {
                setMonthBid(res.data.bids)
                setSearchWait(false)
            }, 1000)
        })
    }

    useEffect(() => {
        allBids()
        allBidss()
    }, [])



    const [open11, setOpen11] = React.useState(false);
    const [open12, setOpen12] = React.useState(false);
    const [customerEmail, setCustomerEamil] = useState()
    const [currentMinuts, setCurrentMinuts] = useState()

    const [sendBidsId, setSendBidsId] = useState()

    const [moneyValidation1, setMoneyValidation1] = useState(false)
    const [vehicleLeangth, setVehicleLeangth] = useState()
    const [accNum1, setAccNum] = useState('')
    const [isAccepting, setIsAccepting] = useState(false)


    const moneyValidation = () => {
        if (((money % 500) !== 0)) {
            setMoneyValidation1(true)
        } else {
            if (accNum1 < vehicleLeangth) {
                acceptBidAler()
                setIsAccepting(true)
            } else {
                if (vehicleLeangth > 0) {
                    updateVehicleBidAler()
                    setIsAccepting(true)
                 } 
               else {
                    updateBidCheckAndProceed()
                }
            }
        }
    }

    const updateBidCheckAndProceed = () => {
        setOpen11(false)
        setIsAccepting(false)
        allBidss()
        Swal.fire({
            title: "Warning..!",
            text: "You have done as many bids as the number of vehicles or please add more vehicles in your profile before further biding",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
        }).then((result) => {
            if (result.value) {
                return
            } else {
                return
            }
        });
    }

    const acceptBidAler = () => {
        setOpen11(false)
        Swal.fire({
            title: "Confirm..!",
            text: `Are you sending your bid amount: ${money} for the ${quote} vehicle`,
            cancelButtonText: 'No',
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.value) {
                acceptBid()
            } else {
                setOpen11(true)
                setIsAccepting(false)
            }
        });
    }

    const updateVehicleBidAler = () => {
        setOpen11(false)
        Swal.fire({
            title: "Confirm..!",
            text: `Are you sending your bid amount: ${money} for the ${quote} vehicle`,
            cancelButtonText: 'No',
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.value) {
                updateBidAlr()
            } else {
                setOpen11(true)
                setIsAccepting(false)
            }
        });
    }

    const [acceptedLoading, setIsAcceptedLoading] = useState(false)


    const acceptBid = () => {
        const accNum = accNum1 + 1
        const client_id = sessionStorage.getItem('client_id')
        const email = sessionStorage.getItem('email')
        const oneVehicleBidAmount = money / quote
        setIsAcceptedLoading(true)
        axios.post('https://pmapi.emedha.in/transporter-bids/bid-accept', {
            customerEmail,
            money,
            sendBidsId,
            client_id,
            accNum,
            email,
            quote,
            oneVehicleBidAmount,
            currentMinuts
        }).then((res) => {
            if (res.data.status === 200) {
                setOpen11(false)
                setIsAccepting(false)
                allBidss()
                setIsAcceptedLoading(false)
                bidTraficEmail()
                Swal.fire({
                    title: "Successful..!",
                    text: "Your bid is successfully accepted...!",
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Dashboard",
                }).then((result) => {
                    if (result.value) {
                        setOpen11(false)
                        setIsAccepting(false)
                        allBidss()
                        acceptBidNum()

                    } else {
                        acceptBidNum()

                    }
                });
            } else {
                if (res.data.status === 409) {
                    setOpen11(false)
                    setIsAccepting(false)
                    allBidss()
                    Swal.fire({
                        title: "Warning..!",
                        showCancelButton: true,
                        text: `Bid has been already accepted by ${firstName} ${lastName} for the ${res.data.vehicleReqNum} vehicle, old bid amount is: ${res.data.money}`,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Update",

                    }).then((result) => {
                        if (result.value) {
                            if ((res.data.money === parseInt(money)) || (res.data.money < parseInt(money)) && (parseInt(quote) < res.data.vehicleReqNum)) {
                                Swal.fire({
                                    title: "Warning..!",
                                    showCancelButton: true,
                                    cancelButtonText: 'Continue',
                                    text: `Bid amount ${money} must be less than old bid amount: ${res.data.money}`,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Dashboard",
                                }).then((result) => {
                                    if (result.value) {
                                        window.location.reload()

                                    } else {
                                        return
                                    }
                                });
                            } else {
                                updateBid()
                            }

                        } else {
                            setOpen11(false)
                            setIsAccepting(false)
                            allBidss()
                        }
                    });
                }
            }
        })
    }




    const updateBidAlr = () => {
        const accNum = accNum1
        const client_id = sessionStorage.getItem('client_id')
        const email = sessionStorage.getItem('email')
        const condition = 'update'

        axios.post('https://pmapi.emedha.in/transporter-bids/bid-accept', {
            customerEmail,
            money,
            sendBidsId,
            client_id,
            accNum,
            email,
            condition,
            quote,
            currentMinuts
        }).then((res) => {
            if (res.data.status === 201) {
                setOpen11(false)
                setIsAccepting(false)
                allBidss()
                Swal.fire({
                    title: "Warning..!",
                    text: "You have done as many bids as the number of vehicles or please add more vehicles in your profile before further biding ",
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ok",
                }).then((result) => {
                    if (result.value) {
                        setOpen11(false)
                        setIsAccepting(false)
                        allBidss()
                        acceptBidNum()
                    } else {
                        acceptBidNum()
                    }
                });
            } else {
                if (res.data.status === 409) {
                    setOpen11(false)
                    setIsAccepting(false)
                    allBidss()
                    Swal.fire({
                        title: "Warning..!",
                        showCancelButton: true,
                        text: `Bid has been already accepted by ${firstName} ${lastName} for the ${res.data.vehicleReqNum} vehicle, old bid amount is: ${res.data.money}`,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Update",
                    }).then((result) => {
                        if (result.value) {
                            if ((res.data.money === parseInt(money)) || (res.data.money < parseInt(money)) && ((parseInt(quote) === res.data.vehicleReqNum) || (parseInt(quote) < res.data.vehicleReqNum))) {
                                Swal.fire({
                                    title: "Warning..!",
                                    showCancelButton: true,
                                    cancelButtonText: 'Continue',
                                    text: `Bid amount ${money} must be less than old bid amount: ${res.data.money}`,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Dashboard",
                                }).then((result) => {
                                    if (result.value) {
                                        window.location.reload()
                                    } else {
                                        return
                                    }
                                });
                            } else {
                                updateBid()
                            }

                        } else {
                            setOpen11(false)
                            setIsAccepting(false)
                            allBidss()
                        }
                    });
                }
            }
        })
    }


    const bidTraficEmail = () => {
        axios.post('https://pmapi.emedha.in/transporter-bids/bidvalue-email-alert', {
            money,
            sendBidsId
        })
    }

    const updateBid = () => {
        const accNum = accNum1 + 1
        const client_id = sessionStorage.getItem('client_id')
        const oneVehicleBidAmount = money / quote
        setIsAcceptedLoading(true)
        axios.post('https://pmapi.emedha.in/transporter-bids/bid-update', {
            customerEmail,
            money,
            sendBidsId,
            client_id,
            accNum,
            quote,
            oneVehicleBidAmount,
            currentMinuts
        }).then((res) => {
            if (res.data.status === 200) {
                setIsAcceptedLoading(false)
                setOpen11(false)
                setIsAccepting(false)
                allBidss()
                acceptBidNum()
                bidTraficEmail()
                Swal.fire({
                    title: "Successful..!",
                    confirmButtonAriaLabel: true,
                    text: "Your bid amount is successfully updated...!",
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ok",
                }).then((result) => {
                    if (result.value) {
                        setOpen11(false)
                        setIsAccepting(false)
                        allBidss()
                        acceptBidNum()
                    }
                });
            }
        })
    }

    const [accBidLen, setAccBidLen] = useState()
    const [accBids, setAccBids] = useState([])

    const accBidsRes = () => {
        setAccBidLen(0)
        const email = sessionStorage.getItem('email')
        axios.post('https://pmapi.emedha.in/transporter/bids-status/accepted', {
            email
        }).then((res) => {
            setAccBids(res.data.result)
            setAccBidLen(res.data.result.length)
        })
    }


    const [aStore, setAstore] = useState([])

    const getAadhar = () => {

        const client_id = sessionStorage.getItem('client_id')
        const dataReq = 'aadhar_number'
        if (sessionStorage.getItem('type') === 'LorryBroker') {
            axios.post('https://pmapi.emedha.in/users/lorry_broker', {
                client_id,
                dataReq
            }).then((res) => {
                setAstore(res.data.data)
            })
        } else {
            const dataReq = 'aadhar_number'
            if (sessionStorage.getItem('type') === 'LorryWala') {
                axios.post('https://pmapi.emedha.in/users/lorry_wala', {
                    client_id,
                    dataReq
                }).then((res) => {
                    setAstore(res.data.data)
                })
            }
        }
    }


    useEffect(() => {
        accBidsRes()
        getAadhar()
    }, [])



    // const [vehicleData, setVehicleData] = useState([])

    const acceptBidNum = () => {
        const client_id = sessionStorage.getItem('client_id')
        const type = sessionStorage.getItem('type')
        axios.post('https://pmapi.emedha.in/transporter-bids/all-acc-b-in-number', {
            type,
            client_id,
        }).then((res) => {
            setAccNum(res.data.accNum)
        })
    }

    const acceptBidValidation = () => {
        if (sessionStorage.getItem('userType') === 'Customer') {
            return
        }
        const client_id = sessionStorage.getItem('client_id')
        const type = sessionStorage.getItem('type')

        // https://pmapi.emedha.in/transporter-bids/vehicle-check-validation
        axios.post('https://pmapi.emedha.in/transporter-bids/vehicle-check-validation', {
            client_id,
            type
            // vehicle:vehicle
        }).then((res) => {
            setVehicleLeangth(res.data.data.length)
            console.log(res.data.data.length)
        })
    }

    useEffect(() => {
        acceptBidValidation()
        acceptBidNum()
        acceptBidValidation()
    }, [])

    const [kycAlertModel, setKycAlertModel] = useState(false)
    const handleClose = () => setKycAlertModel(false);
    const [vehicleReqNum, setVehicleReqNum] = useState(0)

    const sendBid = (cEmail, send_bids_id, num, minute) => {
        if (isKeySuccess) {
            setOpen11(true)
            setCustomerEamil(cEmail)
            setSendBidsId(send_bids_id)
            setVehicleReqNum(num)
            setCurrentMinuts(minute)
        } else {
            setKycAlertModel(true)
        }
    }


    useEffect(() => {
        isKyc()
    }, [])

    const [searchModal, setSearchModal] = useState(false)

    const [quote, setQuote] = useState(1)

    const [open6, setOpen6] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen6((prevOpen) => !prevOpen);
    };

    const handleClose6 = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen6(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);


    const [isTransit, setIsTransit] = useState(false)
    const [transit, setTransit] = useState([])

    const transitGet = () => {
        axios.post('https://pmapi.emedha.in/transporter-bids/transit').then((res) => {
            setTransit(res.data.bids)
        })
    }

    useEffect(() => {
        transitGet()
    }, [])

    const policyValidation = () => {
        sessionStorage.setItem('isPolicy1', 'fdhguiurnxmvjfghhdfgh')
        setIsPolicy(true)

        Swal.fire({
            text: "Bid having truck numbers have high chances of winning the bid .!",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
        }).then((result) => {
            return
        });
    }

    const [isPolicy, setIsPolicy] = useState(false)

    useEffect(() => {
        if (sessionStorage.getItem('isPolicy1')) {
            setIsPolicy(true)
        } else {
            setIsPolicy(false)
        }
    }, [])


    return isLoaded ? (
        <div className='pt-4' >
            <div>
                <Dialog
                    open={kycAlertModel}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='md'
                >
                    <DialogContent style={{ padding: 20 }}>
                        <div className='card' style={{ boxShadow: 'none', height: '0rem', width: '40rem', flexDirection: 'row', alignItems: 'center', borderRadius: '0.1rem' }} >

                        </div>
                        <h4>Your Kyc is Pending</h4>
                        <h6>Please complete your pending kyc verification and send your bid</h6>
                        <Timeline isAv={isAv} isAg={isAg} />
                        <button style={{ height: '2.1rem', width: '6rem', border: 'none', borderRadius: '0.2rem', backgroundColor: 'red', color: 'white', fontWeight: '500' }} variant="secondary" onClick={handleClose}>
                            Cancel
                        </button>
                        <button style={{ marginLeft: 5, height: '2.1rem', width: '11rem', border: 'none', borderRadius: '0.2rem', backgroundColor: '#12124e', color: 'white', fontWeight: '500' }} variant="primary" onClick={() => {
                            setIsDashboard(false)
                            setisCreateBids(false)
                            setIsProfile(false)
                            setIsBidoffer(false)
                            setIsUpdateKyc(true)
                            setIsprfileItem(false)
                            setKycAlertModel(false)
                            setIsTransit(false)
                        }}>
                            <Typography style={{ color: 'white', position: 'relative' }} >Complete Your Kyc</Typography>
                        </button>
                    </DialogContent>
                </Dialog>
            </div>

            <Box sx={{ display: 'flex', marginTop: -10 }}>
                <Drawer style={{ color: 'white' }} elevation={3} variant="permanent" open={open}>
                    <List style={{ height: '100vh', width: '100%', backgroundColor: '#282c34' }}>
                        <Box>
                            <div style={{ padding: 7, position: 'absolute', top: 0, width: '100%' }}>
                                <Typography style={{ fontWeight: '600', color: 'white', fontSize: 23, position: 'relative', left: '0.8rem', top: '1rem' }} >Prime <span style={{ color: 'rgb(41, 174, 227)' }} >Moovers</span></Typography>
                            </div>
                            <br />
                            <br />
                            <br />

                            <ListItem onClick={() => {
                                setIsDashboard(true)
                                setIsProfile(false)
                                setisCreateBids(false)
                                setIsUpdateKyc(false)
                                setisCurrentBids(false)
                                setIsBidoffer(false)
                                setIsprfileItem(false)
                                setIsSuccessFulBids(false)
                                refreshPage()
                                setIsTransit(false)
                            }} disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        backgroundColor: isDashboard ? 'white' : '',
                                        borderLeft: isDashboard ? '5px solid black' : ''

                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',

                                        }}
                                    >
                                        <FaPencilAlt style={{ color: isDashboard ? 'black' : 'white' }} color='white' fontSize={20} />
                                    </ListItemIcon>
                                    <ListItemText primary="Dashboard" sx={{ opacity: open ? 1 : 0, color: isDashboard ? 'black' : 'white' }} />
                                </ListItemButton>
                            </ListItem>
                            <List
                                sx={{ width: '100%', maxWidth: 360, bgcolor: '#282c34' }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                subheader={
                                    <ListSubheader component="div" id="nested-list-subheader">

                                    </ListSubheader>
                                }
                            >
                                <ListItemButton style={{ zIndex: 0, backgroundColor: '#282c34', }} onClick={handleClick}>
                                    <ListItemIcon>
                                        <InboxIcon style={{ color: 'white' }} />
                                    </ListItemIcon>
                                    <ListItemText style={{ position: 'relative', right: '0.4rem', color: 'white' }} primary="Bids Store" />
                                    {open9 ? <ExpandLess style={{ color: 'white' }} /> : <ExpandMore style={{ color: 'white' }} />}
                                </ListItemButton>
                                <Collapse style={{ zIndex: 0, backgroundColor: '#282c34' }} in={open9} timeout="auto" unmountOnExit>
                                    <List onClick={() => {
                                        setIsDashboard(false)
                                        setIsProfile(false)
                                        setIsSuccessFulBids(true)
                                        setIsUpdateKyc(false)
                                        setIsBidsForMonth(false)
                                        setisCurrentBids(false)
                                        setIsCloseBids(false)
                                        setIsprfileItem(false)
                                        setIsTransit(false)
                                    }} component="div" disablePadding>
                                        <ListItemButton sx={{
                                            pl: 4, backgroundColor: isSuccessfulBids ? 'white' : '',
                                            borderLeft: isSuccessfulBids ? '5px solid black' : ''
                                        }}>
                                            <ListItemIcon>
                                                <CheckCircleOutlineOutlinedIcon style={{ color: isSuccessfulBids ? 'black' : 'white' }} />
                                            </ListItemIcon>
                                            <ListItemText style={{ color: isSuccessfulBids ? 'black' : 'white' }} primary="Successful Bids" />
                                        </ListItemButton>
                                    </List>
                                    <List onClick={() => {
                                        setIsDashboard(false)
                                        setIsProfile(false)
                                        setIsSuccessFulBids(false)
                                        setIsUpdateKyc(false)
                                        setIsBidsForMonth(false)
                                        setisCurrentBids(false)
                                        setIsCloseBids(true)
                                        setIsTransit(false)
                                        setIsprfileItem(false)
                                    }} component="div" disablePadding>
                                        <ListItemButton sx={{
                                            pl: 4, backgroundColor: isCloseBids ? 'white' : '',
                                            borderLeft: isCloseBids ? '5px solid black' : ''
                                        }}>
                                            <ListItemIcon>
                                                <BlurOnIcon style={{ color: isCloseBids ? 'black' : 'white' }} />
                                            </ListItemIcon>
                                            <ListItemText style={{ color: isCloseBids ? 'black' : 'white' }} primary="Expired Bids" />
                                        </ListItemButton>
                                    </List>
                                    <List onClick={() => {
                                        setIsDashboard(false)
                                        setIsProfile(false)
                                        setIsSuccessFulBids(false)
                                        setIsUpdateKyc(false)
                                        setIsBidsForMonth(false)
                                        setisCurrentBids(false)
                                        setIsCloseBids(false)
                                        setIsprfileItem(false)
                                        setIsTransit(true)
                                    }} component="div" disablePadding>
                                        <ListItemButton sx={{
                                            pl: 4, backgroundColor: isTransit ? 'white' : '',
                                            borderLeft: isTransit ? '5px solid black' : ''
                                        }}>
                                            <ListItemIcon>
                                                <BsDice3 style={{ position: 'relative', left: '5px', color: isTransit ? 'black' : 'white' }} />
                                            </ListItemIcon>
                                            <ListItemText style={{ color: isTransit ? 'black' : 'white' }} primary="Transit" />
                                        </ListItemButton>
                                    </List>
                                    <List onClick={() => {
                                        setIsDashboard(false)
                                        setIsProfile(false)
                                        setIsSuccessFulBids(false)
                                        setIsUpdateKyc(false)
                                        setIsBidsForMonth(true)
                                        setisCurrentBids(false)
                                        setIsCloseBids(false)
                                        setIsTransit(false)
                                        setIsprfileItem(false)
                                    }} component="div" disablePadding>
                                        <ListItemButton sx={{
                                            pl: 4, backgroundColor: isBidsforMonth ? 'white' : '',
                                            borderLeft: isBidsforMonth ? '5px solid black' : ''
                                        }}>
                                            <ListItemIcon>
                                                <BsCalendar style={{ position: 'relative', left: '5px', color: isBidsforMonth ? 'black' : 'white' }} />
                                            </ListItemIcon>
                                            <ListItemText style={{ color: isBidsforMonth ? 'black' : 'white' }} primary="Bids For The Month" />
                                        </ListItemButton>
                                    </List>
                                </Collapse>
                            </List>
                            <ListItem disablePadding sx={{ display: 'block' }} onClick={() => { }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <FaFile style={{ color: 'white' }} fontSize={20} />
                                    </ListItemIcon>
                                    <ListItemText primary="Reports" sx={{ opacity: open ? 1 : 0, color: 'white' }} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding sx={{ display: 'block' }} onClick={() => {
                                setIsDashboard(false)
                                setIsProfile(false)
                                setIsSuccessFulBids(false)
                                setIsUpdateKyc(true)
                                setIsBidsForMonth(false)
                                setisCurrentBids(false)
                                setIsCloseBids(false)
                                setIsprfileItem(false)
                                setIsTransit(false)
                            }} >
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        backgroundColor: isUpdateKyc ? 'white' : '',
                                        borderLeft: isUpdateKyc ? '5px solid black' : ''
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <FaEdit style={{ color: isUpdateKyc ? 'black' : 'white' }} fontSize={20} />
                                    </ListItemIcon>
                                    <ListItemText primary="Update Kyc" sx={{ opacity: open ? 1 : 0, color: isUpdateKyc ? 'black' : 'white' }} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding sx={{ display: 'block' }} onClick={() => {
                                setIsDashboard(false)
                                setIsProfile(false)
                                setIsSuccessFulBids(false)
                                setIsUpdateKyc(false)
                                setIsBidsForMonth(false)
                                setisCurrentBids(false)
                                setIsCloseBids(false)
                                setIsprfileItem(true)
                                setIsTransit(false)
                            }} >
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <FaUser style={{ color: isProfile ? 'black' : 'white' }} fontSize={20} />
                                    </ListItemIcon>
                                    <ListItemText primary="Profile" sx={{ opacity: open ? 1 : 0, color: isProfile ? 'black' : 'white' }} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding sx={{ display: 'block', mt: 9 }} onClick={() => {
                                sessionStorage.removeItem('key')
                                navigate('/')
                            }} >
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <FaSignOutAlt style={{ color: 'white' }} fontSize={20} />
                                    </ListItemIcon>
                                    <ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0, color: 'white' }} />
                                </ListItemButton>
                            </ListItem>
                        </Box>
                    </List>
                    <Divider />
                </Drawer>

                <Box className='boxbg w-100' sx={{ mt: 8 }} >
                    <div className='mt-4 container-fluid'>
                        {
                            (isUpdateKyc && sessionStorage.getItem('type') === 'LorryBroker') && (

                                <div>
                                    <Grid md={12} >
                                        <Item className='card' style={{ position: 'fixed', top: 0, left: 0, right: 0, backgroundColor: 'white', marginBottom: 13, justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', border: 'none', width: '100%', height: '4rem', borderRadius: '0.003rem', zIndex: 100, borderBottom: '0.5px solid #30d0fc', paddingRight: 15 }} >
                                            <div style={{ boxShadow: 'none', border: 'none', flexDirection: 'row', position: 'absolute', left: '17.6rem' }} ><h4 style={{ fontWeight: '900', color: '#30d0fc', position: 'relative', top: '0.14rem', left: -3, zIndex: 100 }}>Welcome {firstName} {lastName}</h4></div>
                                            <div className='card' style={{ backgroundColor: 'white', border: 'none', width: '18rem', boxShadow: 'none', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <FaBars color='white' style={{ cursor: 'pointer' }} onClick={() => setOpen(!open)} size={33} />
                                            </div>
                                            <div style={{ border: 'none', height: 46, width: 46, borderRadius: 35, border: '0.8px solid #30d0fc', justifyContent: 'center', alignItems: 'center', boxShadow: 'none' }} className='card'>
                                                <div style={{ border: 'none', height: 42, width: 42, borderRadius: 35, backgroundColor: 'lightgray', justifyContent: 'center', alignItems: 'center', boxShadow: 'none' }} className='card'>
                                                    <Button
                                                        ref={anchorRef}
                                                        id="composition-button"
                                                        aria-controls={open6 ? 'composition-menu' : undefined}
                                                        aria-expanded={open6 ? 'true' : undefined}
                                                        aria-haspopup="true"
                                                        onClick={handleToggle}
                                                        style={{ color: 'gray' }}
                                                    >
                                                        <FaUser color='gray' size={16}></FaUser>
                                                    </Button>
                                                </div>
                                            </div>
                                        </Item>
                                        <Stack direction="row" spacing={2}>
                                            <div>
                                                <Popper
                                                    open={open6}
                                                    anchorEl={anchorRef.current}
                                                    role={undefined}
                                                    style={{ zIndex: 1001 }}
                                                    placement="bottom-start"
                                                    transition
                                                    disablePortal
                                                >
                                                    {({ TransitionProps, placement }) => (
                                                        <Grow
                                                            {...TransitionProps}
                                                            style={{
                                                                transformOrigin:
                                                                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                                                            }}
                                                        >
                                                            <Paper>
                                                                <ClickAwayListener onClickAway={handleClose6}>
                                                                    <MenuList
                                                                        autoFocusItem={open}
                                                                        id="composition-menu"
                                                                        aria-labelledby="composition-button"
                                                                        onKeyDown={handleListKeyDown}
                                                                    >
                                                                        <MenuItem onClick={() => {
                                                                            setIsDashboard(false)
                                                                            setIsProfile(false)
                                                                            setIsSuccessFulBids(false)
                                                                            setIsUpdateKyc(false)
                                                                            setIsBidsForMonth(false)
                                                                            setisCurrentBids(false)
                                                                            setIsCloseBids(false)
                                                                            setIsTransit(false)
                                                                            setIsprfileItem(true)
                                                                        }}>Profile</MenuItem>
                                                                        <MenuItem onClick={() => {
                                                                            navigate('/')
                                                                            sessionStorage.removeItem('key')
                                                                        }} style={{ color: 'red' }} ><FaSignOutAlt />Logout</MenuItem>
                                                                    </MenuList>
                                                                </ClickAwayListener>
                                                            </Paper>
                                                        </Grow>
                                                    )}
                                                </Popper>
                                            </div>
                                        </Stack>
                                    </Grid>
                                    <br />
                                    {
                                        aStore.map((data) => {
                                            return (
                                                <UpdateKycBroker aadhar_card={data.aadhar_card} aIsAdd={aIsAdd} AllDriver={AllDriver} />
                                            )
                                        })
                                    }
                                </div>
                            )
                        }

                        {
                            (isUpdateKyc && sessionStorage.getItem('type') === 'LorryWala') && (
                                <div>
                                    <Grid md={12} >
                                        <Item className='card' style={{ position: 'fixed', top: 0, left: 0, right: 0, backgroundColor: 'white', marginBottom: 13, justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', border: 'none', width: '100%', height: '4rem', borderRadius: '0.003rem', zIndex: 100, borderBottom: '0.5px solid #30d0fc', paddingRight: 15 }} >
                                            <div style={{ boxShadow: 'none', border: 'none', flexDirection: 'row', position: 'absolute', left: '17.6rem' }} ><h4 style={{ fontWeight: '900', color: '#30d0fc', position: 'relative', top: '0.14rem', left: -3, zIndex: 100 }}>Welcome {firstName} {lastName}</h4></div>
                                            <div className='card' style={{ backgroundColor: 'white', border: 'none', width: '18rem', boxShadow: 'none', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <FaBars color='white' style={{ cursor: 'pointer' }} onClick={() => setOpen(!open)} size={33} />
                                            </div>
                                            <div style={{ border: 'none', height: 46, width: 46, borderRadius: 35, border: '0.8px solid #30d0fc', justifyContent: 'center', alignItems: 'center', boxShadow: 'none' }} className='card'>
                                                <div style={{ border: 'none', height: 42, width: 42, borderRadius: 35, backgroundColor: 'lightgray', justifyContent: 'center', alignItems: 'center', boxShadow: 'none' }} className='card'>
                                                    <Button
                                                        ref={anchorRef}
                                                        id="composition-button"
                                                        aria-controls={open6 ? 'composition-menu' : undefined}
                                                        aria-expanded={open6 ? 'true' : undefined}
                                                        aria-haspopup="true"
                                                        onClick={handleToggle}
                                                        style={{ color: 'gray' }}
                                                    >
                                                        <FaUser color='gray' size={16}></FaUser>
                                                    </Button>
                                                </div>
                                            </div>
                                        </Item>
                                        <Stack direction="row" spacing={2}>
                                            <div>
                                                <Popper
                                                    open={open6}
                                                    anchorEl={anchorRef.current}
                                                    role={undefined}
                                                    style={{ zIndex: 1001 }}
                                                    placement="bottom-start"
                                                    transition
                                                    disablePortal
                                                >
                                                    {({ TransitionProps, placement }) => (
                                                        <Grow
                                                            {...TransitionProps}
                                                            style={{
                                                                transformOrigin:
                                                                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                                                            }}
                                                        >
                                                            <Paper>
                                                                <ClickAwayListener onClickAway={handleClose6}>
                                                                    <MenuList
                                                                        autoFocusItem={open}
                                                                        id="composition-menu"
                                                                        aria-labelledby="composition-button"
                                                                        onKeyDown={handleListKeyDown}
                                                                    >
                                                                        <MenuItem onClick={() => {
                                                                            setIsDashboard(false)
                                                                            setIsProfile(false)
                                                                            setIsSuccessFulBids(false)
                                                                            setIsUpdateKyc(false)
                                                                            setIsBidsForMonth(false)
                                                                            setisCurrentBids(false)
                                                                            setIsCloseBids(false)
                                                                            setIsTransit(false)
                                                                            setIsprfileItem(true)
                                                                        }}>Profile</MenuItem>
                                                                        <MenuItem onClick={() => {
                                                                            navigate('/')
                                                                            sessionStorage.removeItem('key')
                                                                        }} style={{ color: 'red' }} ><FaSignOutAlt />Logout</MenuItem>
                                                                    </MenuList>
                                                                </ClickAwayListener>
                                                            </Paper>
                                                        </Grow>
                                                    )}
                                                </Popper>
                                            </div>
                                        </Stack>
                                    </Grid>
                                    <br />
                                    {
                                        aStore.map((data) => {
                                            return (
                                                <LorryWalaUpdateKyc aadhar_card={data.aadhar_card} aIsAdd={aIsAdd} AllDriver={AllDriver} />
                                            )
                                        })
                                    }
                                </div>
                            )
                        }
                    </div>
                    {
                        isDashboard && (
                            <Box style={{ position: 'relative', marginTop: '-2.5rem' }}>
                                <Box sx={{ flexGrow: 1, p: 0 }} className='ps-3 pe-3' >
                                    <Grid md={12} >
                                        <Item className='card' style={{ position: 'fixed', top: 0, left: 0, right: 0, backgroundColor: 'white', marginBottom: 13, justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', border: 'none', width: '100%', height: '4.1rem', borderRadius: '0.003rem', zIndex: 100, borderBottom: '0.5px solid #30d0fc', paddingRight: 15 }} >
                                            <div style={{ boxShadow: 'none', border: 'none', flexDirection: 'row', position: 'absolute', left: '17.6rem' }} ><h4 style={{ fontWeight: '900', color: '#30d0fc', position: 'relative', top: '0.14rem', left: -3, zIndex: 100 }}>Welcome {firstName} {lastName}</h4></div>
                                            <div className='card' style={{ backgroundColor: 'white', border: 'none', width: '18rem', boxShadow: 'none', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <FaBars color='white' style={{ cursor: 'pointer' }} onClick={() => setOpen(!open)} size={33} />
                                            </div>
                                            <div style={{ border: 'none', height: 46, width: 46, borderRadius: 35, border: '0.8px solid #30d0fc', justifyContent: 'center', alignItems: 'center', boxShadow: 'none' }} className='card'>
                                                <div style={{ border: 'none', height: 42, width: 42, borderRadius: 35, backgroundColor: 'lightgray', justifyContent: 'center', alignItems: 'center', boxShadow: 'none' }} className='card'>
                                                    <Button
                                                        ref={anchorRef}
                                                        id="composition-button"
                                                        aria-controls={open6 ? 'composition-menu' : undefined}
                                                        aria-expanded={open6 ? 'true' : undefined}
                                                        aria-haspopup="true"
                                                        onClick={handleToggle}
                                                        style={{ color: 'gray' }}
                                                    >
                                                        <FaUser color='gray' size={16}></FaUser>
                                                    </Button>
                                                </div>
                                            </div>
                                        </Item>
                                        <Stack direction="row" spacing={2}>
                                            <div>
                                                <Popper
                                                    open={open6}
                                                    anchorEl={anchorRef.current}
                                                    role={undefined}
                                                    style={{ zIndex: 1001 }}
                                                    placement="bottom-start"
                                                    transition
                                                    disablePortal
                                                >
                                                    {({ TransitionProps, placement }) => (
                                                        <Grow
                                                            {...TransitionProps}
                                                            style={{
                                                                transformOrigin:
                                                                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                                                            }}
                                                        >
                                                            <Paper>
                                                                <ClickAwayListener onClickAway={handleClose6}>
                                                                    <MenuList
                                                                        autoFocusItem={open}
                                                                        id="composition-menu"
                                                                        aria-labelledby="composition-button"
                                                                        onKeyDown={handleListKeyDown}
                                                                    >
                                                                        <MenuItem onClick={() => {
                                                                            setIsDashboard(false)
                                                                            setIsProfile(false)
                                                                            setIsSuccessFulBids(false)
                                                                            setIsUpdateKyc(false)
                                                                            setIsBidsForMonth(false)
                                                                            setisCurrentBids(false)
                                                                            setIsCloseBids(false)
                                                                            setIsTransit(false)
                                                                            setIsprfileItem(true)
                                                                        }}>Profile</MenuItem>
                                                                        <MenuItem onClick={() => {
                                                                            navigate('/')
                                                                            sessionStorage.removeItem('key')
                                                                        }} style={{ color: 'red' }} ><FaSignOutAlt />Logout</MenuItem>
                                                                    </MenuList>
                                                                </ClickAwayListener>
                                                            </Paper>
                                                        </Grow>
                                                    )}
                                                </Popper>
                                            </div>
                                        </Stack>
                                    </Grid>
                                    <Box style={{ height: '2rem', marginTop: 56 }}>
                                        <DashShowProfile />
                                    </Box>
                                    <div>
                                        {
                                            isAlert && (
                                                <Grid md={12} sx={{ mt: 3, borderRadius: '0.04rem' }} >
                                                    <Item style={{ borderRadius: '0.06rem', padding: '0.8rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderLeft: '1.6px solid #30d0fc' }} >
                                                        <div style={{ display: 'flex', justifyContent: 'center' }} >
                                                            <div style={{ width: '26rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRight: '1ps soild gray', borderRightStyle: 'dashed', paddingRight: 3 }} >
                                                                <div style={{ display: 'flex' }}>
                                                                    <Typography><img src='https://i.pinimg.com/originals/76/05/2e/76052e4c0db271656da0cf58d826543e.jpg' width='14px' /> Aadhar Kyc</Typography>
                                                                    {
                                                                        isAv && <Typography color='rgb(30, 225, 30)' style={{ fontSize: '0.8rem' }} >verified<img src='https://th.bing.com/th/id/OIP.vg8_-0X783jk--lcR8S3EAHaHa?w=183&h=183&c=7&r=0&o=5&dpr=2.5&pid=1.7' width='20px' /></Typography>
                                                                    }
                                                                    {
                                                                        !isAv && <Typography color='red' style={{ fontSize: '0.8rem' }} >Not Verified</Typography>
                                                                    }
                                                                </div>
                                                                <div style={{ width: '3rem', borderTop: '1px solid lightgray', borderStyle: 'dashed' }} ></div>
                                                                <div style={{ display: 'flex' }} >
                                                                    <Typography> <img src='https://img.freepik.com/free-vector/tax-form-concept-illustration_114360-7378.jpg?size=626&ext=jpg&ga=GA1.2.415759594.1681714138&semt=robertav1_2_sidr' width='30px' height='14px' /> Gst Kyc</Typography>
                                                                    {
                                                                        isAg && <Typography color='rgb(30, 225, 30)' style={{ fontSize: '0.8rem' }} >verified<img src='https://th.bing.com/th/id/OIP.vg8_-0X783jk--lcR8S3EAHaHa?w=183&h=183&c=7&r=0&o=5&dpr=2.5&pid=1.7' width='20px' /></Typography>
                                                                    }
                                                                    {
                                                                        !isAg && <Typography color='red' style={{ fontSize: '0.8rem' }} >Not Verified</Typography>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <Typography style={{ fontSize: '0.8rem', position: 'relative', top: 3, marginLeft: 3 }} >Your Kyc is pending please complete kyc to further process.</Typography>
                                                        </div>
                                                        <button onClick={() => {
                                                            setIsDashboard(false)
                                                            setIsProfile(false)
                                                            setIsSuccessFulBids(false)
                                                            setIsUpdateKyc(true)
                                                            setIsBidsForMonth(false)
                                                            setisCurrentBids(false)
                                                            setIsCloseBids(false)
                                                            setIsTransit(false)
                                                            setIsprfileItem(false)
                                                        }} style={{ width: '7.3rem', border: '1px solid red', height: '2.2rem', borderRadius: '0.2rem', fontWeight: '500', color: 'red', fontSize: '0.9rem', position: 'relative' }} >Complete Kyc</button>
                                                    </Item>
                                                </Grid>
                                            )
                                        }
                                    </div>
                                    <Box sx={{ flexGrow: 1, mt: 4 }}>
                                        <Grid container spacing={2}>
                                            <Grid onClick={handleClickListItem} item xs={2}>
                                                <Item style={{ position: 'relative', }}>
                                                    <TuneTwoToneIcon onClick={handleClickListItem} style={{ position: 'absolute', right: 5, top: 9.4, color: '#30d0fc', cursor: 'pointer' }} />
                                                    <input style={{ cursor: 'auto', border: 'none', outline: 'none', outlineColor: 'none', width: '100%', marginTop: 4 }} readOnly placeholder='Ongoing Bids' />
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <div style={{ paddingLeft: 10, }}>
                                        <Menu
                                            id="lock-menu"
                                            anchorEl={anchorEl}
                                            open={open5}
                                            style={{ marginLeft: 8 }}
                                            onClose={handleClose5}
                                            MenuListProps={{
                                                'aria-labelledby': 'lock-button',
                                                role: 'listbox',
                                            }}
                                        >


                                            <div style={{ height: '21rem', width: '45rem', padding: 21 }} >
                                                <Box
                                                    component="form"
                                                    sx={{
                                                        '& .MuiTextField-root': { width: '100%' },
                                                    }}
                                                    noValidate
                                                    autoComplete="off"
                                                >
                                                    <div>
                                                        <h4>Search by route</h4>
                                                        <form onSubmit={(e) => e.preventDefault()}>
                                                            <TextField
                                                                id="standard-multiline-flexible"
                                                                label="From"
                                                                multiline
                                                                maxRows={4}
                                                                variant="standard"
                                                                onChange={(e) => setFrom(e.target.value)}
                                                            />

                                                            <TextField
                                                                id="standard-multiline-flexible"
                                                                label="To"
                                                                multiline
                                                                maxRows={4}
                                                                variant="standard"
                                                                sx={{ mt: 2 }}
                                                                onChange={(e) => setTo(e.target.value)}
                                                            />
                                                            <Button onClick={() => searchBids()} style={{ position: 'absolute', bottom: '0.4rem', right: '9rem', height: '2.8rem', width: '7.4rem', border: 'none', backgroundColor: 'gold', color: 'white', fontWeight: 'bold', borderRadius: '0.3rem' }} >Seacrh</Button>
                                                            <Button onClick={() => handleClose5()} style={{ position: 'absolute', bottom: '0.4rem', right: '0.3rem', height: '2.8rem', width: '7.4rem', border: 'none', backgroundColor: 'red', color: 'white', fontWeight: 'bold', borderRadius: '0.3rem' }} >Cancel</Button>
                                                        </form>
                                                    </div>
                                                </Box>
                                            </div>
                                        </Menu>
                                    </div>

                                    <Grid container spacing={2} sx={{ mt: 3 }}>
                                        <Grid xs={12} sx={{ height: '11rem', marginBottom: '1.2rem' }} md={12}>
                                            <BrokersCard successBidLen={sbidsLen} accLen={accBidLen} currentBidLen={currentBidLen} expLen={expLen} />
                                        </Grid>
                                    </Grid>
                                    <div>
                                        <Grid container spacing={2} sx={{ padding: 0, pt: 0 }}>
                                            {
                                                (bidCurrent.length > 0) && (
                                                    bidCurrent.map((data) => {
                                                        return (
                                                            <Grid md={6} xs={12}  >
                                                                <Item style={{ position: 'relative', marginLeft: 0, marginRight: 0 }}>
                                                                    <div style={{ backgroundColor: 'rgb(227, 239, 249)', padding: 6, borderRadius: 4 }}>
                                                                        <div className='card' style={{ border: 'none', borderRadius: '2rem', color: 'white', height: '1.4rem', justifyContent: 'center', backgroundColor: 'rgb(50, 186, 240)', width: '13rem', marginTop: -17, marginBottom: 10 }} variant='outlined'>
                                                                            <MyTimer expiryTimestamp={new Date().setSeconds(data.currentMinuts * 60)} />
                                                                        </div>
                                                                        <Typography style={{ fontSize: 11.5, marginBottom: -15 }}>Despatch Time: {data.dispatchTime}</Typography>
                                                                        <p style={{ position: 'relative', top: '1.8rem' }}><span style={{ color: '#232363' }}><FiberManualRecordIcon style={{ fontSize: 14 }} /> From: </span> {data.startLocation}</p>
                                                                        <div style={{ height: '2.3rem', borderLeft: '1px solid #232363', position: 'relative', left: '0.4rem' }} ></div>
                                                                        <p style={{ position: 'relative', top: '-0.9rem' }}><span style={{ color: '#232363' }}>  <FaMapMarker style={{ fontSize: 14 }} color='gold' /> To: </span> {data.endLocation}</p>
                                                                        <div className='card' style={{ border: 'none', boxShadow: 'none', flexDirection: 'row', justifyContent: 'space-between', marginBottom: -2.5, backgroundColor: 'rgb(227, 239, 249)' }}>
                                                                            <div>
                                                                                <Typography style={{ position: 'relative', top: '-0.9rem' }}><span style={{ color: 'black', fontSize: 14, }}> Vehicle Type</span>:  <span style={{ fontSize: 12 }} >{data.vehicle}</span></Typography>
                                                                                <Typography style={{ position: 'relative', top: '-0.9rem' }}><span style={{ color: 'black', fontSize: 14, }}> Capacity </span>:  <span style={{ fontSize: 12 }} >{data.capacity}</span></Typography>
                                                                            </div>
                                                                            <div>
                                                                                <Typography style={{ position: 'relative', top: '-0.9rem' }}><span style={{ color: 'black', fontSize: 14, }}> <span style={{ color: 'black', fontSize: 12 }}>Material: </span> {data.material}</span></Typography>
                                                                                <Typography style={{ position: 'relative', top: '-0.9rem' }}><span style={{ color: 'black', fontSize: 14, }}> Required Vehicle </span>:  <span style={{ fontSize: 16 }} >{data.vehicleNum} Vehicle</span></Typography>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='card bg-info mt-4' style={{ border: 'none', height: 50, alignItems: 'center', width: '74%', boxShadow: 'none', marginTop: 1, top: -8, flexDirection: 'row', paddingLeft: 4, borderRadius: '0.2rem' }}>
                                                                        <div style={{ position: 'relative', left: 10 }}>
                                                                            <Typography style={{ fontSize: 10, marginLeft: '0.1rem', fontWeight: 'bold', color: 'white' }}>Bid Id: {data.send_bids_id}</Typography>
                                                                            <strong style={{ color: 'white' }}>Distance: {data.distance}</strong>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <Dialog open={open11} maxWidth='lg' onClose={() => setOpen11(false)}>
                                                                            <DialogTitle>Send Bid</DialogTitle>
                                                                            <DialogContent style={{ width: '41rem' }} >
                                                                                <DialogContentText>
                                                                                    Send Bid to this customer, please enter your Bid amount.
                                                                                </DialogContentText>
                                                                                <Box style={{ marginTop: 18, marginBottom: 2 }}>
                                                                                    {
                                                                                        (vehicleReqNum === 2) && (
                                                                                            <select onChange={(e) => setQuote(e.target.value)} style={{ height: '2.3rem', width: '100%' }}>
                                                                                                <option>Select vehicle number</option>
                                                                                                <option value={1}>Quote for 1 vehicle</option>
                                                                                                <option value={2}>Quote for 2 vehicle</option>
                                                                                            </select>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        (vehicleReqNum === 3) && (
                                                                                            <select onChange={(e) => setQuote(e.target.value)} style={{ height: '2.3rem', width: '100%' }}>
                                                                                                <option>Select vehicle number</option>
                                                                                                <option value={1}>Quote for 1 vehicle</option>
                                                                                                <option value={2}>Quote for 2 vehicle</option>
                                                                                                <option value={3}>Quote for 3 vehicle</option>
                                                                                            </select>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        (vehicleReqNum === 4) && (
                                                                                            <select onChange={(e) => setQuote(e.target.value)} style={{ height: '2.3rem', width: '100%' }}>
                                                                                                <option>Select vehicle number</option>
                                                                                                <option value={1}>Quote for 1 vehicle</option>
                                                                                                <option value={2}>Quote for 2 vehicle</option>
                                                                                                <option value={3}>Quote for 3 vehicle</option>
                                                                                                <option value={4}>Quote for 4 vehicle</option>
                                                                                            </select>
                                                                                        )
                                                                                    }
                                                                                </Box>
                                                                                <TextField
                                                                                    margin="dense"
                                                                                    id="name"
                                                                                    label="Bid Amount"
                                                                                    type="number"
                                                                                    fullWidth
                                                                                    variant="standard"
                                                                                    onChange={(e) => {
                                                                                        setMoney(e.target.value)
                                                                                        setMoneyValidation1(false)
                                                                                    }}
                                                                                />
                                                                                <TextField
                                                                                    margin="dense"
                                                                                    id="name"
                                                                                    label="Truck Number (optional)"
                                                                                    type="text"
                                                                                    fullWidth
                                                                                    variant="standard"
                                                                                />
                                                                                {
                                                                                    moneyValidation1 && (
                                                                                        <p className='text-danger'>Please enter your bid value like this Ex: 500, 1000, 1500, 2000 etc</p>
                                                                                    )
                                                                                }
                                                                            </DialogContent>
                                                                            <DialogActions>
                                                                                {
                                                                                    !isAccepting && (
                                                                                        <Button style={{ backgroundColor: '#12124e' }} variant='contained' onClick={moneyValidation}>Quote</Button>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    isAccepting && (
                                                                                        <Button disabled={true} variant='contained' onClick={moneyValidation}>Quoting...</Button>
                                                                                    )
                                                                                }
                                                                                <Button variant='contained' onClick={() => setOpen11(false)}>Cancel</Button>
                                                                            </DialogActions>
                                                                        </Dialog>
                                                                    </div>

                                                                    <button className='bidsBtn' style={{ backgroundColor: '#12124e', color: 'white', border: 'none', position: 'absolute', right: '1rem', top: '0.87rem', width: '8rem', borderRadius: '0.2rem' }} variant='contained' onClick={() => sendBid(data.email, data.send_bids_id, data.vehicleNum, data.currentMinuts)}>Quote Bid</button>

                                                                    <img style={{ position: 'absolute', bottom: 1, right: 3, width: '73px' }} width='100px' src='https://img.freepik.com/free-vector/legal-statement-court-notice-judge-decision-judicial-system-lawyer-attorney-studying-papers-cartoon-character-mortgage-debt-legislation_335657-3535.jpg?size=626&ext=jpg&ga=GA1.1.415759594.1681714138&semt=robertav1_2_sidr' />
                                                                </Item>
                                                            </Grid>
                                                        )
                                                    })
                                                )
                                            }{
                                                (bidCurrent.length === 0) && (
                                                    <Grid item xs={12}>
                                                        <Item style={{ height: 87 + 'vh', alignItems: 'center' }}>
                                                            <div className='card' style={{ height: 85.2 + 'vh', alignItems: 'center', boxShadow: 'none', justifyContent: 'center', border: 'none' }}>
                                                                <img width='150px' src='https://img.freepik.com/free-vector/auction-house-abstract-concept-vector-illustration-residential-commercial-property-auction-buy-sell-assets-online-exclusive-bid-consecutive-biddings-business-auctions-abstract-metaphor_335657-1956.jpg?size=626&ext=jpg&ga=GA1.1.415759594.1681714138&semt=robertav1_2_sidr' />
                                                                <h3>No Live Bids</h3>
                                                            </div>
                                                        </Item>
                                                    </Grid>
                                                )
                                            }
                                        </Grid>
                                    </div>
                                    <Footer></Footer>
                                </Box>
                            </Box>
                        )
                    }


                    {
                        <div style={{ marginTop: '-4.8rem' }}>
                            {
                                profileItem && (
                                    <Profile location={location} firstName={firstName} lastName={lastName} newEmail={newEmail} newMobile={newMobile} newGst={newGst} aadhar={aadhar} firm={firm} business={business} mobile={mobile} email={email} />
                                )
                            }
                        </div>
                    }


                    {
                        isSuccessfulBids && (
                            <div className='col-12 mx-auto ps-3 pe-3' style={{ marginTop: '-3.9rem' }} >
                                <Grid md={12} >
                                    <Item className='card' style={{ position: 'fixed', top: 0, left: 0, right: 0, backgroundColor: 'white', marginBottom: 13, justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', border: 'none', width: '100%', height: '4rem', borderRadius: '0.003rem', zIndex: 100, borderBottom: '0.5px solid #30d0fc', paddingRight: 15 }} >
                                        <div style={{ boxShadow: 'none', border: 'none', flexDirection: 'row', position: 'absolute', left: '17.6rem' }} ><h4 style={{ fontWeight: '900', color: '#30d0fc', position: 'relative', top: '0.14rem', left: -3, zIndex: 100 }}>Successful Bids</h4></div>
                                        <div className='card' style={{ backgroundColor: 'white', border: 'none', width: '18rem', boxShadow: 'none', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <FaBars color='white' style={{ cursor: 'pointer' }} onClick={() => setOpen(!open)} size={33} />
                                        </div>
                                        <div style={{ border: 'none', height: 46, width: 46, borderRadius: 35, border: '0.8px solid #30d0fc', justifyContent: 'center', alignItems: 'center', boxShadow: 'none' }} className='card'>
                                            <div style={{ border: 'none', height: 42, width: 42, borderRadius: 35, backgroundColor: 'lightgray', justifyContent: 'center', alignItems: 'center', boxShadow: 'none' }} className='card'>
                                                <Button
                                                    ref={anchorRef}
                                                    id="composition-button"
                                                    aria-controls={open6 ? 'composition-menu' : undefined}
                                                    aria-expanded={open6 ? 'true' : undefined}
                                                    aria-haspopup="true"
                                                    onClick={handleToggle}
                                                    style={{ color: 'gray' }}
                                                >
                                                    <FaUser color='gray' size={16}></FaUser>
                                                </Button>
                                            </div>
                                        </div>
                                    </Item>
                                    <Stack direction="row" spacing={2}>
                                        <div>
                                            <Popper
                                                open={open6}
                                                anchorEl={anchorRef.current}
                                                role={undefined}
                                                style={{ zIndex: 1001 }}
                                                placement="bottom-start"
                                                transition
                                                disablePortal
                                            >
                                                {({ TransitionProps, placement }) => (
                                                    <Grow
                                                        {...TransitionProps}
                                                        style={{
                                                            transformOrigin:
                                                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                                                        }}
                                                    >
                                                        <Paper>
                                                            <ClickAwayListener onClickAway={handleClose6}>
                                                                <MenuList
                                                                    autoFocusItem={open}
                                                                    id="composition-menu"
                                                                    aria-labelledby="composition-button"
                                                                    onKeyDown={handleListKeyDown}
                                                                >
                                                                    <MenuItem onClick={() => {
                                                                        setIsDashboard(false)
                                                                        setIsProfile(false)
                                                                        setIsSuccessFulBids(false)
                                                                        setIsUpdateKyc(false)
                                                                        setIsBidsForMonth(false)
                                                                        setisCurrentBids(false)
                                                                        setIsCloseBids(false)
                                                                        setIsTransit(false)
                                                                        setIsprfileItem(true)
                                                                    }}>Profile</MenuItem>
                                                                    <MenuItem onClick={() => {
                                                                        navigate('/')
                                                                        sessionStorage.removeItem('key')
                                                                    }} style={{ color: 'red' }} ><FaSignOutAlt />Logout</MenuItem>
                                                                </MenuList>
                                                            </ClickAwayListener>
                                                        </Paper>
                                                    </Grow>
                                                )}
                                            </Popper>
                                        </div>
                                    </Stack>
                                </Grid>
                                <Grid container spacing={2} sx={{ padding: 0, pt: 1, mt: 12 }}>
                                    {
                                        (sbids.length > 0) && (
                                            sbids.map((data) => {
                                                let isSuccessBids = ''
                                                if (data.isSuccessBids === 1) {
                                                    isSuccessBids = true
                                                } else {
                                                    isSuccessBids = false
                                                }
                                                return (
                                                    <Grid item md={6} xs={12} >
                                                        <Item style={{ position: 'relative' }}>
                                                            <Typography style={{ fontSize: 10, marginLeft: '0.1rem' }}>Bid Id: {data.send_bids_id}</Typography>
                                                            <Typography style={{ position: 'relative', top: '1rem' }}><span style={{ color: '#232363', fontSize: 16 }}><FiberManualRecordIcon style={{ fontSize: 15 }} /> From: </span> {data.startLocation}</Typography>
                                                            <div style={{ height: '2.3rem', borderLeft: '1px solid #232363', position: 'relative', left: '0.4rem' }} ></div>
                                                            <Typography style={{ position: 'relative', top: '-0.9rem' }}><span style={{ color: '#232363' }}>  <FaMapMarker style={{ fontSize: 14 }} color='gold' /> To: </span> {data.endLocation}</Typography>
                                                            <div style={{ marginTop: 18, marginBottom: 18 }}>
                                                                <Typography style={{ position: 'relative', top: '-0.9rem' }}><span style={{ color: 'gray', fontSize: 14, fontWeight: 'bold', }}>  Vehicle Type </span>:  <span style={{ fontWeight: 'bold', fontSize: 14 }} >{data.vehicle}</span></Typography>
                                                                <Typography style={{ position: 'relative', top: '-0.5rem' }}><span style={{ color: 'gray', fontSize: 14, fontWeight: 'bold', }}> Capacity </span>:  <span style={{ fontWeight: 'bold', fontSize: 14 }} >{data.capacity}</span></Typography>
                                                            </div>
                                                            <div style={{ position: 'relative', }} className='d-flex'>
                                                                <Typography style={{ position: 'relative', fontSize: 15, marginRight: 30 }}><span style={{ color: '#232363', fontSize: 15 }}>Material: </span> {data.material}</Typography>
                                                                <Typography style={{ position: 'relative', fontSize: 15, marginRight: 30 }}><span style={{ color: '#232363', fontSize: 15 }}>Number Of Vehicle: </span> {data.vehicleNum} Vehicle</Typography>
                                                            </div>
                                                            <div>
                                                                <Dialog open={open10} onClose={() => setOpen10(false)}>
                                                                    <DialogTitle>Confirm Bid</DialogTitle>
                                                                    <DialogContent style={{ width: '35rem' }} >


                                                                        <FormControl variant="standard" sx={{ minWidth: '100%' }}>

                                                                            <InputLabel id="demo-simple-select-standard-label">Select Driver</InputLabel>
                                                                            <Select
                                                                                labelId="demo-simple-select-standard-label"
                                                                                id="demo-simple-select-standard"
                                                                                value={driver}
                                                                                onChange={handleChange}
                                                                                label="Select Driver"
                                                                            >
                                                                                <MenuItem value="">
                                                                                    <em>All Drivers</em>
                                                                                </MenuItem>

                                                                                {
                                                                                    AllDriver.map((data) => {
                                                                                        return (
                                                                                            <MenuItem value={data.driver_id}><FaUser style={{ marginRight: 12 }} /> {data.dName}</MenuItem>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Select>

                                                                        </FormControl>
                                                                        <br />
                                                                        <br />
                                                                        <br />
                                                                        <FormControl variant="standard" sx={{ minWidth: '100%' }}>
                                                                            <InputLabel id="demo-simple-select-standard-label">Select Vehicle</InputLabel>
                                                                            <Select
                                                                                labelId="demo-simple-select-standard-label"
                                                                                id="demo-simple-select-standard"
                                                                                value={vehicle}
                                                                                onChange={handleChange1}
                                                                                label="Select Vehicle"
                                                                            >
                                                                                <MenuItem value="">
                                                                                    <em>All Vehicles</em>
                                                                                </MenuItem>
                                                                                {
                                                                                    vehicleData.map((data) => {
                                                                                        return (
                                                                                            <MenuItem value={data.vehicle_id}><FaTruck style={{ marginRight: 12 }} /> {data.vehicleType}----{data.vehicleCapacity} </MenuItem>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Select>
                                                                        </FormControl>
                                                                    </DialogContent>
                                                                    <DialogActions>
                                                                        {
                                                                            !isConfirming && (
                                                                                <Button style={{ backgroundColor: '#12124e' }} variant='contained' onClick={() => confirmBid(data.send_bids_id, data.email, data.startLocation, data.endLocation)}>Confirm</Button>
                                                                            )
                                                                        }
                                                                        {
                                                                            isConfirming && (
                                                                                <Button disabled={true} variant='contained' >Confirming...</Button>
                                                                            )
                                                                        }
                                                                        <Button variant='contained' onClick={() => setOpen10(false)}>Cancel</Button>
                                                                    </DialogActions>
                                                                </Dialog>
                                                            </div>
                                                            {
                                                                isSuccessBids && (
                                                                    <div>
                                                                        <Button disabled={true} className='bidsBtn' onClick={() => {
                                                                            setOpen(true)
                                                                        }} style={{ color: 'lightgray', border: '2px solid lightgray', position: 'absolute', right: '2rem', top: '0.6rem' }} variant='outlined' >Confirmed</Button>
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                !isSuccessBids && (
                                                                    <div>
                                                                        <Button className='bidsBtn' onClick={() => {
                                                                            setOpen10(true)
                                                                        }} style={{ color: 'white', border: 'none', position: 'absolute', backgroundColor: '#282c34', right: '1rem', top: '1rem' }} variant='outlined' >Confirm</Button>
                                                                    </div>
                                                                )
                                                            }
                                                            <img style={{ position: 'absolute', bottom: 1, right: 3, width: '89px' }} width='150px' src='https://img.freepik.com/free-vector/auction-house-abstract-concept-vector-illustration-residential-commercial-property-auction-buy-sell-assets-online-exclusive-bid-consecutive-biddings-business-auctions-abstract-metaphor_335657-1956.jpg?size=626&ext=jpg&ga=GA1.1.415759594.1681714138&semt=robertav1_2_sidr' />
                                                        </Item>
                                                    </Grid>
                                                )
                                            })
                                        )
                                    }{
                                        (sbids.length === 0) && (
                                            <Grid item xs={12}>
                                                <Item style={{ height: 87 + 'vh', alignItems: 'center' }}>
                                                    <div className='card' style={{ height: 85.2 + 'vh', alignItems: 'center', boxShadow: 'none', justifyContent: 'center', border: 'none' }}>
                                                        <img width='150px' src='https://img.freepik.com/free-vector/auction-house-abstract-concept-vector-illustration-residential-commercial-property-auction-buy-sell-assets-online-exclusive-bid-consecutive-biddings-business-auctions-abstract-metaphor_335657-1956.jpg?size=626&ext=jpg&ga=GA1.1.415759594.1681714138&semt=robertav1_2_sidr' />
                                                        <h3>No Successful Bids</h3>
                                                    </div>
                                                </Item>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </div>
                        )
                    }
                    {
                        isCloseBids && (
                            <div className='col-12 mx-auto mt-3 ps-3 pe-3' style={{ marginTop: '3.4rem' }} >
                                <Grid md={12} >
                                    <Item className='card' style={{ position: 'fixed', top: 0, left: 0, right: 0, backgroundColor: 'white', marginBottom: 13, justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', border: 'none', width: '100%', height: '4rem', borderRadius: '0.003rem', zIndex: 100, borderBottom: '0.5px solid #30d0fc', paddingRight: 15 }} >
                                        <div style={{ boxShadow: 'none', border: 'none', flexDirection: 'row', position: 'absolute', left: '17.6rem' }} ><h4 style={{ fontWeight: '900', color: '#30d0fc', position: 'relative', top: '0.14rem', left: -3, zIndex: 100 }}>Expired Bids</h4></div>
                                        <div className='card' style={{ backgroundColor: 'white', border: 'none', width: '18rem', boxShadow: 'none', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <FaBars color='white' style={{ cursor: 'pointer' }} onClick={() => setOpen(!open)} size={33} />
                                        </div>
                                        <div style={{ border: 'none', height: 46, width: 46, borderRadius: 35, border: '0.8px solid #30d0fc', justifyContent: 'center', alignItems: 'center', boxShadow: 'none' }} className='card'>
                                            <div style={{ border: 'none', height: 42, width: 42, borderRadius: 35, backgroundColor: 'lightgray', justifyContent: 'center', alignItems: 'center', boxShadow: 'none' }} className='card'>
                                                <Button
                                                    ref={anchorRef}
                                                    id="composition-button"
                                                    aria-controls={open6 ? 'composition-menu' : undefined}
                                                    aria-expanded={open6 ? 'true' : undefined}
                                                    aria-haspopup="true"
                                                    onClick={handleToggle}
                                                    style={{ color: 'gray' }}
                                                >
                                                    <FaUser color='gray' size={16}></FaUser>
                                                </Button>
                                            </div>
                                        </div>
                                    </Item>
                                    <Stack direction="row" spacing={2}>
                                        <div>
                                            <Popper
                                                open={open6}
                                                anchorEl={anchorRef.current}
                                                role={undefined}
                                                style={{ zIndex: 1001 }}
                                                placement="bottom-start"
                                                transition
                                                disablePortal
                                            >
                                                {({ TransitionProps, placement }) => (
                                                    <Grow
                                                        {...TransitionProps}
                                                        style={{
                                                            transformOrigin:
                                                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                                                        }}
                                                    >
                                                        <Paper>
                                                            <ClickAwayListener onClickAway={handleClose6}>
                                                                <MenuList
                                                                    autoFocusItem={open}
                                                                    id="composition-menu"
                                                                    aria-labelledby="composition-button"
                                                                    onKeyDown={handleListKeyDown}
                                                                >
                                                                    <MenuItem onClick={() => {
                                                                        setIsDashboard(false)
                                                                        setIsProfile(false)
                                                                        setIsSuccessFulBids(false)
                                                                        setIsUpdateKyc(false)
                                                                        setIsBidsForMonth(false)
                                                                        setisCurrentBids(false)
                                                                        setIsCloseBids(false)
                                                                        setIsTransit(false)
                                                                        setIsprfileItem(true)
                                                                    }}>Profile</MenuItem>
                                                                    <MenuItem onClick={() => {
                                                                        navigate('/')
                                                                        sessionStorage.removeItem('key')
                                                                    }} style={{ color: 'red' }} ><FaSignOutAlt />Logout</MenuItem>
                                                                </MenuList>
                                                            </ClickAwayListener>
                                                        </Paper>
                                                    </Grow>
                                                )}
                                            </Popper>
                                        </div>
                                    </Stack>
                                </Grid>
                                <Grid container spacing={2} sx={{ padding: 0, pt: 1, mt: 12 }}>
                                    {
                                        (bids.length > 0) && (
                                            bids.map((data) => {
                                                let isSuccessBids = ''
                                                if (data.isSuccessBids === 1) {
                                                    isSuccessBids = true
                                                } else {
                                                    isSuccessBids = false
                                                }
                                                return (
                                                    <Grid item md={6} xs={12}  >
                                                        <Item style={{ position: 'relative' }}>
                                                            <Typography style={{ fontSize: 10, marginLeft: '0.1rem' }}>Bid Id: {data.send_bids_id}</Typography>
                                                            <Typography style={{ position: 'relative', top: '1rem' }}><span style={{ color: '#232363', fontSize: 16 }}><FiberManualRecordIcon style={{ fontSize: 15 }} /> From: </span> {data.startLocation}</Typography>
                                                            <div style={{ height: '2.3rem', borderLeft: '1px solid #232363', position: 'relative', left: '0.4rem' }} ></div>
                                                            <Typography style={{ position: 'relative', top: '-0.9rem' }}><span style={{ color: '#232363' }}>  <FaMapMarker style={{ fontSize: 14 }} color='gold' /> To: </span> {data.endLocation}</Typography>
                                                            <div style={{ marginTop: 18, marginBottom: 18 }}>
                                                                <Typography style={{ position: 'relative', top: '-0.9rem' }}><span style={{ color: 'gray', fontSize: 14, fontWeight: 'bold', }}>  Vehicle Type </span>:  <span style={{ fontWeight: 'bold', fontSize: 14 }} >{data.vehicle}</span></Typography>
                                                                <Typography style={{ position: 'relative', top: '-0.5rem' }}><span style={{ color: 'gray', fontSize: 14, fontWeight: 'bold', }}> Capacity </span>:  <span style={{ fontWeight: 'bold', fontSize: 14 }} >{data.capacity}</span></Typography>
                                                            </div>
                                                            <div style={{ position: 'relative', }} className='d-flex'>
                                                                <Typography style={{ position: 'relative', fontSize: 15, marginRight: 30 }}><span style={{ color: '#232363', fontSize: 15 }}>Material: </span> {data.material}</Typography>
                                                                <Typography style={{ position: 'relative', fontSize: 15, marginRight: 30 }}><span style={{ color: '#232363', fontSize: 15 }}>Number Of Vehicle: </span> {data.vehicleNum} Vehicle</Typography>
                                                            </div>
                                                            <div>
                                                                <Dialog open={open10} onClose={() => setOpen10(false)}>
                                                                    <DialogTitle>Send Bid</DialogTitle>
                                                                    <DialogContent>
                                                                        <DialogContentText>
                                                                            Send Vehicle And Driver Data to the next step by bid customer, please select vehcle and driver.
                                                                        </DialogContentText>
                                                                        <FormControl variant="standard" sx={{ minWidth: '100%' }}>
                                                                            <InputLabel id="demo-simple-select-standard-label">Select Driver</InputLabel>
                                                                            <Select
                                                                                labelId="demo-simple-select-standard-label"
                                                                                id="demo-simple-select-standard"
                                                                                value={driver}
                                                                                onChange={handleChange}
                                                                                label="Select Driver"
                                                                            >
                                                                                <MenuItem value="">
                                                                                    <em>All Drivers</em>
                                                                                </MenuItem>

                                                                                {
                                                                                    AllDriver.map((data) => {
                                                                                        return (
                                                                                            <MenuItem value={data.driver_id}>{data.dName}</MenuItem>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Select>

                                                                        </FormControl>
                                                                        <FormControl variant="standard" sx={{ minWidth: '100%' }}>
                                                                            <InputLabel id="demo-simple-select-standard-label">Select Vehicle</InputLabel>
                                                                            <Select
                                                                                labelId="demo-simple-select-standard-label"
                                                                                id="demo-simple-select-standard"
                                                                                value={vehicle}
                                                                                onChange={handleChange1}
                                                                                label="Select Vehicle"
                                                                            >
                                                                                <MenuItem value="">
                                                                                    <em>All Vehicles</em>
                                                                                </MenuItem>
                                                                                {
                                                                                    vehicleData.map((data) => {
                                                                                        return (
                                                                                            <MenuItem value={data.vehicle_id}>{data.vehicleType}----{data.vehicleCapacity} </MenuItem>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Select>
                                                                        </FormControl>
                                                                    </DialogContent>
                                                                    <DialogActions>
                                                                        {
                                                                            !isConfirming && (
                                                                                <Button variant='contained' disabled={true}>Confirming...</Button>
                                                                            )
                                                                        }
                                                                        {
                                                                            isConfirming && (
                                                                                <Button style={{ backgroundColor: '#12124e' }} variant='contained' onClick={() => confirmBid(data.send_bids_id, data.email, data.startLocation, data.endLocation)}>Confirm</Button>
                                                                            )
                                                                        }
                                                                        <Button variant='contained' onClick={() => setOpen10(false)}>Cancel</Button>
                                                                    </DialogActions>
                                                                </Dialog>
                                                            </div>

                                                            <div>
                                                                <Button disabled={true} className='bidsBtn' onClick={() => {
                                                                    setOpen(true)
                                                                }} style={{ color: 'lightgray', border: '2px solid lightgray', position: 'absolute', right: '1.3rem', top: '0.6rem' }} variant='outlined' >Expired</Button>

                                                            </div>


                                                            <img style={{ position: 'absolute', bottom: 1, right: 3, width: '89px' }} width='150px' src='https://img.freepik.com/free-vector/auction-house-abstract-concept-vector-illustration-residential-commercial-property-auction-buy-sell-assets-online-exclusive-bid-consecutive-biddings-business-auctions-abstract-metaphor_335657-1956.jpg?size=626&ext=jpg&ga=GA1.1.415759594.1681714138&semt=robertav1_2_sidr' />
                                                        </Item>
                                                    </Grid>
                                                )
                                            })
                                        )
                                    }{
                                        (bids.length === 0) && (
                                            <Grid item xs={12}>
                                                <Item style={{ height: 87 + 'vh', alignItems: 'center' }}>
                                                    <div className='card' style={{ height: 85.2 + 'vh', alignItems: 'center', boxShadow: 'none', justifyContent: 'center', border: 'none' }}>
                                                        <img width='150px' src='https://img.freepik.com/free-vector/auction-house-abstract-concept-vector-illustration-residential-commercial-property-auction-buy-sell-assets-online-exclusive-bid-consecutive-biddings-business-auctions-abstract-metaphor_335657-1956.jpg?size=626&ext=jpg&ga=GA1.1.415759594.1681714138&semt=robertav1_2_sidr' />
                                                        <h3>No Expired Bids</h3>
                                                    </div>
                                                </Item>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </div>
                        )
                    }
                    {
                        isBidsforMonth && (
                            <div className='col-12 mx-auto ps-3 pe-3' >
                                <Grid md={12} >
                                    <Item className='card' style={{ position: 'fixed', top: 0, left: 0, right: 0, backgroundColor: 'white', marginBottom: 13, justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', border: 'none', width: '100%', height: '4rem', borderRadius: '0.003rem', zIndex: 100, borderBottom: '0.5px solid #30d0fc', paddingRight: 15 }} >
                                        <div style={{ boxShadow: 'none', border: 'none', flexDirection: 'row', position: 'absolute', left: '17.6rem' }} ><h4 style={{ fontWeight: '900', color: '#30d0fc', position: 'relative', top: '0.14rem', left: -3, zIndex: 100 }}>Filter Bids By Date</h4></div>
                                        <div className='card' style={{ backgroundColor: 'white', border: 'none', width: '18rem', boxShadow: 'none', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <FaBars color='white' style={{ cursor: 'pointer' }} onClick={() => setOpen(!open)} size={33} />
                                        </div>
                                        <div style={{ border: 'none', height: 46, width: 46, borderRadius: 35, border: '0.8px solid #30d0fc', justifyContent: 'center', alignItems: 'center', boxShadow: 'none' }} className='card'>
                                            <div style={{ border: 'none', height: 42, width: 42, borderRadius: 35, backgroundColor: 'lightgray', justifyContent: 'center', alignItems: 'center', boxShadow: 'none' }} className='card'>
                                                <Button
                                                    ref={anchorRef}
                                                    id="composition-button"
                                                    aria-controls={open6 ? 'composition-menu' : undefined}
                                                    aria-expanded={open6 ? 'true' : undefined}
                                                    aria-haspopup="true"
                                                    onClick={handleToggle}
                                                    style={{ color: 'gray' }}
                                                >
                                                    <FaUser color='gray' size={16}></FaUser>
                                                </Button>
                                            </div>
                                        </div>
                                    </Item>
                                    <Stack direction="row" spacing={2}>
                                        <div>
                                            <Popper
                                                open={open6}
                                                anchorEl={anchorRef.current}
                                                role={undefined}
                                                style={{ zIndex: 1001 }}
                                                placement="bottom-start"
                                                transition
                                                disablePortal
                                            >
                                                {({ TransitionProps, placement }) => (
                                                    <Grow
                                                        {...TransitionProps}
                                                        style={{
                                                            transformOrigin:
                                                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                                                        }}
                                                    >
                                                        <Paper>
                                                            <ClickAwayListener onClickAway={handleClose6}>
                                                                <MenuList
                                                                    autoFocusItem={open}
                                                                    id="composition-menu"
                                                                    aria-labelledby="composition-button"
                                                                    onKeyDown={handleListKeyDown}
                                                                >
                                                                    <MenuItem onClick={() => {
                                                                        setIsDashboard(false)
                                                                        setIsProfile(false)
                                                                        setIsSuccessFulBids(false)
                                                                        setIsUpdateKyc(false)
                                                                        setIsBidsForMonth(false)
                                                                        setisCurrentBids(false)
                                                                        setIsCloseBids(false)
                                                                        setIsTransit(false)
                                                                        setIsprfileItem(true)
                                                                    }}>Profile</MenuItem>
                                                                    <MenuItem onClick={() => {
                                                                        navigate('/')
                                                                        sessionStorage.removeItem('key')
                                                                    }} style={{ color: 'red' }} ><FaSignOutAlt />Logout</MenuItem>
                                                                </MenuList>
                                                            </ClickAwayListener>
                                                        </Paper>
                                                    </Grow>
                                                )}
                                            </Popper>
                                        </div>
                                    </Stack>
                                </Grid>
                                <Grid md={12} sx={{ mt: 14 }} >
                                    <Item style={{ marginBottom: 8.7, mt: 10 }}>
                                        <h4>Select Bid date</h4>
                                        <FaDotCircle color='#020222' />

                                        <input onChange={(e) => setMonthDate(e.target.value)} type='date' style={{ border: 'none', outline: 'none', width: '70%', backgroundColor: ' ', paddingLeft: 20 }} placeholder='Origine route location' />

                                        <div style={{ borderBottom: '2px solid #30d0fc', marginTop: 10, marginBottom: 10, width: '93%', marginLeft: 12 }} ></div>

                                        <button onClick={(date) => bidForMonth(monthDate)} style={{ height: '2.6rem', borderRadius: '0.2rem', backgroundColor: '#30d0fc', color: 'white', border: 'none', width: '9rem' }} >Find</button>
                                    </Item>
                                </Grid>
                                <Grid container spacing={2} sx={{ padding: 0, pt: 1 }}>
                                    {
                                        (monthBid.length > 0) && (
                                            monthBid.map((data) => {
                                                let isSuccessBids = ''
                                                if (data.isSuccessBids === 1) {
                                                    isSuccessBids = true
                                                } else {
                                                    isSuccessBids = false
                                                }
                                                return (
                                                    <Grid item md={6} xs={12}  >
                                                        <Item style={{ position: 'relative' }}>
                                                            <Typography style={{ fontSize: 10, marginLeft: '0.1rem' }}>Bid Id: {data.send_bids_id}</Typography>
                                                            <p style={{ position: 'relative', top: '1.8rem' }}><span style={{ color: '#232363' }}><FiberManualRecordIcon /> From: </span> {data.startLocation}</p>
                                                            <div style={{ height: '2.3rem', borderLeft: '1px solid #232363', position: 'relative', left: '0.6rem' }} ></div>
                                                            <p style={{ position: 'relative', top: '-0.9rem' }}><span style={{ color: '#232363' }}>  <FaMapMarker color='gold' /> To: </span> {data.endLocation}</p>
                                                            <p style={{ position: 'relative', top: '-0.9rem' }}><span style={{ color: 'gray', fontSize: 14, fontWeight: 'bold', }}> Bid for the <FaTruck size={18} />truck </span>:  <span style={{ fontWeight: 'bold', fontSize: 16 }} >{data.vehicle}</span></p>
                                                            <p style={{ position: 'relative', top: '-2.3rem' }}><span style={{ color: 'gray', fontSize: 14, fontWeight: 'bold', }}> Capacity </span>:  <span style={{ fontWeight: 'bold', fontSize: 16 }} >{data.capacity}</span></p>
                                                            <div style={{ position: 'relative', }} className='d-flex'>
                                                                <p style={{ position: 'relative', fontSize: 19, marginRight: 30 }}><span style={{ color: '#232363', fontSize: 19 }}>Material: </span> {data.material}</p>
                                                                <p style={{ position: 'relative', fontSize: 19, marginRight: 30 }}><span style={{ color: '#232363', fontSize: 19 }}>Number Of Vehicle: </span> {data.vehicleNum} Vehicle</p>
                                                            </div>
                                                            <img style={{ position: 'absolute', bottom: 1, right: 3, width: '89px' }} width='150px' src='https://img.freepik.com/free-vector/auction-house-abstract-concept-vector-illustration-residential-commercial-property-auction-buy-sell-assets-online-exclusive-bid-consecutive-biddings-business-auctions-abstract-metaphor_335657-1956.jpg?size=626&ext=jpg&ga=GA1.1.415759594.1681714138&semt=robertav1_2_sidr' />
                                                        </Item>
                                                    </Grid>
                                                )
                                            })
                                        )
                                    }
                                </Grid>
                                {
                                    monthBid.length === 0 && (
                                        <Grid container spacing={2} sx={{ padding: 0, pt: 1 }}>
                                            <Grid item xs={12}>
                                                <Item style={{ height: 87 + 'vh', alignItems: 'center' }}>
                                                    <div className='card' style={{ height: 85.2 + 'vh', alignItems: 'center', boxShadow: 'none', justifyContent: 'center', border: 'none' }}>
                                                        <img width='150px' src='https://img.freepik.com/free-vector/auction-house-abstract-concept-vector-illustration-residential-commercial-property-auction-buy-sell-assets-online-exclusive-bid-consecutive-biddings-business-auctions-abstract-metaphor_335657-1956.jpg?size=626&ext=jpg&ga=GA1.1.415759594.1681714138&semt=robertav1_2_sidr' />
                                                        <h3>Bids show by your selected date</h3>
                                                    </div>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </div>
                        )
                    }
                    {
                        isTransit && (

                            <Transit transit={transit} />

                        )
                    }
                </Box>

            </Box >
            <div>
                <Dialog
                    open={searchModal}
                    onClose={() => setSearchModal(true)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='lg'
                >
                    <DialogContent style={{ padding: 20 }}>
                        <div className='card' style={{ height: '4.8rem', width: '67rem', flexDirection: 'row', alignItems: 'center', borderRadius: '0.1rem' }} >
                            <FaWindowClose onClick={() => setSearchModal(false)} style={{ fontSize: 42, color: 'lightgray', marginLeft: 10, cursor: 'pointer' }} />
                            <h4 style={{ marginTop: 9.6, marginLeft: 7 }}>Bid Details</h4>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
            {
                seacrhWait && (
                    <SimpleBackdrop />
                )
            }
            <div>
                <Dialog open={open18} onClose={handleClose18}>
                    <DialogTitle>Subscribe</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            To subscribe to this website, please enter your email address here. We
                            will send updates occasionally.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Email Address"
                            type="email"
                            fullWidth
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose18}>Cancel</Button>
                        <Button onClick={handleClose18}>Subscribe</Button>
                    </DialogActions>
                </Dialog>
            </div>
            {
                acceptedLoading && (
                    <SimpleBackdrop />
                )
            }
            {
                (!sessionStorage.getItem('isPolicy1') || !isPolicy) && (
                    <div className='card shadow' style={{ position: 'fixed', left: '13rem', right: '13rem', bottom: '0.2rem', backgroundColor: 'white', zIndex: 100002, border: 'none', borderRadius: '0.3rem', padding: 17 }} >
                        <Typography>We use cookies to ensure you have the best browsing experience on our website. By using our site, you acknowledge that you have read and understood our  <span style={{ textDecoration: 'underline', color: 'blueviolet' }} >Cookie Policy</span> & <span style={{ textDecoration: 'underline', color: 'blueviolet' }} >Privacy Policy</span> </Typography>
                        <Button style={{ position: 'absolute', right: 83, color: 'red', bottom: 10, width: '7rem' }} onClick={() => window.location.reload()}>Cancel</Button>
                        <Button style={{ position: 'absolute', right: 4, color: '#0d0d4a', bottom: 10, width: '7rem' }} onClick={() => policyValidation()} >Accept</Button>
                    </div>
                )
            }
            {
                (!sessionStorage.getItem('isPolicy1') || !isPolicy) && (
                    <div className='card' style={{ position: 'fixed', left: '0rem', right: '0rem', bottom: '0rem', backgroundColor: 'black', top: 0, opacity: '0.5', zIndex: 100000 }} ></div>
                )
            }
        </div>
    ) : (
        <SimpleBackdrop />
    )
}

const listStyle3 = {
    background: '#fafa00',
    backgroundColor: 'red'
}