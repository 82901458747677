import axios from 'axios'
import React, { useEffect } from 'react'
import { GoArrowUp } from 'react-icons/go'
import './index.css'
import { FaAccusoft, FaAdn, FaCheckCircle, FaUsers, FaWindowClose } from 'react-icons/fa'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'Auto',
    color: theme.palette.text.secondary,
}));

const CustomerDashboard = () => {

    const [currentBids, setCurrentBids] = React.useState([])

    const curBidsRes = () => {
        const email = sessionStorage.getItem('email')
        axios.post('https://pmapi.emedha.in/customer/bids-status/current', {
            email
        }).then((res) => {
            setCurrentBids(res.data.res.length)
        })
    }

    const [acceptBids, setAcceptBids] = React.useState([])


    const accBidsRes = () => {
        const email = sessionStorage.getItem('email')
        axios.post('https://pmapi.emedha.in/customer/bids-status/accept', {
            email
        }).then((res) => {
            setAcceptBids(res.data.res.length)
        })
    }

    React.useEffect(() => {
        accBidsRes()
    }, [])

    React.useEffect(() => {
        curBidsRes()
    }, [])

    const [succBids, setSuccBids] = React.useState([])


    const succRes = () => {
        const email = sessionStorage.getItem('email')
        axios.post('https://pmapi.emedha.in/customer/bids-status/successful', {
            email
        }).then((res) => {
            setSuccBids(res.data.res.length)
        })
    }

    useEffect(() => {
        succRes()
    }, [])

    const [expBids, setExpBids] = React.useState([])


    const expRes = () => {
        const email = sessionStorage.getItem('email')
        axios.post('https://pmapi.emedha.in/customer/bids-status/expired', {
            email
        }).then((res) => {
            setExpBids(res.data.res.length)
        })
    }

    useEffect(() => {
        expRes()
    }, [])


    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid style={{ height: '10rem', marginBottom: '0.8rem', }} item xs={3}>
                    <Item elevation={1} sx={{ height: '9.5rem', position: 'relative' }}>
                        <h1 style={{ fontSize: 16, marginTop: 14, fontWeight: 'bold', color: '#0d0d4a' }} >
                            Live Bids
                        </h1>
                        <div style={{  position: 'absolute', right: '0.8rem', top: '0.7rem', padding: '0.4rem', borderRadius: '0.3rem' }}>
                            <FaAccusoft fontSize={28} style={{ position: 'relative' }} color='#67aabd' />
                        </div>
                        <p style={{ fontSize: 29, color: 'blue', fontWeight: 'bold' }}>{currentBids}</p>
                        <h1 style={{ fontSize: 11, color: 'gray', textTransform: 'uppercase', fontWeight: 'bold', marginTop: '1.2rem' }}>
                            All Current Bids Valid For 180min
                        </h1>
                    </Item>
                </Grid>
                <Grid style={{ height: '10rem', marginBottom: '0.8rem' }} item xs={3}>
                    <Item elevation={1} sx={{ height: '9.5rem', position: 'relative' }}>
                        <h1 style={{ fontSize: 16, marginTop: 14, fontWeight: 'bold', color: '#0d0d4a' }} >
                            Failed Bids
                        </h1>
                        <div style={{  position: 'absolute', right: '0.8rem', top: '0.7rem', padding: '0.4rem', borderRadius: '0.3rem' }}>
                            <FaAdn fontSize={28} style={{ position: 'relative' }} color='gold' />
                        </div>
                        <p style={{ fontSize: 29, color: 'blue', fontWeight: 'bold' }}>{acceptBids}</p>
                        <h1 style={{ fontSize: 11, color: 'gray', textTransform: 'uppercase', fontWeight: 'bold', marginTop: '1.2rem' }}>
                            Your total failed bids
                        </h1>
                    </Item>
                </Grid>
                <Grid style={{ height: '10rem', marginBottom: '0.8rem' }} item xs={3}>
                    <Item elevation={1} sx={{ height: '9.5rem', position: 'relative' }}>
                        <h1 style={{ fontSize: 16, marginTop: 14, fontWeight: 'bold', color: '#0d0d4a' }} >
                            Successful Bids
                        </h1>
                        <div style={{  position: 'absolute', right: '0.8rem', top: '0.7rem', padding: '0.4rem', borderRadius: '0.3rem' }}>
                            <FaCheckCircle fontSize={28} style={{ position: 'relative' }} color='green' />
                        </div>
                        <p style={{ fontSize: 29, color: 'blue', fontWeight: 'bold' }}>{succBids}</p>
                        <h1 style={{ fontSize: 11, color: 'gray', textTransform: 'uppercase', fontWeight: 'bold', marginTop: '1.2rem' }}>
                            Total Successful Delivery
                        </h1>
                    </Item>
                </Grid>
                <Grid style={{ height: '10rem', marginBottom: '0.8rem' }} item xs={3}>
                    <Item elevation={1} sx={{ height: '9.5rem', position: 'relative' }}>
                        <h1 style={{ fontSize: 16, marginTop: 14, fontWeight: 'bold', color: '#0d0d4a' }} >
                            Expired Bids
                        </h1>
                        <div style={{  position: 'absolute', right: '0.8rem', top: '0.7rem', padding: '0.4rem', borderRadius: '0.3rem' }}>
                            <FaWindowClose fontSize={28} style={{ position: 'relative' }} color='rgb(244, 90, 90)' />
                        </div>
                        <p style={{ fontSize: 29, color: 'blue', fontWeight: 'bold' }}>{expBids}</p>
                        <h1 style={{ fontSize: 11, color: 'gray', textTransform: 'uppercase', fontWeight: 'bold', marginTop: '1.2rem' }}>
                            Here all bids is expired
                        </h1>
                    </Item>
                </Grid>
            </Grid>
        </Box>
    )

}


export default CustomerDashboard