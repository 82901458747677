import axios from 'axios'
import React, { useEffect, useState } from 'react'

const DashShowProfile = () => {

    const [profile, setProfile] = useState('0')
    const userProfile = () => {
        const email = sessionStorage.getItem('email')
        const type = sessionStorage.getItem('type')
        axios.post('https://pmapi.emedha.in/profile/complation', {
            email,
            type
        }).then((res) => {
            setProfile(res.data.profile)
        })
    }


    useEffect(() => {
        userProfile()
    }, [])


    return (
        <div className='mb-3 mt-2' >
            <span style={{ color: '#12124e', fontSize: 14 }} >{profile}% Kyc Completion</span>
            <div class="progress">
                <div class="progress-bar progress-bar-striped" role="progressbar" style={{ width: profile + '%' }} aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>
    )
}

export default DashShowProfile