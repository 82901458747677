import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import { FaTruck, FaUser } from 'react-icons/fa';

export default function TimelineKyc({ isAv, isAg }) {
    return (
        <Timeline position="alternate">
            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0', backgroundColor: 'white' }}
                    align="right"
                    variant="body2"
                    color="white"
                >

                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot sx={{ bgcolor: 'white' }}>
                        <img src='https://i.pinimg.com/originals/76/05/2e/76052e4c0db271656da0cf58d826543e.jpg' width='30px' />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                        Aadhar Verification
                    </Typography>
                    {
                        isAv && <Typography color='rgb(30, 225, 30)' >verified<img src='https://th.bing.com/th/id/OIP.vg8_-0X783jk--lcR8S3EAHaHa?w=183&h=183&c=7&r=0&o=5&dpr=2.5&pid=1.7' width='20px' /></Typography>
                    }
                    {
                        !isAv && <Typography color='red' >Not Verified</Typography>
                    }
                </TimelineContent>
            </TimelineItem>
            
            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    variant="body2"
                    color="text.secondary"
                >
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot sx={{ bgcolor: 'white' }}>
                        <img src='https://img.freepik.com/free-vector/tax-form-concept-illustration_114360-7378.jpg?size=626&ext=jpg&ga=GA1.2.415759594.1681714138&semt=robertav1_2_sidr' width='30px' height='30px' />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                        Gst Verification
                    </Typography>
                    {
                        isAg && <Typography color='rgb(30, 225, 30)' >verified<img src='https://th.bing.com/th/id/OIP.vg8_-0X783jk--lcR8S3EAHaHa?w=183&h=183&c=7&r=0&o=5&dpr=2.5&pid=1.7' width='20px' /></Typography>
                    }
                    {
                        !isAg && <Typography color='red' >No Verified</Typography>
                    }
                </TimelineContent>
            </TimelineItem>
        </Timeline>
    );
}