import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FaMapMarker, FaUserCircle } from 'react-icons/fa';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'auto',
    color: theme.palette.text.secondary,
}));

export default function Transit({ transit }) {

    return (

 
        <Box sx={{ flexGrow: 1, pt: 9, paddingLeft: 7, paddingRight: 7 }}>
            <h4>Transit board</h4>
            <Grid container spacing={2}>
                {
                    transit.map((data) => {
                        return (
                            <Grid item xs={6}>
                                <Item style={{ position: 'relative' }}>
                                    <Typography style={{ marginTop: 5, marginLeft: 5 }}>Loading Date: {data.loadingDate}</Typography>
                                    <Typography style={{ marginTop: 10, marginLeft: 5 }} >Id: {data.acceptedBids_id}</Typography>
                                    <Typography style={{ position: 'relative', top: '0.4rem' }}><span style={{ color: '#232363' }}><FiberManualRecordIcon /> From: </span> {data.fromRoute}</Typography>
                                    <div style={{ height: '1.8rem', borderLeft: '2px solid lightgray', position: 'relative', left: '0.6rem', top: 4, borderLeftStyle: 'dotted' }} ></div>
                                    <Typography style={{ marginTop: '0.1rem' }}><span style={{ color: '#232363' }}>  <FaMapMarker color='gold' /> To: </span> {data.toRoute}</Typography>
                                    <Typography style={{ marginTop: 10, marginLeft: 5 }} >Amount: {data.money}</Typography>
                                    <Typography style={{ marginTop: 5, marginLeft: 5 }}>Number Of Vehicle: {data.vehicleReqNum} vehicle</Typography>
                                    <img style={{ width: '160px', position: 'absolute', right: '4px', top: '4px', opacity: '0.6' }} src={data.LoadinImage} />
                                    <div style={{ width: '160px', position: 'absolute', right: 4, top: 4, border: 'none', justifyContent: 'center', alignItems: 'center' }} className='card' >
                                        <img style={{ width: '160px', opacity: '0.6', }} src={data.LoadinImage} ></img>
                                        <Typography style={{position:'absolute',top:12,color:'gray',fontSize:12.5}}>Loading image</Typography>
                                    </div>
                                    <button style={{ height: '2.3rem', borderRadius: '0.2rem', backgroundColor: '#12124e', width: '7rem', border: 'none', position: 'absolute', bottom: 10, right: 8, color: 'white', fontWeight: '500' }} >Confirm</button>
                                </Item>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box>
    );
}