 

import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { FaExclamationTriangle, FaLock, FaTimes } from 'react-icons/fa'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Button from '@mui/material/Button';
import logo from '../../../GlobleImageContainer/images/PrimeMoovers.png'
import SimpleBackdrop from '../../../BackDrop/BackDrop';


const ChangePassword = () => {


    const [password, setPassword] = useState('')
    const [cPassword, setCPassword] = useState('')
    const [cur_pass, set_cur_pass] = useState('')
    const [isBackDrop, setIsBackDrop] = useState(false)

    console.log(password,cPassword)

    const navigate = useNavigate()

    const { client_id, userType } = useParams()

    const change_password = () => {
        
        axios.post('https://pmapi.emedha.in/password/change', {
            client_id,
            password,
            userType
        }).then((res) => {
            if (res.data.status === 200) {
                alert('Dear user Your password Successfully Change')
                navigate('/')
                setIsBackDrop(false)
            }
        })
    }

    const [error, setError] = useState(false)

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            if (cur_pass !== sessionStorage.getItem('cur_pass')) {
                setError('Please Enter valid Current password ')
            } else {
                if (password !== cPassword) {
                    setError('Confirm password is Not Matched')
                } else {
                    setIsBackDrop(true)
                    change_password()
                }
            }
        }
        setValidated(true);
    };


    return (
        <div>
            <div className='p-3 mt-5'>
                {
                    (error !== false) && (
                        <center>
                            <div className='error1 mb-4' >
                                <FaTimes onClick={() => setError(false)} style={{ position: 'absolute', top: '0.4rem', right: '0.4rem', cursor: 'pointer' }} />
                                <p className='text-start'><FaExclamationTriangle className='text-danger' />  <span style={{ position: 'relative', top: '0.13rem', left: '0.2rem' }}>{error}</span></p>
                            </div>
                        </center>
                    )
                }
                <div className='col-xl-6 mx-auto mt-5' >
                    <div className='row signup-container justify-content-center' >
                        <div className='col-lg-6' >
                            <center>
                                <img src={logo} style={{ width: '150px' }} />
                                <br />
                                <br />
                                <br />
                                <h4 className='text-center' style={{ fontWeight: '800', color: 'black' }}>Welcome To  <span style={{ color: '#0d0a46', fontSize: '2rem' }}>Prime</span> <span style={{ fontSize: '2rem' }} className='text-info'>Moovers</span></h4>
                                <br />
                                <p style={{ fontSize: '1.2rem', color: '#0d0a46' }}>Set Your New Login password and login</p>
                            </center>
                            <Form noValidate validated={validated} onSubmit={handleSubmit} >
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                                        <Form.Label>Current Password</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><FaLock /></InputGroup.Text>
                                            <Form.Control
                                                type="password"
                                                placeholder="Current Password"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                style={{ padding: '1.1rem' }}
                                                onChange={(e) => set_cur_pass(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please choose a username.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                                        <Form.Label>New Password</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><FaLock /></InputGroup.Text>
                                            <Form.Control
                                                type="password"
                                                placeholder="New Password"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                style={{ padding: '1.1rem' }}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please choose a username.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><FaLock /></InputGroup.Text>
                                            <Form.Control
                                                type="password"
                                                placeholder="Confirm Password"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                style={{ padding: '1.1rem' }}
                                                onChange={(e) => setCPassword(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please choose a username.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Row>
                                <br />
                                <Button variant="contained" type='submit' style={{ backgroundColor: '#12124e', padding: '0.6rem', width: '100%', color: 'white' }}>Submit</Button>
                            </Form>
                        </div>
                        <div className='col-lg-6  uinfo login-color-col' >
                            <div style={{ marginTop: '10rem' }}>
                                <h2 className='text-center' style={{ fontWeight: '800', color: 'white' }}>Our trucks save you bucks</h2>
                                <p className='text-center text-white' style={{ fontSize: '1.1rem' }}>Find truck loads online PAN-India and maximize the profits of your transport business with return loads for all your trucks!</p>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isBackDrop && (
                        <SimpleBackdrop />
                    )
                }
            </div>
        </div>
    )
}

export default ChangePassword