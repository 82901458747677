
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import moment from 'moment'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import KycTable from '../kyctable/KycTable';
import Button from '@mui/material/Button';
import { FaBusAlt, FaPlusCircle, FaUpload, FaWindowClose } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { Typography } from '@mui/material';

const LorryBrokerVehicle = () => {

    const navigate = useNavigate()
    useEffect(() => {
        if (!sessionStorage.getItem('key')) {
            navigate('/user-login')
        }
    })

    const [vehicle, setVehicle] = useState('')
    const [rPermit, setRPermit] = useState()
    const [fCertificate, setFCertificate] = useState('')
    const [vehicleCapacity, setVehicleCapacity] = useState('')
    const [regNum, setRegNum] = useState('')
    const [insDet, setInsDet] = useState('')
    const [isCapacity, setIsCapacity] = useState(false)
    const [dateOfExp, setDateOfExp] = useState('')
    const [dateOfIss, setDateOfIssue] = useState('')
    const [insName, setInsName] = useState('')
    const [rcCopy, setRcCopy] = useState('')
    const [cerCopy, setCerCopy] = useState('')
    const [capacity, setCapacity] = useState([])

    const check1 = ((moment(dateOfExp).year() - moment(dateOfIss).year()) >= 2)

    const check2 = (moment(dateOfIss).month() + 1) < (moment(dateOfExp).month() + 1)
    const check3 = moment(dateOfIss).date() < moment(dateOfExp).date()
    const check4 = (moment(dateOfExp).year() > moment(dateOfIss).year())

    const check5 = (moment(dateOfIss).month() + 1) <= (moment(dateOfExp).month() + 1)
    const check6 = moment(dateOfIss).date() <= moment(dateOfExp).date()
    const check7 = ((moment(dateOfExp).year() - moment(dateOfIss).year()) === 1)

    const [insDateValidation, setInsDateValidation] = useState(true)

    const dateValidation = () => {
        if (check1) {
            setInsDateValidation(true)
            addVehiclesBroker()
        } else {
            if (check2 && check3 && check4) {
                setInsDateValidation(true)
                addVehiclesBroker()

            } else {
                if (check5 && check6 && check7) {
                    setInsDateValidation(true)
                    addVehiclesBroker()
                } else {
                    setInsDateValidation(false)
                }
            }
        }
    }

    const [Validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            dateValidation()
        }
        setValidated(true);
    };

    const addVehiclesBroker = () => {

        const client_id = sessionStorage.getItem('client_id')
        const formData = new FormData();
        formData.append('fcrffile', cerCopy);
        formData.append('insfile', insDet);
        formData.append('rcfile', rcCopy);
        formData.append('capacity', vehicleCapacity);
        formData.append('regNum', regNum);
        formData.append('vehicle', vehicle);
        formData.append('fCertificate', fCertificate);
        formData.append('rPermit', rPermit);
        formData.append('insName', insName);
        formData.append('dateOfExp', dateOfExp);
        formData.append('dateOfIss', dateOfIss);
        formData.append('email', sessionStorage.getItem('email'))
        formData.append('id', client_id)
        formData.append('userType', 'LorryBroker')


        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        axios.post('https://pmapi.emedha.in/vehicle/data/add', formData, config).then((res) => {
            if (res.data.status === 200) {
                setIsAddDrivers(false)
                vehicleGet()
                setIsCapacity(false)
                setCerCopy('')
                setInsDet('')
                setRcCopy('')
                setVehicleCapacity('')
                setRegNum('')
                setVehicle('')
                setFCertificate('')
                setRPermit('')
                setInsName('')
                setDateOfExp('')
                setDateOfIssue('')
                Swal.fire({
                    title: "Successful..!",
                    text: "Vehicle successfully added...!",
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Cancel",
                }).then((result) => {
                    if (result.value) {
                        setIsAddDrivers(false)
                        vehicleGet()
                        setIsCapacity(false)
                        setCerCopy('')
                        setInsDet('')
                        setRcCopy('')
                        setVehicleCapacity('')
                        setRegNum('')
                        setVehicle('')
                        setFCertificate('')
                        setRPermit('')
                        setInsName('')
                        setDateOfExp('')
                        setDateOfIssue('')
                    }
                });
            }
            if (res.data.status === 409) {
                Swal.fire({
                    title: "Error..!",
                    text: "Vehicle is already exist",
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Cancel",
                }).then((result) => {
                    if (result.value) {
                        setIsAddDrivers(false)
                        vehicleGet()
                        setIsCapacity(false)
                        setCerCopy('')
                        setInsDet('')
                        setRcCopy('')
                        setVehicleCapacity('')
                        setRegNum('')
                        setVehicle('')
                        setFCertificate('')
                        setRPermit('')
                        setInsName('')
                        setDateOfExp('')
                        setDateOfIssue('')
                    }
                });
            }
        }).catch(() => {
            alert('Data is Already exist')
        })


    }

    const selectVehicleWithCapacity = (vehicle) => {
        setVehicle(vehicle)
        axios.post('https://pmapi.emedha.in/vehicle/data/capacity', {
            vehicle
        }).then((res) => {
            setIsCapacity(true)
            setCapacity(res.data.result)

        })
    }


    const [allVehicle, setAllVehicle] = useState([])

    const vehicleGet = () => {
        const client_id = sessionStorage.getItem('client_id')
        const type = 'LorryBroker'
        axios.post('https://pmapi.emedha.in/vehicle/data/show', {
            client_id,
            type
        }).then((res) => {
            setAllVehicle(res.data.result)
        })
    }

    useEffect(() => {
        vehicleGet()
    }, [])

    const [isAddDrivers, setIsAddDrivers] = useState(false)
    const [isAddNewVeh, setIsAddNewVeh] = useState(false)
    const [isShowVeh, setIsShowVeh] = useState(true)

    return (
        <div>
            <div  >
                {
                    (allVehicle.length !== 0) && (
                        <div>
                            <Button variant="contained" onClick={() => {
                                setIsAddDrivers(true)
                                setIsAddNewVeh(true)
                                setIsShowVeh(false)
                            }} size='small' style={{ backgroundColor: isAddNewVeh ? '#12124e' : 'white', width: '9rem', color: !isAddNewVeh ? '#12124e' : 'white' }}><FaPlusCircle size={16} style={{ position: 'relative', top: '-0.1rem', marginRight: '0.2rem' }} />Add Vehicle</Button>
                            <Button size='small' onClick={() => {
                                setIsAddDrivers(false)
                                setIsAddNewVeh(false)
                                setIsShowVeh(true)
                            }} variant="contained" style={{ backgroundColor: isShowVeh ? '#12124e' : 'white', width: '9rem', color: isAddNewVeh ? '#12124e' : 'white', marginLeft: 4 }}><FaBusAlt size={16} style={{ position: 'relative', top: '-0.1rem', marginRight: '0.2rem' }} />Vehicles</Button>
                        </div>
                    )
                }
                {
                    ((allVehicle.length === 0) || isAddDrivers) && (
                        <div className='col-12  mx-auto'>
                            <br />
                            <Typography style={{ marginTop: '1rem', fontSize: '1.3rem', color: '#12124e', fontWeight: 'bold' }}>Add Vehicle</Typography>
                            <Typography style={{ width: '100%', height: '0.09rem', backgroundColor: '#12124e', position: 'relative', top: '-0.2rem' }} ></Typography>
                            <br />
                            <Form noValidate validated={Validated} onSubmit={handleSubmit}>
                                <Row>
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Label>Select Vehicle Type</Form.Label>
                                        <Form.Select required value={vehicle} onChange={(e) => {
                                            selectVehicleWithCapacity(e.target.value)


                                        }} style={{ height: '52px', outline: 'none', border: '2px solid lightgray', paddingLeft: 10, borderRadius: '5px' }} aria-label="Default select example">
                                            <option >Select Vehicle Type</option>
                                            <option value='Auto'>Auto</option>
                                            <option value='Tractor'>Tractor</option>
                                            <option value='Container'>Container</option>
                                            <option value='Truck'>Truck</option>
                                            <option value='Tusker'>Tusker</option>
                                            <option value='Tanker'>Tanker</option>
                                        </Form.Select>
                                    </Form.Group>
                                    {
                                        isCapacity && (
                                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                <Form.Label>
                                                    Select Vehicle Capacity
                                                </Form.Label>
                                                <Form.Select style={{ height: '52px', outline: 'none', border: '2px solid lightgray', paddingLeft: 10, borderRadius: '5px' }} value={vehicleCapacity} onChange={(e) => {
                                                    setVehicleCapacity(e.target.value)

                                                }} aria-label="Default select example">
                                                    <option>Select Vehicle Capacity</option>


                                                    {
                                                        capacity.map((data) => {
                                                            return (

                                                                <option value={data.Capacity}>{data.Capacity}</option>

                                                            )
                                                        })
                                                    }



                                                </Form.Select>

                                            </Form.Group>
                                        )
                                    }
                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Form.Label>Registration Number</Form.Label>
                                        <Form.Control style={{ height: '52px', outline: 'none', border: '2px solid lightgray', paddingLeft: 10, borderRadius: '5px' }} onChange={(e) => {
                                            setRegNum(e.target.value)

                                        }} required placeholder='Registration number' />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid RC Number
                                        </Form.Control.Feedback>
                                    </Form.Group>



                                    <label>Insurance Details</label>

                                    <div>
                                        {
                                            !insDateValidation && (
                                                <div style={{ backgroundColor: 'rgb(248, 213, 213)', padding: 10, borderRadius: '0.2rem', marginTop: 16, position: 'relative' }} >
                                                    <FaWindowClose style={{ position: 'absolute', top: 4, right: 6, color: 'red', cursor: 'pointer' }} onClick={() => setInsDateValidation(true)} ></FaWindowClose>
                                                    <Typography className='text-danger'>Atleast 1 year Deffrence between in issue date and expire date </Typography>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control style={{ height: '52px', outline: 'none', border: '2px solid lightgray', paddingLeft: 10, borderRadius: '5px' }} type='text' onChange={(e) => {
                                            setInsName(e.target.value)

                                        }} required placeholder='Enter valid name' />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Name
                                        </Form.Control.Feedback>
                                    </Form.Group>


                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Form.Label>Date of issue</Form.Label>
                                        <Form.Control style={{ height: '52px', outline: 'none', border: '2px solid lightgray', paddingLeft: 10, borderRadius: '5px' }} value={dateOfIss} type='date' onChange={(e) => {
                                            setDateOfIssue(e.target.value)

                                            setInsDateValidation(true)
                                        }} required />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Date
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Form.Label >Date of expiry</Form.Label>
                                        <Form.Control style={{ height: '52px', outline: 'none', border: '2px solid lightgray', paddingLeft: 10, borderRadius: '5px' }} value={dateOfExp} type='date' onChange={(e) => {
                                            setDateOfExp(e.target.value)

                                            setInsDateValidation(true)
                                        }} required />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Date
                                        </Form.Control.Feedback>
                                    </Form.Group>




                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Form.Label>Fitness Certificate</Form.Label>
                                        <Form.Control style={{ height: '52px', outline: 'none', border: '2px solid lightgray', paddingLeft: 10, borderRadius: '5px' }} onChange={(e) => {
                                            setFCertificate(e.target.value)

                                        }} required placeholder='Fitness Certificate' />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Certificate
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Form.Label>Road Permit</Form.Label>
                                        <Form.Control style={{ height: '52px', outline: 'none', border: '2px solid lightgray', paddingLeft: 10, borderRadius: '5px' }} value={rPermit} onChange={(e) => {
                                            setRPermit(e.target.value)
                                        }} required placeholder='Road Permit' />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Data
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <div className='mt-2' >
                                        <Typography style={{ fontWeight: '500', fontSize: '19px', color: '#023A51', fontStyle: 'poppins', position: 'relative', top: 5 }} >Easy to verification</Typography>
                                        <Typography style={{color:'gray', fontSize:14}} >Add some more data to make your verification easy</Typography>
                                        <div className='d-flex justify-content-between' >

                                            <Form.Group className='mb-3' md="6" >
                                                <Typography style={{ fontWeight: '500', fontSize: '16px', color: '#023A51', fontStyle: 'poppins', position: 'relative', top: 12.1 }}>Upload Certificate Copy </Typography>
                                                {/* {(uplDlAdhar === '' || uplDlAdhar === 'error') ? '' : '(Image selected)'} */}
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    style={{ height: '100px', width: '267px', backgroundColor: 'white', position: 'relative', border: '2px solid lightgray', boxShadow: 'none' }}
                                                >
                                                    <FaUpload style={{ fontSize: 23, zIndex: 20, color: 'rgb(40, 251, 251)' }} />
                                                    <input
                                                        required
                                                        type="file"

                                                        hidden
                                                        onChange={(e) => {
                                                            setCerCopy(e.target.files[0])
                                                        }}
                                                    />
                                                </Button>
                                                {/* {
                                            uplDlAdhar === 'error' && (
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Valid DL Image .
                                                </Form.Control.Feedback>
                                            )
                                        } */}
                                            </Form.Group>

                                            <Form.Group className='mb-3' md="6" >
                                                <Typography style={{ fontWeight: '500', fontSize: '16px', color: '#023A51', fontStyle: 'poppins', position: 'relative', top: 12.1 }}>Upload INS Copy </Typography>
                                                {/* {(uplDlAdhar === '' || uplDlAdhar === 'error') ? '' : '(Image selected)'} */}
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    style={{ height: '100px', width: '267px', backgroundColor: 'white', position: 'relative', border: '2px solid lightgray', boxShadow: 'none' }}
                                                >
                                                    <FaUpload style={{ fontSize: 23, zIndex: 20, color: 'rgb(40, 251, 251)' }} />
                                                    <input
                                                        required
                                                        type="file"

                                                        hidden
                                                        onChange={(e) => {
                                                            setInsDet(e.target.files[0])
                                                        }}
                                                    />
                                                </Button>
                                                {/* {
                                            uplDlAdhar === 'error' && (
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Valid DL Image .
                                                </Form.Control.Feedback>
                                            )
                                        } */}
                                            </Form.Group>

                                            <Form.Group className='mb-3' md="6" >
                                                <Typography style={{ fontWeight: '500', fontSize: '16px', color: '#023A51', fontStyle: 'poppins', position: 'relative', top: 12.1 }}>Upload RC Copy</Typography>
                                                {/* {(uplDlAdhar === '' || uplDlAdhar === 'error') ? '' : '(Image selected)'} */}
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    style={{ height: '100px', width: '267px', backgroundColor: 'white', position: 'relative', border: '2px solid lightgray', boxShadow: 'none' }}
                                                >
                                                    <FaUpload style={{ fontSize: 23, zIndex: 20, color: 'rgb(40, 251, 251)' }} />
                                                    <input
                                                        required
                                                        type="file"

                                                        hidden
                                                        onChange={(e) => {
                                                            setRcCopy(e.target.files[0])
                                                        }}
                                                    />
                                                </Button>
                                                {/* {
                                            uplDlAdhar === 'error' && (
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Valid DL Image .
                                                </Form.Control.Feedback>
                                            )
                                        } */}
                                            </Form.Group>
                                        </div>

                                    </div>
                                </Row>
                                <button className='signupBtn'>Submit</button>
                            </Form>

                        </div>
                    )
                }
                {
                    ((allVehicle.length > 0 && !isAddDrivers)) && (
                        <div  >
                            <div style={{ marginTop: '-5rem' }}>
                                <KycTable type='LorryBroker' />
                            </div>
                        </div>
                    )
                }
            </div>

        </div>
    )
}

export default LorryBrokerVehicle