import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import 'react-circular-progressbar/dist/styles.css';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CreateBids from '../../../create bids/CreateBids';
import TopThreeLowestBids from '../TopThreeLowestBids/TopThreeLowestBids';
import DashShowProfile from '../../../DashBoardShowProfile/DashboardShowProfile';



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'auto',
    color: theme.palette.text.secondary,
}));


export default function FullWidthGrid() {


    const [value, setValue] = React.useState('2');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (

        <div className='container-fluid'>
            <Box sx={{ flexGrow: 1, p: 0, mt: 2,mb:4 }} className='ps-3 pe-3 pt-4' >
            <DashShowProfile/>
                <Grid container spacing={2}>
                    <Grid xs={12} md={12} >
                        <Item elevation={1} sx={{ height: 87 + 'vh', p: 2, overflowY: 'scroll' }} >
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                                            <Tab label="BIDS OFFER" value="2" />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="2">
                                        <Typography >
                                            <TopThreeLowestBids />
                                        </Typography>
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}