import React, { useEffect, useState } from 'react'
import { FaCheckCircle, FaEdit, FaEye, FaTrashAlt, FaUser, FaWindowClose } from 'react-icons/fa'
import axios from 'axios'
import SimpleBackdrop from '../../../../BackDrop/BackDrop'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function Licence({ id }) {

    console.log(id)

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [openViewModel, setOpenViewModel] = useState(false)

    const [data, setData] = useState([])
    const [isActionToVerify, setIsActionToVerify] = useState(false)
    const [localData, setLocalData] = useState([])
    const [citizenship, set_citizenship] = useState()
    const [dob, set_dob] = useState()
    const [doe, set_doe] = useState()
    const [doi, setDoi] = useState()
    const [father_or_husband_name, set_father_or_husband_name] = useState()
    const [gender, set_gender] = useState()
    const [initial_doi, set_initial_doi] = useState()
    const [license_number, set_license_number] = useState()
    const [name, setName] = useState()
    const [permanent_address, set_permanent_address] = useState()
    const [permanent_zip, set_permanent_zip] = useState()
    const [transport_doe, set_transport_doe] = useState()
    const [transport_doi, set_transport_doi] = useState()
    const [isVerify, setIsVerify] = useState(0)
    const [client_id, set_client_id] = useState()
    const [dlDataView, setDlDataView] = useState(true)
    const [isView, setIsView] = useState(false)
    const [isBackDrop, setIsBackDrop] = useState(false)

    const userWithDl = (client_id = id) => {
        const userType = 'LorryBroker'
        axios.post('https://pmapi.emedha.in/admin/user/all/drivers', {
            client_id,
            userType
        }).then((res) => {
            setData(res.data)
        })
    }

    const user_inserted_data = (client_id) => {
        const checkData = 'broker_driver_dl'
        axios.post('https://pmapi.emedha.in/admin/user/all/drivers', {
            client_id,
            checkData
        }).then((res) => {
            setIsActionToVerify(true)
            setIsView(false)
            setDlDataView(false)
            setLocalData(res.data)
            setIsVerify(1)
            set_client_id(client_id)
            setIsBackDrop(false)
        }).catch((err) => {
            console.log(err)
        })
    }

    const [checkIsVerify, setCheckIsVerify] = useState('')

    const DLVerify = () => {
        const userType = 'LorryBroker'
        if (window.confirm('Confirm To Verify Dl Number')) {
            axios.post('https://pmapi.emedha.in/admin/driver-dl/verification', {
                isVerify,
                client_id,
                checkIsVerify,
                userType
            }).then(() => {
                alert('Verified')
                setIsActionToVerify(false)
                userWithDl()
            })
        }

    }

    const dlVerification = (id_number, dlDob, id,client_id) => {
        setOpenViewModel(true)
        setIsBackDrop(true)
        setCheckIsVerify(client_id)
        const webApiUrl = 'https://kyc-api.surepass.io/api/v1/driving-license/driving-license'
        const tokenStr = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY3MzMyODQwMywianRpIjoiMmYzMmMzMmQtMjMwMS00ZDg1LTkwNzktODUxMzMwMWMzZTJhIiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LnByaW1lbW92ZXJzQHN1cmVwYXNzLmlvIiwibmJmIjoxNjczMzI4NDAzLCJleHAiOjE5ODg2ODg0MDMsInVzZXJfY2xhaW1zIjp7InNjb3BlcyI6WyJ3YWxsZXQiXX19.9u2NZdFGzWOXpt6UNq8wvtrxTljuZ8lkw4r_K2K6d7I'
        axios.post(webApiUrl, {
            id_number,
            dlDob
        },
            {
                headers: {
                    'Accept': 'application/json, text/plain',
                    'content-type': 'application/json',
                    "Authorization": `Bearer ${tokenStr}`
                }
            }).then((res) => {
                if (res.data.status_code === 200 && res.data.success) {
                    user_inserted_data(id)
                    set_citizenship(res.data.data.citizenship)
                    set_dob(res.data.data.dob)
                    set_doe(res.data.data.doe)
                    set_father_or_husband_name(res.data.data.father_or_husband_name)
                    set_gender(res.data.data.gender)
                    set_initial_doi(res.data.data.initial_doi)
                    set_license_number(res.data.data.license_number)
                    set_permanent_address(res.data.data.permanent_address)
                    set_permanent_zip(res.data.data.permanent_zip)
                    set_transport_doe(res.data.data.transport_doe)
                    set_transport_doi(res.data.data.transport_doi)
                }
            }).catch((err) => {
                console.log(err)
            })
    }

    const dlView = (id_number, dlDob) => {
        handleClickOpen()
        setOpenViewModel(true)
        setIsBackDrop(true)
        const webApiUrl = 'https://kyc-api.surepass.io/api/v1/driving-license/driving-license'
        const tokenStr = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY3MzMyODQwMywianRpIjoiMmYzMmMzMmQtMjMwMS00ZDg1LTkwNzktODUxMzMwMWMzZTJhIiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LnByaW1lbW92ZXJzQHN1cmVwYXNzLmlvIiwibmJmIjoxNjczMzI4NDAzLCJleHAiOjE5ODg2ODg0MDMsInVzZXJfY2xhaW1zIjp7InNjb3BlcyI6WyJ3YWxsZXQiXX19.9u2NZdFGzWOXpt6UNq8wvtrxTljuZ8lkw4r_K2K6d7I'
        axios.post(webApiUrl, {
            id_number,
            dlDob
        },
            {
                headers: {
                    'Accept': 'application/json, text/plain',
                    'content-type': 'application/json',
                    "Authorization": `Bearer ${tokenStr}`
                }
            }).then((res) => {
                if (res.data.status_code === 200 && res.data.success) {
                    set_citizenship(res.data.data.citizenship)
                    set_dob(res.data.data.dob)
                    set_doe(res.data.data.doe)
                    set_father_or_husband_name(res.data.data.father_or_husband_name)
                    set_gender(res.data.data.gender)
                    set_initial_doi(res.data.data.initial_doi)
                    set_license_number(res.data.data.license_number)
                    set_permanent_address(res.data.data.permanent_address)
                    set_permanent_zip(res.data.data.permanent_zip)
                    set_transport_doe(res.data.data.transport_doe)
                    set_transport_doi(res.data.data.transport_doi)
                    setIsActionToVerify(true)
                    setIsView(true)
                    setDlDataView(true)
                    setIsBackDrop(false)
                }
            }).catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        userWithDl()
    }, [])

    return (
        <div>
            {
                !isActionToVerify && (
                    <div>
                        {
                            <div className='p-3'>
                                {
                                    !isActionToVerify && (
                                        <Paper elevation={0}>
                                            <TableContainer sx={{ height: 68 + "vh", overflowY: 'auto', width: '52rem' }} >
                                                <div>
                                                    <h5 style={{ position: 'relative', top: '0.4rem' }}>GST  VERIFICATION</h5>
                                                    <br />
                                                    <br />
                                                    <span style={{ fontSize: '1rem' }}> A driver's license is a legal authorization, or the official document confirming such an authorization, for a specific individual to operate one or more types of motorized vehicles—such as motorcycles, cars, trucks, or buses —on a public road</span>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <Table style={{ position: 'relative', top: '-0.7rem' }}>
                                                        <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                                                            <TableRow>
                                                                <TableCell
                                                                    align={"center"}
                                                                    style={{ minWidth: 30, fontWeight: 'bold' }}
                                                                >
                                                                    User
                                                                </TableCell>
                                                                <TableCell
                                                                    align={"center"}
                                                                    style={{ minWidth: 30, fontWeight: 'bold' }}
                                                                >
                                                                    Licence Number
                                                                </TableCell>
                                                                <TableCell
                                                                    align={"center"}
                                                                    style={{ top: 57, minWidth: 60, fontWeight: 'bold' }}
                                                                >
                                                                    Driver Name
                                                                </TableCell>
                                                                <TableCell
                                                                    align={"center"}
                                                                    style={{ top: 57, minWidth: 60, fontWeight: 'bold' }}
                                                                >
                                                                    Mobile
                                                                </TableCell>
                                                                <TableCell
                                                                    align={"left"}
                                                                    style={{ top: 57, minWidth: 100, fontWeight: 'bold' }}
                                                                >
                                                                    Status
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        {
                                                            data.map((data) => {
                                                                let verify = ''
                                                                if (data.isVerify === '1') {
                                                                    verify = true
                                                                } else {
                                                                    verify = false
                                                                }
                                                                return (
                                                                    <TableBody>
                                                                        {
                                                                            verify && (
                                                                                <TableRow hover role="checkbox" tabIndex={-1}>
                                                                                    <TableCell align={"center"}
                                                                                        style={{ top: 57, minWidth: 60, fontSize: 12 }}><FaUser fontSize={26} color='rgb(233, 198, 108)' /></TableCell>
                                                                                    <TableCell align={"center"}
                                                                                        style={{ top: 57, minWidth: 60, fontSize: 12, cursor: 'pointer', textDecoration: 'underline' }} onClick={() => dlView(data.DLNumber)} >XXXX XXXX {data.DLNumber.substring(data.DLNumber.length + 4, 10)}</TableCell>
                                                                                    <TableCell align={"center"}
                                                                                        style={{ top: 57, minWidth: 60, fontSize: 12 }}>{data.dName}</TableCell>
                                                                                    <TableCell align={"center"}
                                                                                        style={{ top: 57, minWidth: 60, fontSize: 12 }}>{data.dMobile}</TableCell>
                                                                                    <TableCell align={"left"}
                                                                                        style={{ top: 57, minWidth: 100, flexDirection: 'row', alignItems: 'left' }}>
                                                                                        <span style={{ cursor: 'pointer', }}>
                                                                                            <button disabled={true} style={{ backgroundColor: 'rgb(107, 213, 107)', fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} >verified</button>
                                                                                        </span>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            )
                                                                        }
                                                                        {
                                                                            !verify && (
                                                                                <TableRow hover role="checkbox" tabIndex={-1}>
                                                                                    <TableCell align={"center"}
                                                                                        style={{ top: 57, minWidth: 60, fontSize: 12 }}><FaUser fontSize={26} color='rgb(233, 198, 108)' /></TableCell>
                                                                                    <TableCell align={"center"}
                                                                                        style={{ top: 57, minWidth: 60, fontSize: 12 }} >XXXX XXXX {data.DLNumber.substring(data.DLNumber.length + 4, 10)}</TableCell>
                                                                                    <TableCell align={"center"}
                                                                                        style={{ top: 57, minWidth: 60, fontSize: 12 }}>{data.dName}</TableCell>
                                                                                    <TableCell align={"center"}
                                                                                        style={{ top: 57, minWidth: 60, fontSize: 12 }}>{data.dMobile}</TableCell>
                                                                                    <TableCell align={"left"}
                                                                                        style={{ top: 57, minWidth: 100, flexDirection: 'row', alignItems: 'left' }}>
                                                                                        <span style={{ cursor: 'pointer', }}>
                                                                                            <button style={{ backgroundColor: 'rgb(107, 213, 107)', fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} onClick={() => dlVerification(data.DLNumber, data.dob, data.driver_id, data.BrokerId)} >Verify</button>
                                                                                        </span>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            )
                                                                        }
                                                                    </TableBody>
                                                                )
                                                            })
                                                        }
                                                    </Table>
                                                </div>
                                                <br />
                                                <br />

                                            </TableContainer>
                                        </Paper>
                                    )
                                }
                            </div>
                        }
                    </div>
                )
            }
            {
                isActionToVerify && (
                    <div style={{ width: '52rem', height: '69vh', overflowY: 'scroll' }}>
                        <div style={{ marginBottom: 10 }}>
                            <button className='signupBtn' style={{ height: '2.5rem', width: '8.9rem', borderRadius: '0.2rem', backgroundColor: 'green', color: 'white' }} onClick={() => DLVerify()} >Verify</button>
                            <button className='signupBtn' style={{ height: '2.5rem', width: '8.9rem', borderRadius: '0.2rem', backgroundColor: 'red', color: 'white' }} >Decline</button>
                        </div>
                        <div>
                            {
                                localData.map((data) => {
                                    console.log(data.uplDLAndAadhar)
                                    return (
                                        <div className='col-12 mx-auto bg-white p-4' style={{ border: '1px solid lightgray', borderRadius: 10 }}>
                                            <img src={data.uplDLAndAadhar} width='100%' />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <br />
                        <div>
                            <div className='col-12 mx-auto bg-white p-4' style={{ border: '1px solid lightgray', borderRadius: 10 }}>
                                {
                                    (dlDataView) && (
                                        <div>
                                            <button style={{ border: 'none' }} onClick={() => { setIsActionToVerify(false) }}><FaWindowClose color='#232363' fontSize={20} /></button>
                                            <h1 style={{ color: '#e4e4fa', textDecoration: 'textUnderlineOffset', textAlign: 'center' }}>DL DATA</h1>
                                        </div>
                                    )
                                }
                                <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                    <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Citizenship:</p>
                                    <p>{citizenship}</p>
                                </div>
                                <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                    <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Date Of Birth:</p>
                                    <p>{dob}</p>
                                </div>
                                <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                    <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>DOE:</p>
                                    <p>{doe}</p>
                                </div>
                                <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                    <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>DOI:</p>
                                    <p>{doi}</p>
                                </div>
                                <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                    <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Father Or Husband Name:</p>
                                    <p>{father_or_husband_name}</p>
                                </div>
                                <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                    <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Gender:</p>
                                    <p>{gender}</p>
                                </div>
                                <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                    <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Initial Doi:</p>
                                    <p>{initial_doi}</p>
                                </div>
                                <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                    <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Licence Number:</p>
                                    <p>{license_number}</p>
                                </div>
                                <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                    <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Permanent Address:</p>
                                    <p>{permanent_address}</p>
                                </div>
                                <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                    <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Permanent Zip:</p>
                                    <p>{permanent_zip}</p>
                                </div>
                                <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                    <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Transport Doe:</p>
                                    <p>{transport_doe}</p>
                                </div>
                                <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                    <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Transport Doi:</p>
                                    <p>{transport_doi}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            <div>
                {
                    isBackDrop && (
                        <SimpleBackdrop />
                    )
                }
            </div>
            <div>
                <Dialog
                    open={open}
                    maxWidth='lg'
                >
                    <DialogContent style={{width:'45rem'}} >
                        <DialogContentText>
                            {
                                (dlDataView || !isView) && (
                                    <div>
                                        <div className='col-12 mx-auto bg-white'>
                                            {
                                                (dlDataView) && (
                                                    <div>

                                                        <h1 style={{ color: '#e4e4fa', textDecoration: 'textUnderlineOffset', textAlign: 'center' }}>DL DATA</h1>
                                                    </div>
                                                )
                                            }
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Citizenship:</p>
                                                <p>{citizenship}</p>
                                            </div>
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Date Of Birth:</p>
                                                <p>{dob}</p>
                                            </div>
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>DOE:</p>
                                                <p>{doe}</p>
                                            </div>
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>DOI:</p>
                                                <p>{doi}</p>
                                            </div>
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Father Or Husband Name:</p>
                                                <p>{father_or_husband_name}</p>
                                            </div>
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Gender:</p>
                                                <p>{gender}</p>
                                            </div>
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Initial Doi:</p>
                                                <p>{initial_doi}</p>
                                            </div>
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Licence Number:</p>
                                                <p>{license_number}</p>
                                            </div>
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Permanent Address:</p>
                                                <p>{permanent_address}</p>
                                            </div>
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Permanent Zip:</p>
                                                <p>{permanent_zip}</p>
                                            </div>
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Transport Doe:</p>
                                                <p>{transport_doe}</p>
                                            </div>
                                            <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Transport Doi:</p>
                                                <p>{transport_doi}</p>
                                            </div>
                                            {
                                                (!dlDataView && !isView) && (
                                                    <div>
                                                        <button className='signupBtn' onClick={() => DLVerify()} >Submit</button>
                                                        <button className='signupBtn text-danger' >Decline</button>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={() => {
                            handleClose()
                            setOpenViewModel(false)
                            setDlDataView(false)
                            setIsActionToVerify(false)
                        }}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}

export default Licence
