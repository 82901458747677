

import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { FaLocks } from 'react-icons/fa'
import { FaLock } from 'react-icons/fa'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

const ResetPassword = () => {


    const [password, setPassword] = useState('')
    const [cpassword, setCPassword] = useState('')
    const navigate = useNavigate()

    const { client_id, userType } = useParams()

    const [passErr, setPassErr] = useState(false)



    const reset_password = () => {

        if (password !== cpassword) {
            setPassErr(true)
            return
        }

        axios.post('https://pmapi.emedha.in/password/change', {
            client_id,
            password,
            userType
        }).then((res) => {
            if (res.data.status === 200) {
                alert("Your New Password Successfully Change")
                navigate('/')
            }
        })
    }

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            reset_password()
        }

        setValidated(true);
    };

    return (
        <div  >
            <div className='p-3 mt-5'>
                <div className='col-lg-4 mx-auto signup-container uinfo' >
                    <Form noValidate validated={validated} onSubmit={handleSubmit} >
                        <Row className="mb-3">
                            <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                                <Form.Label>Confirm</Form.Label>
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend"><FaLock /></InputGroup.Text>
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        style={{ padding: '0.6rem' }}
                                        onChange={(e) =>  {
                                            setPassword(e.target.value)
                                            setPassErr(false)
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please choose a username.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                                <Form.Label>Confirm Password</Form.Label>
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend"><FaLock /></InputGroup.Text>
                                    <Form.Control
                                        type="password"
                                        placeholder="Confirm Password"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        style={{ padding: '0.6rem' }}
                                        onChange={(e) =>  {
                                            setCPassword(e.target.value)
                                            setPassErr(false)
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please choose a username.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        {
                            passErr && (
                                <div className='card' style={{ borderRadius: '0.1rem', height: '3.3rem', width: '100%', border: 'none', borderLeft: '4px solid red', backgroundColor: 'white', flexDirection: 'row', alignItems: 'center', paddingLeft: 10 }} >
                                    <span style={{ color: 'red', fontWeight: 'bold' }} >Error, </span><span> Confirm password not matched..!</span>
                                </div>
                            )
                        }
                        <button className="signupBtn" type='submit'>Submit</button>
                        <br />
                    </Form>
                </div>
            </div>
            <p className='text-center'>@2023 emedha technologies. Allright Reserved.</p>
        </div>
    )
}

export default ResetPassword