import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'auto',
    color: theme.palette.text.secondary,
}));

const KycTable = ({ type }) => {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (image) => {
        setImage(image)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    const [allVehicle, setAllVehicle] = useState([])

    const vehicle = () => {
        const client_id = sessionStorage.getItem('client_id')
        axios.post('https://pmapi.emedha.in/vehicle/data/show', {
            client_id,
            type
        }).then((res) => {
            setAllVehicle(res.data.result)
        })
    }

    useEffect(() => {
        vehicle()
    }, [])

    const [image, setImage] = useState('')

    return (
        <div>
            <div style={{ marginTop: '6rem' }} >
                {
                    (allVehicle.length < 0 || allVehicle.length === 0) && (
                        <p style={{ position: 'relative', top: '0.7rem', textAlign: 'center', marginTop: '10rem' }}> No Vehicle </p>
                    )
                }
            </div>
            <Box sx={{ flexGrow: 1 }}>


                {
                    allVehicle.length > 0 && (
                        <Grid container spacing={2}>
                            <div style={{ padding: 10, width: '100%' }} >
                                <Typography style={{ fontSize: '1.3rem', color: '#12124e', fontWeight: 'bold' }}>All Vehicle Details</Typography>
                                <Typography style={{ width: '100%', height: '0.09rem', backgroundColor: '#12124e', position: 'relative', top: '-0.2rem' }} ></Typography>

                            </div>
                            {
                                allVehicle.map((data) => {
                                    return (

                                        <Grid xs={6} >
                                            <Item style={{ backgroundColor: '#12124e', borderRadius: '0.2rem' }} elevation={1}>
                                                <Card>
                                                    <div>
                                                        <div className='p-1' style={{ backgroundColor: '#12124e', position: 'relative' }}>
                                                            <div style={{ marginTop: '-0.2rem' }} >
                                                                <Typography style={{ fontSize: '1.4rem', color: 'white' }}><span style={{ color: 'white', fontSize: '1.13rem', marginBottom:16 }}>Vehicle Rc Number: </span><span style={{fontSize:'1rem'}} >XX XXXX {data.RegNum.substring(data.RegNum.length + 4, 6)}</span></Typography>
                                                                 
                                                                <Typography style={{ fontSize: '0.8rem', color: 'white' }}><span style={{ color: 'white', fontSize: '0.8rem', marginBottom:16 }}>Vehicle: </span> {data.vehicleType}</Typography>
                                                                 
                                                                <Typography style={{ fontSize: '0.8rem', color: 'white' }}><span style={{ color: 'white', fontSize: '0.8rem', marginBottom:16 }}>Vehicle Capacity: </span> {data.vehicleCapacity}</Typography>
                                                            </div>

                                                            <div style={{ height: '7rem', position: 'relative', backgroundColor:'#2c2c6c', marginTop:6 }} >
                                                                <div className='col-12' style={{ backgroundColor: 'white', borderRadius: '0.2rem', overflow: 'hidden', width: '7rem', position: 'absolute', right: 5, bottom: 10, top: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >

                                                                    <img src={data.rcCopy} style={{ height: '4rem' }} />

                                                                </div>
                                                                <div className='col-12' style={{ backgroundColor: 'lightgray', borderRadius: '0.2rem', overflow: 'hidden', width: '7rem', position: 'absolute', right: 5, bottom: 10, top: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', opacity: 0.6 }} >

                                                                </div>
                                                                <div onClick={()=>handleClickOpen(data.rcCopy)} className='col-12' style={{ borderRadius: '0.2rem', overflow: 'hidden', width: '7rem', position: 'absolute', right: 5, bottom: 10, top: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'zoom-in' }} >
                                                                    <Typography style={{ border: '1px solid gray', borderRadius: '0.2rem', borderStyle: 'dashed', padding:10, paddingLeft: 3, paddingRight:3, fontSize: '0.6rem', width:'5rem', textAlign:'center' }} >RC Copy</Typography>
                                                                </div>



                                                                <div className='col-12' style={{ backgroundColor: 'white', borderRadius: '0.2rem', overflow: 'hidden', width: '7rem', position: 'absolute', left: 5, bottom: 10, top: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >

                                                                    <img src={data.cerCopy} style={{ height: '4rem' }} />

                                                                </div>
                                                                <div className='col-12' style={{ backgroundColor: 'lightgray', borderRadius: '0.2rem', overflow: 'hidden', width: '7rem', position: 'absolute', left: 5, bottom: 10, top: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', opacity: 0.6 }} >

                                                                </div>
                                                                <div onClick={()=>handleClickOpen(data.cerCopy)} className='col-12' style={{ borderRadius: '0.2rem', overflow: 'hidden', width: '7rem', position: 'absolute', left: 5, bottom: 10, top: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'zoom-in' }} >
                                                                    <Typography style={{ border: '1px solid gray', borderRadius: '0.2rem', borderStyle: 'dashed', padding:10, paddingLeft: 3, paddingRight:3, fontSize: '0.6rem', width:'5rem' }} >Certificate Copy</Typography>
                                                                </div>




                                                                <div className='col-12' style={{ backgroundColor: 'white', borderRadius: '0.2rem', overflow: 'hidden', width: '7rem', position: 'absolute', left: 138, bottom: 10, top: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >

                                                                    <img src={data.insCopy} style={{ height: '4rem' }} />

                                                                </div>
                                                                <div className='col-12' style={{ backgroundColor: 'lightgray', borderRadius: '0.2rem', overflow: 'hidden', width: '7rem', position: 'absolute', left: 138, bottom: 10, top: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', opacity: 0.6 }} >

                                                                </div>
                                                                <div onClick={()=> handleClickOpen(data.insCopy)} className='col-12' style={{ borderRadius: '0.2rem', overflow: 'hidden', width: '7rem', position: 'absolute', left: 138, bottom: 10, top: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'zoom-in' }} >
                                                                    <Typography style={{ border: '1px solid gray', borderRadius: '0.2rem', borderStyle: 'dashed', padding:10, paddingLeft: 3, paddingRight:3, fontSize: '0.6rem', width:'5rem', textAlign:'center' }} >INS Copy</Typography>
                                                                </div>
                                                            </div>
                                                            <div>

                                                                <Dialog
                                                                    open={open}
                                                                    onClose={handleClose}
                                                                    aria-labelledby="alert-dialog-title"
                                                                    aria-describedby="alert-dialog-description"
                                                                >
                                                                    <DialogTitle id="alert-dialog-title">
                                                                        {"Document Copy"}
                                                                    </DialogTitle>
                                                                    <DialogContent   >
                                                                        <DialogContentText style={{ border: '1px solid lightgray', borderStyle: 'dashed', borderRadius: '0.2rem' }} id="alert-dialog-description">
                                                                            <img src={image} style={{ height: '20rem' }} />
                                                                        </DialogContentText>
                                                                    </DialogContent>
                                                                    <DialogActions >

                                                                        <Button style={{ color: 'red' }} onClick={handleClose} autoFocus>
                                                                            Cancel
                                                                        </Button>
                                                                    </DialogActions>
                                                                </Dialog>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Item>
                                        </Grid>


                                    )
                                })
                            }
                        </Grid>
                    )
                }


            </Box>
        </div>
    )
}

export default KycTable