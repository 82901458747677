import React, { useState, useEffect } from 'react'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Button from '@mui/material/Button';
import { FaBusAlt, FaPlusCircle, FaUpload } from 'react-icons/fa';
import DriverShowData from '../DrverShowData/DriverShowData';
import Swal from 'sweetalert2';
import { TextField, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import OtpInput from "react-otp-input";

const LorryBrokerDriver = (props) => {

    const navigate = useNavigate()
    useEffect(() => {
        if (!sessionStorage.getItem('key')) {
            navigate('/')
        }
    })

    const [dob, setDob] = useState()
    const [dLicence, setDLicence] = useState('')

    const [Validated, setValidated] = useState(false);

    const handleSubmitGst = (event) => {



        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            if (!isAadharVerify) {
                setIsAadharVerifyError(true)
                setDaError(false)
                return
            } else {
                addDriversBroker()
            }

        }

        setValidated(true);
    };

    const [dName, setDName] = useState('')
    const [uplDlAdhar, setUplDlAdhar] = useState('')
    const [dMobile, setDMobile] = useState('')
    const [bankDetUpiId, setBankDetUpiId] = useState()
    const [dAadhar, setDAadhar] = useState('')

    const addDriversBroker = () => {

        if (uplDlAdhar === '') {
            setUplDlAdhar('error')
            return
        }

        const client_id = sessionStorage.getItem('client_id')
        const formData = new FormData();
        formData.append('file', uplDlAdhar)
        formData.append('client_id', client_id)
        formData.append('dName', dName)
        formData.append('dMobile', dMobile)
        formData.append('dLicence', dLicence)
        formData.append('dAadhar', dAadhar)
        formData.append('bankDetUpiId', bankDetUpiId)
        formData.append('dob', dob)
        formData.append('userType', 'LorryBroker')

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        axios.post('https://pmapi.emedha.in/driver/data/add', formData, config).then((res) => {
            if (res.data.status === 200) {
                setIsAddDrivers(false)
                setUplDlAdhar('')
                setDName('')
                setDMobile('')
                setDLicence('')
                setDAadhar('')
                setBankDetUpiId('')
                setDob('')
                setIsAadharVerify(false)
                Swal.fire({
                    title: "Successful..!",
                    text: "Driver successfully added...!",
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Cancel",
                }).then((result) => {
                    if (result.value) {
                        setIsAddDrivers(false)
                        setUplDlAdhar('')
                        setDName('')
                        setDMobile('')
                        setDLicence('')
                        setDAadhar('')
                        setBankDetUpiId('')
                        setDob('')
                    }
                });
            }
        }).catch(() => {
            Swal.fire({
                title: "Error..!",
                text: "Change Your image or this data is already exist",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Cancel",
            }).then((result) => {
                if (result.value) {
                    setIsAddDrivers(false)
                    setUplDlAdhar('')
                    setDName('')
                    setDMobile('')
                    setDLicence('')
                    setDAadhar('')
                    setBankDetUpiId('')
                    setDob('')
                }
            });
        })
    }



    const [isAddDrivers, setIsAddDrivers] = useState(false)


    const [isAddNewDriver, setIsAddNewDriver] = useState(false)
    const [isShowDriver, setIsShowDriver] = useState(true)
    const [wait4, setWait4] = useState(false)
    const [otp, setOtp] = useState('')
    const [client_id, set_client_id] = useState()
    const [isAadharVerify, setIsAadharVerify] = useState(false)

    const [isAadharVerifyError, setIsAadharVerifyError] = useState(false)




    const handleChange = (otp) => {
        setOtp(otp)
    };


    const AadharTime = () => {
        Swal.fire({
            title: "Time Out..!",
            cancelButtonText: 'Cancel',
            text: "Please try again after some time ...!",
            showCancelButton: 'Cancel',
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Dashboard",
        }).then((result) => {
            if (result.value) {
                window.location.reload()
            } else {
                setWait4(false)
            }
        });
    }

    const AadharOtpError = () => {
        Swal.fire({
            title: "Somthing Wrong..!",
            cancelButtonText: 'Cancel',
            text: "Please try again later ...!",
            showCancelButton: 'Cancel',
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Dashboard",
        }).then((result) => {
            if (result.value) {
                window.location.reload()
            } else {
                setWait4(false)
            }
        });
    }

    const aadharVerification = () => {
        setWait4(true)
        const webApiUrl = 'https://kyc-api.surepass.io/api/v1/aadhaar-v2/submit-otp'
        const tokenStr = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY3MzMyODQwMywianRpIjoiMmYzMmMzMmQtMjMwMS00ZDg1LTkwNzktODUxMzMwMWMzZTJhIiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LnByaW1lbW92ZXJzQHN1cmVwYXNzLmlvIiwibmJmIjoxNjczMzI4NDAzLCJleHAiOjE5ODg2ODg0MDMsInVzZXJfY2xhaW1zIjp7InNjb3BlcyI6WyJ3YWxsZXQiXX19.9u2NZdFGzWOXpt6UNq8wvtrxTljuZ8lkw4r_K2K6d7I'
        axios.post(webApiUrl, {
            client_id,
            otp
        },
            {
                headers: {
                    'Accept': 'application/json, text/plain',
                    'content-type': 'application/json',
                    "Authorization": `Bearer ${tokenStr}`
                }
            }).then(() => {
                const CCEncoded = "XXXX XXXX " + dAadhar.substring(dAadhar.length + 4, 8);
                setDAadhar(CCEncoded)
                setIsAadharVerify(true)
                handleClose()
            }).catch((err) => {
                handleClose()
                AadharOtpError()
                setWait4(false)
            })
    }

    const [daError, setDaError] = useState(false)

    const generateOtp = () => {

        if (dAadhar === '') {
            setDaError(true)
            setIsAadharVerify(false)
            return
        }

        setWait4(true)
        const webApiUrl = 'https://kyc-api.surepass.io/api/v1/aadhaar-v2/generate-otp'
        const tokenStr = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY3MzMyODQwMywianRpIjoiMmYzMmMzMmQtMjMwMS00ZDg1LTkwNzktODUxMzMwMWMzZTJhIiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LnByaW1lbW92ZXJzQHN1cmVwYXNzLmlvIiwibmJmIjoxNjczMzI4NDAzLCJleHAiOjE5ODg2ODg0MDMsInVzZXJfY2xhaW1zIjp7InNjb3BlcyI6WyJ3YWxsZXQiXX19.9u2NZdFGzWOXpt6UNq8wvtrxTljuZ8lkw4r_K2K6d7I'
        axios.post(webApiUrl, {
            id_number: dAadhar
        },
            {
                headers: {
                    'Accept': 'application/json, text/plain',
                    'content-type': 'application/json',
                    "Authorization": `Bearer ${tokenStr}`
                }
            }).then((res) => {
                if (res.data.data.otp_sent && res.data.data.valid_aadhaar) {

                    set_client_id(res.data.data.client_id)
                    setWait4(false)
                    handleClickOpen()
                }
            }).catch(() => {
                AadharTime()
                setWait4(false)
            })
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);

    };

    const handleClose = () => {
        setOpen(false);
        setWait4(false)
    };

    return (
        <div>
            <div >
                {
                    (props.AllDriver.length !== 0) && (
                        <div style={{ marginLeft: 10 }} >
                            <Button variant="contained" onClick={() => {
                                setIsAddDrivers(true)
                                setIsAddNewDriver(true)
                                setIsShowDriver(false)
                            }} size='small' style={{ backgroundColor: isAddNewDriver ? '#12124e' : 'white', width: '9rem', color: !isAddNewDriver ? '#12124e' : 'white' }}><FaPlusCircle size={16} style={{ position: 'relative', top: '-0.1rem', marginRight: '0.2rem' }} />Add Driver</Button>
                            <Button size='small' onClick={() => {
                                setIsAddDrivers(false)
                                setIsAddNewDriver(false)
                                setIsShowDriver(true)
                            }} variant="contained" style={{ backgroundColor: isShowDriver ? '#12124e' : 'white', width: '9rem', color: isAddNewDriver ? '#12124e' : 'white', marginLeft: 4 }}><FaBusAlt size={16} style={{ position: 'relative', top: '-0.1rem', marginRight: '0.2rem' }} />Drivers</Button>
                        </div>
                    )
                }
                {
                    ((props.AllDriver.length < 0 || props.AllDriver.length === 0) || isAddDrivers) && (
                        <div className='col-lg-12 mx-auto pb-3 p-2' >
                            <br />
                            <Typography style={{ marginTop: '1rem', fontSize: '1.3rem', color: '#12124e', fontWeight: 'bold' }}>Add Driver</Typography>
                            <Typography style={{ width: '100%', height: '0.09rem', backgroundColor: '#12124e', position: 'relative', top: '-0.2rem' }} ></Typography>
                            <br />
                            <Form noValidate validated={Validated} onSubmit={handleSubmitGst} >
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Form.Label style={{ fontWeight: '500', fontSize: '16px', color: '#023A51', fontStyle: 'poppins', position: 'relative', top: 10 }} >Driver Name</Form.Label>
                                        <Form.Control
                                            required
                                            value={dName}
                                            type="text"
                                            placeholder="Driver Name"
                                            style={{ height: '52px', outline: 'none', border: '2px solid lightgray', paddingLeft: 10, borderRadius: '5px' }}
                                            onChange={(e) => {
                                                setDName(e.target.value)
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Name
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Form.Label style={{ fontWeight: '500', fontSize: '16px', color: '#023A51', fontStyle: 'poppins', position: 'relative', top: 10 }} >Driver Mobile</Form.Label>
                                        <Form.Control
                                            required
                                            value={dMobile}
                                            type="text"
                                            placeholder="Driver Mobile"
                                            style={{ height: '52px', outline: 'none', border: '2px solid lightgray', paddingLeft: 10, borderRadius: '5px' }}
                                            onChange={(e) => {
                                                setDMobile(e.target.value)
                                            }}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Mobile Number
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3' md="6" >
                                        <Typography style={{ fontWeight: '500', fontSize: '16px', color: '#023A51', fontStyle: 'poppins', position: 'relative', top: 12.1 }}>Upload Dl{(uplDlAdhar === '' || uplDlAdhar === 'error') ? '':'(Image selected)'} </Typography>
                                        <Button
                                            variant="contained"
                                            component="label"
                                            style={{ height: '100px', width: '48.5%', backgroundColor: 'white', position: 'relative', border: '2px solid lightgray', boxShadow: 'none' }}
                                        >
                                            <FaUpload style={{ fontSize: 23, zIndex: 20, color: 'rgb(40, 251, 251)' }} />
                                            <input
                                                required
                                                type="file"

                                                hidden
                                                onChange={(e) => {
                                                    setUplDlAdhar(e.target.files[0])
                                                }}
                                            />
                                        </Button>
                                        {
                                            uplDlAdhar === 'error' && (
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Valid DL Image .
                                                </Form.Control.Feedback>
                                            )
                                        }
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Form.Label style={{ fontWeight: '500', fontSize: '16px', color: '#023A51', fontStyle: 'poppins', position: 'relative', top: 10 }} >DL Number</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            value={dLicence}
                                            placeholder="Driver Licence"
                                            style={{ height: '52px', outline: 'none', border: '2px solid lightgray', paddingLeft: 10, borderRadius: '5px' }}
                                            onChange={(e) => {
                                                setDLicence(e.target.value)
                                            }}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid DL Number
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Form.Label style={{ fontWeight: '500', fontSize: '16px', color: '#023A51', fontStyle: 'poppins', position: 'relative', top: 10 }} >Driver Aadhar Number</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            value={dAadhar}
                                            placeholder="Driver Aadhar Number"
                                            style={{ height: '52px', outline: 'none', border: '2px solid lightgray', paddingLeft: 10, borderRadius: '5px' }}
                                            onChange={(e) => {
                                                setDAadhar(e.target.value)
                                                setIsAadharVerifyError(false)
                                            }}
                                        />
                                        {
                                            isAadharVerifyError && (
                                                <Typography style={{ color: 'red', fontSize: '0.8rem' }} >Please verify driver aadhar number</Typography>
                                            )
                                        }

                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Aadhar Number
                                        </Form.Control.Feedback>
                                        {
                                            isAadharVerify ? <button style={{ color: 'green', border: 'none', borderRadius: '1rem', width: '7rem', marginTop: '0.2rem' }} >Verified</button> : <button type='' onClick={generateOtp} disabled={wait4} style={{ backgroundColor: !wait4 ? '#30d0fc' : 'gray', color: 'white', border: 'none', borderRadius: '1rem', width: '7rem', marginTop: '0.2rem' }} >{!wait4 ? 'Verify' : 'Otp Sending..'}</button>
                                        }

                                    </Form.Group>

                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Label style={{ fontWeight: '500', fontSize: '16px', color: '#023A51', fontStyle: 'poppins', position: 'relative', top: 10 }} >Bank Details/UPI ID</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            value={bankDetUpiId}
                                            placeholder="Bank Details/UPI ID"
                                            style={{ height: '52px', outline: 'none', border: '2px solid lightgray', paddingLeft: 10, borderRadius: '5px' }}
                                            onChange={(e) => {
                                                setBankDetUpiId(e.target.value)
                                            }}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Name Number
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Label style={{ fontWeight: '500', fontSize: '16px', color: '#023A51', fontStyle: 'poppins', position: 'relative', top: 10 }} >Date Of Birth</Form.Label>
                                        <Form.Control
                                            required
                                            value={dob}
                                            type="date"
                                            placeholder="Driver Licence"
                                            style={{ height: '52px', outline: 'none', border: '2px solid lightgray', paddingLeft: 10, borderRadius: '5px' }}
                                            onChange={(e) => {
                                                setDob(e.target.value)
                                            }}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Name Number
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <button className='signupBtn' >Submit</button>
                            </Form>
                        </div>
                    )
                }
                {
                    (props.AllDriver.length > 0 && !isAddDrivers) && (
                        <div>
                            <div style={{ marginTop: '-5rem' }}>
                                <DriverShowData AllDriver={props.AllDriver} />
                            </div>
                        </div>
                    )
                }
            </div>
            <div>

                <Dialog
                    open={open}

                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >

                    <DialogContent style={{ width: '32rem' }} >
                        <Typography style={{ color: '#30d0fc', fontSize: '1.3rem', marginBottom: '1rem', fontWeight: '800' }} >Driver Aadhar verification</Typography>
                        <div style={{ backgroundColor: 'green', borderRadius: '0.2rem', padding: 4 }} >
                            <Typography color='white' >OTP will be sent to the mobile number linked to this aadhaar number .</Typography>
                        </div>
                        <br />
                        <Typography>Enter OTP</Typography>
                        <OtpInput
                            value={otp}
                            onChange={handleChange}
                            numInputs={6}
                            separator={<span style={{ width: "8px" }}></span>}
                            isInputNum={true}
                            shouldAutoFocus={true}
                            className='signup-input-otp'
                            inputStyle={{
                                border: "none",
                                borderRadius: "2px",
                                width: "4.4rem",
                                height: "54px",
                                fontSize: "16px",
                                margin: 'auto',
                                backgroundColor: '#e4e4fa',
                                fontWeight: "bold",
                                caretColor: "rgba(60, 64, 67, 0.3)",
                            }}
                            focusStyle={{
                                outline: "none"
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={wait4} onClick={aadharVerification} style={{ color: '#30d0fc' }} autoFocus>
                            {!wait4 ? 'Submit' : 'Submiting..'}
                        </Button>
                        <Button onClick={handleClose} style={{ color: 'red' }} autoFocus>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

        </div>
    )
}

export default LorryBrokerDriver