import axios from 'axios'
import React from 'react'
import { FaCheckCircle, FaEye, FaTruck, FaWindowClose } from 'react-icons/fa'
import { useState, useEffect } from 'react'
import SimpleBackdrop from '../../../../BackDrop/BackDrop'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


function Rc({ id }) {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [openViewModel, setOpenViewModel] = useState(false)


    const [data, setData] = useState([])

    const userWithRc = () => {

        const userType = 'LorryBroker'

        axios.post('https://pmapi.emedha.in/admin/vehicle-details', {
            id,
            userType
        }).then((res) => {
            setData(res.data)
        })
    }


    const [localData, setLocaldata] = useState([])
    const [isActionToVerify, setIsActionToVerify] = useState(false)
    const [fuel_type, set_fuel_type] = useState()
    const [insurance_company, set_insurance_company] = useState()
    const [insurance_policy_number, set_insurance_policy_number] = useState()
    const [maker_description, set_maker_description] = useState()
    const [maker_model, set_maker_model] = useState()
    const [national_permit_number, set_national_permit_number] = useState()
    const [national_permit_upto, set_national_permit_upto] = useState()
    const [norms_type, set_norms_type] = useState()
    const [owner_name, set_owner_name] = useState()
    const [rc_number, set_rc_number] = useState()
    const [registration_date, set_registration_date] = useState()
    const [vehicle_category, set_vehicle_category] = useState()
    const [vehicle_category_description, set_vehicle_category_description] = useState()
    const [fit_up_to, set_fit_up_to] = useState()
    const [registered_at, set_registered_at] = useState()
    const [isVerify, setIsVerify] = useState(0)
    const [client_id, set_client_id] = useState()
    const [rcDataView, setrcDataView] = useState(true)
    const [isView, setIsView] = useState(false)
    const [isRcCopyZoom, setIsRcCopyZoom] = useState(false)
    const [isCerCopyZoom, setIsCerCopyZoom] = useState(false)
    const [isInsCopyZoom, setIsInsCopyZoom] = useState(false)
    const [isBackDrop, setIsBackDrop] = useState(false)

    const userLocalData = (id) => {
        const checkData = 'broker_vehcle_reg' 
        axios.post("https://pmapi.emedha.in/admin/vehicle-details", {
            id,
            checkData
        }).then((res) => {
            setLocaldata(res.data)
            setIsActionToVerify(true)
            setrcDataView(false)
            setIsView(false)
            setIsVerify(1)
            setIsBackDrop(false)
        })
    }

    const rcVerification = (id_number, id) => {
        setOpenViewModel(false)
        setIsBackDrop(true)
        set_client_id(id_number)
        setCheckIsVerify(id)
        const less_info = true
        const webApiUrl = 'https://kyc-api.surepass.io/api/v1/rc/rc-lite-v2'
        const tokenStr = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY3MzMyODQwMywianRpIjoiMmYzMmMzMmQtMjMwMS00ZDg1LTkwNzktODUxMzMwMWMzZTJhIiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LnByaW1lbW92ZXJzQHN1cmVwYXNzLmlvIiwibmJmIjoxNjczMzI4NDAzLCJleHAiOjE5ODg2ODg0MDMsInVzZXJfY2xhaW1zIjp7InNjb3BlcyI6WyJ3YWxsZXQiXX19.9u2NZdFGzWOXpt6UNq8wvtrxTljuZ8lkw4r_K2K6d7I'
        axios.post(webApiUrl, {
            id_number,
            less_info
        },
            {
                headers: {
                    'Accept': 'application/json, text/plain',
                    'content-type': 'application/json',
                    "Authorization": `Bearer ${tokenStr}`
                }
            }).then((res) => {

                if (res.data.status_code === 200) {
                    userLocalData(id_number)
                    set_fuel_type(res.data.data.fuel_type)
                    set_insurance_company(res.data.data.insurance_company)
                    set_insurance_policy_number(res.data.data.insurance_policy_number)
                    set_maker_description(res.data.data.maker_description)
                    set_maker_model(res.data.data.maker_model)
                    set_national_permit_number(res.data.data.national_permit_number)
                    set_norms_type(res.data.data.norms_type)
                    set_owner_name(res.data.data.owner_name)
                    set_rc_number(res.data.data.rc_number)
                    set_registration_date(res.data.data.registration_date)
                    set_vehicle_category(res.data.data.vehicle_category)
                    set_vehicle_category_description(res.data.data.vehicle_category_description)
                    set_fit_up_to(res.data.data.fit_up_to)
                    set_registered_at(res.data.data.registered_at)
                }

            }).catch(() => {
                alert('Please Enter Valid RC number..')
                setIsBackDrop(false)
            })
    }

    const rcView = (id_number) => {
        handleClickOpen()
        setOpenViewModel(true)
        setIsBackDrop(true)
        const less_info = true
        const webApiUrl = 'https://kyc-api.surepass.io/api/v1/rc/rc-lite-v2'
        const tokenStr = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY3MzMyODQwMywianRpIjoiMmYzMmMzMmQtMjMwMS00ZDg1LTkwNzktODUxMzMwMWMzZTJhIiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LnByaW1lbW92ZXJzQHN1cmVwYXNzLmlvIiwibmJmIjoxNjczMzI4NDAzLCJleHAiOjE5ODg2ODg0MDMsInVzZXJfY2xhaW1zIjp7InNjb3BlcyI6WyJ3YWxsZXQiXX19.9u2NZdFGzWOXpt6UNq8wvtrxTljuZ8lkw4r_K2K6d7I'
        axios.post(webApiUrl, {
            id_number,
            less_info
        },
            {
                headers: {
                    'Accept': 'application/json, text/plain',
                    'content-type': 'application/json',
                    "Authorization": `Bearer ${tokenStr}`
                }
            }).then((res) => {

                if (res.data.status_code === 200) {
                    set_fuel_type(res.data.data.fuel_type)
                    set_insurance_company(res.data.data.insurance_company)
                    set_insurance_policy_number(res.data.data.insurance_policy_number)
                    set_maker_description(res.data.data.maker_description)
                    set_maker_model(res.data.data.maker_model)
                    set_national_permit_number(res.data.data.national_permit_number)
                    set_norms_type(res.data.data.norms_type)
                    set_owner_name(res.data.data.owner_name)
                    set_rc_number(res.data.data.rc_number)
                    set_registration_date(res.data.data.registration_date)
                    set_vehicle_category(res.data.data.vehicle_category)
                    set_vehicle_category_description(res.data.data.vehicle_category_description)
                    set_fit_up_to(res.data.data.fit_up_to)
                    set_registered_at(res.data.data.registered_at)
                    setIsActionToVerify(true)
                    setrcDataView(true)
                    setIsView(true)
                    setIsBackDrop(false)
                }

            }).catch(() => {
                alert('Please Enter Valid RC number..')
                setIsBackDrop(false)
            })
    }

    const [checkIsVerify, setCheckIsVerify] = useState('')

    const VehicleRcVerify = () => {
        const userType = 'LorryBroker'
        if (window.confirm('Confirm To Verify Rc Number')) {
            axios.post('https://pmapi.emedha.in/admin/vehicle/rc/verification', {
                isVerify,
                client_id,
                checkIsVerify,
                userType
            }).then((res) => {
                if (res.data.status_code === 200) {
                    alert('Verified')
                    setIsActionToVerify(false)
                    userWithRc()
                }
            })
        }
    }



    useEffect(() => {
        userWithRc()
    }, [])

    return (
        <div className='p-3' >
            {
                !isActionToVerify && (
                    <Paper elevation={0}>
                        <TableContainer sx={{ height: 68 + "vh", overflowY: 'scroll', width: '52rem' }} >
                            <div>
                                <h5 style={{ position: 'relative', top: '0.4rem' }}>RC  VERIFICATION</h5>
                                <br />
                                <br />
                                <span style={{ fontSize: '1rem' }}>Vehicle rc verification must needed to check the valid vehicle registertion number, please check and verify the rc number and and add new transporter</span>
                                <br />
                                <br />
                                <br />
                                <Table style={{ position: 'relative', top: '-0.7rem' }}>
                                    <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                                        <TableRow>
                                            <TableCell
                                                align={"center"}
                                                style={{ minWidth: 30, fontWeight: 'bold' }}
                                            >
                                                Vehicle
                                            </TableCell>
                                            <TableCell
                                                align={"center"}
                                                style={{ minWidth: 30, fontWeight: 'bold' }}
                                            >
                                                Vehicle RC NO.
                                            </TableCell>
                                            <TableCell
                                                align={"center"}
                                                style={{ top: 57, minWidth: 60, fontWeight: 'bold' }}
                                            >
                                                Vehicle Type
                                            </TableCell>
                                            <TableCell
                                                align={"center"}
                                                style={{ top: 57, minWidth: 60, fontWeight: 'bold' }}
                                            >
                                                Vehicle Capacity
                                            </TableCell>
                                            <TableCell
                                                align={"left"}
                                                style={{ top: 57, minWidth: 100, fontWeight: 'bold' }}
                                            >
                                                Status
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {
                                        data.map((data) => {
                                            let verify = ''
                                            if (data.isVerify === '1') {
                                                verify = true
                                            } else {
                                                verify = false
                                            }
                                            return (
                                                <TableBody>
                                                    {
                                                        verify && (
                                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                                <TableCell align={"center"}
                                                                    style={{ top: 57, minWidth: 60, fontSize: 12 }}><FaTruck fontSize={26} color='rgb(233, 198, 108)' /></TableCell>
                                                                <TableCell align={"center"}
                                                                    style={{ top: 57, minWidth: 60, fontSize: 12, cursor: 'pointer', textDecoration: 'underline' }} onClick={() => rcView(data.RegNum)} >XXXX XXXX {data.RegNum.substring(data.RegNum.length + 4, 6)}</TableCell>
                                                                <TableCell align={"center"}
                                                                    style={{ top: 57, minWidth: 60, fontSize: 12 }}>{data.vehicleType}</TableCell>
                                                                <TableCell align={"center"}
                                                                    style={{ top: 57, minWidth: 60, fontSize: 12 }}>{data.vehicleCapacity}</TableCell>
                                                                <TableCell align={"left"}
                                                                    style={{ top: 57, minWidth: 100, flexDirection: 'row', alignItems: 'left' }}>
                                                                    <span style={{ cursor: 'pointer', }}>
                                                                        <button disabled={true} style={{ backgroundColor: 'rgb(107, 213, 107)', fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} >verified</button>
                                                                    </span>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    }
                                                    {
                                                        !verify && (
                                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                                <TableCell align={"center"}
                                                                    style={{ top: 57, minWidth: 60, fontSize: 12 }}><FaTruck fontSize={26} color='rgb(233, 198, 108)' /></TableCell>
                                                                <TableCell align={"center"}
                                                                    style={{ top: 57, minWidth: 60, fontSize: 12 }} >XXXX XXXX {data.RegNum.substring(data.RegNum.length + 4, 6)}</TableCell>
                                                                <TableCell align={"center"}
                                                                    style={{ top: 57, minWidth: 60, fontSize: 12 }}>{data.vehicleType}</TableCell>
                                                                <TableCell align={"center"}
                                                                    style={{ top: 57, minWidth: 60, fontSize: 12 }}>{data.vehicleCapacity}</TableCell>
                                                                <TableCell align={"left"}
                                                                    style={{ top: 57, minWidth: 100, flexDirection: 'row', alignItems: 'left' }}>
                                                                    <span style={{ cursor: 'pointer', }}>
                                                                        <button style={{ backgroundColor: 'rgb(107, 213, 107)', fontSize: '0.8rem', borderRadius: '1rem', border: 'none', color: 'white' }} onClick={() => rcVerification(data.RegNum, data.BrokerId)} >Verify</button>
                                                                    </span>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    }
                                                </TableBody>
                                            )
                                        })
                                    }
                                </Table>
                            </div>
                            <br />
                            <br />

                        </TableContainer>
                    </Paper>
                )
            }
            {
                (isActionToVerify && !openViewModel) && (
                    <div style={{ width: '52rem', overflowY: 'scroll', height: '68vh' }}>
                        {
                            (!isView && !rcDataView) && (
                                <div >
                                    <button style={{ height: '2.4rem', width: '8rem', backgroundColor: 'green', border: 'none', borderRadius: '0.2rem', color: 'white', marginRight: '0.3rem' }} onClick={() => VehicleRcVerify()} >Verify</button>
                                    <button style={{ height: '2.4rem', width: '8rem', backgroundColor: 'red', border: 'none', borderRadius: '0.2rem', color: 'white' }} >Decline</button>
                                </div>
                            )
                        }
                        <br/>
                        <div style={{ border: '2px solid lightgray', borderRadius: '0.6rem', padding: 10 }}>
                            {
                                (!rcDataView && !isView) && (
                                    <div>
                                        {
                                            localData.map((data) => {
                                                return (
                                                    <div>
                                                        <div className='col-12 mx-auto bg-white p-4'>
                                                            <div className='row'>
                                                                <div className='col-4 mx-auto'>
                                                                    <img onClick={() => {
                                                                        setIsRcCopyZoom(true)
                                                                        setIsInsCopyZoom(false)
                                                                        setIsCerCopyZoom(false)
                                                                    }} style={{ cursor: 'zoom-in' }} src={data.rcCopy} width="100%" />

                                                                </div>
                                                                <div className='col-4 mx-auto'>

                                                                    <img onClick={() => {
                                                                        setIsRcCopyZoom(false)
                                                                        setIsInsCopyZoom(false)
                                                                        setIsCerCopyZoom(true)
                                                                    }} style={{ cursor: 'zoom-in' }} src={data.cerCopy} width="100%" />

                                                                </div>
                                                                <div className='col-4 mx-auto'>

                                                                    <img onClick={() => {
                                                                        setIsRcCopyZoom(false)
                                                                        setIsInsCopyZoom(true)
                                                                        setIsCerCopyZoom(false)
                                                                    }} style={{ cursor: 'zoom-in' }} src={data.insCopy} width="100%" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {
                                                            isRcCopyZoom && (
                                                                <div className='col-12 mx-auto bg-white p-4 mt-3'>
                                                                    <img onClick={() => setIsRcCopyZoom(false)} style={{ cursor: 'zoom-out' }} src={data.rcCopy} width="100%" />
                                                                </div>
                                                            )
                                                        }

                                                        {
                                                            isCerCopyZoom && (
                                                                <div className='col-12 mx-auto bg-white p-4 mt-3'>
                                                                    <img onClick={() => setIsCerCopyZoom(false)} style={{ cursor: 'zoom-out' }} src={data.cerCopy} width="100%" />
                                                                </div>
                                                            )
                                                        }

                                                        {
                                                            isInsCopyZoom && (
                                                                <div className='col-12 mx-auto bg-white p-4 mt-3'>
                                                                    <img onClick={() => setIsInsCopyZoom(false)} style={{ cursor: 'zoom-out' }} src={data.insCopy} width="100%" />
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }
                        </div>

                        <br />
                        {
                            (rcDataView || !isView) && (
                                <div className='col-12 mx-auto bg-white p-4' style={{ position: 'relative', border: '1px solid lightgray', borderRadius: '0.4rem' }}>

                                    <br />
                                    {
                                        (rcDataView) && (
                                            <div>
                                                <button style={{ border: 'none' }} onClick={() => { setIsActionToVerify(false) }}><FaWindowClose color='#232363' fontSize={20} /></button>
                                                <h1 style={{ color: '#e4e4fa', textDecoration: 'textUnderlineOffset', textAlign: 'center' }}>RC DATA</h1>
                                            </div>
                                        )
                                    }
                                    <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                        <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Fit Up To:</p>
                                        <p>{fit_up_to}</p>
                                    </div>
                                    <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                        <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Fuel Type:</p>
                                        <p>{fuel_type}</p>
                                    </div>
                                    <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                        <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Insurance Company:</p>
                                        <p>{insurance_company}</p>
                                    </div>
                                    <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                        <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Insurance Policy Number:</p>
                                        <p>{insurance_policy_number}</p>
                                    </div>
                                    <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                        <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Maker Description:</p>
                                        <p>{maker_description}</p>
                                    </div>
                                    <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                        <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Maker Model:</p>
                                        <p>{maker_model}</p>
                                    </div>
                                    <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                        <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>National Permit Number:</p>
                                        <p>{national_permit_number}</p>
                                    </div>
                                    <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                        <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>National Permit Upto:</p>
                                        <p>{national_permit_upto}</p>
                                    </div>
                                    <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                        <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Norms Type:</p>
                                        <p>{norms_type}</p>
                                    </div>
                                    <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                        <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Owner Name:</p>
                                        <p>{owner_name}</p>
                                    </div>
                                    <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                        <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Rc Number:</p>
                                        <p>{rc_number}</p>
                                    </div>
                                    <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                        <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Registertion Date:</p>
                                        <p>{registration_date}</p>
                                    </div>
                                    <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                        <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Registered At:</p>
                                        <p>{registered_at}</p>
                                    </div>
                                    <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                        <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Vehicle Category:</p>
                                        <p>{vehicle_category}</p>
                                    </div>
                                    <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                        <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Vehicle Category Description:</p>
                                        <p>{vehicle_category_description}</p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }
            <div>
                {
                    isBackDrop && (
                        <SimpleBackdrop />
                    )
                }
            </div>
            <div>
                <Dialog
                maxWidth='lg'
                    open={open}
                >
                    <DialogContent style={{width:'52rem'}} >
                        <DialogContentText>
                            {
                                (rcDataView || !isView) && (
                                    <div className=' col-12 mx-auto bg-white'>
                                        {
                                            (rcDataView) && (
                                                <div>

                                                    <h1 style={{ color: '#e4e4fa', textDecoration: 'textUnderlineOffset', textAlign: 'center' }}>RC DATA</h1>
                                                </div>
                                            )
                                        }
                                        <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                            <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Fit Up To:</p>
                                            <p>{fit_up_to}</p>
                                        </div>
                                        <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                            <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Fuel Type:</p>
                                            <p>{fuel_type}</p>
                                        </div>
                                        <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                            <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Insurance Company:</p>
                                            <p>{insurance_company}</p>
                                        </div>
                                        <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                            <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Insurance Policy Number:</p>
                                            <p>{insurance_policy_number}</p>
                                        </div>
                                        <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                            <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Maker Description:</p>
                                            <p>{maker_description}</p>
                                        </div>
                                        <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                            <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Maker Model:</p>
                                            <p>{maker_model}</p>
                                        </div>
                                        <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                            <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>National Permit Number:</p>
                                            <p>{national_permit_number}</p>
                                        </div>
                                        <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                            <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>National Permit Upto:</p>
                                            <p>{national_permit_upto}</p>
                                        </div>
                                        <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                            <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Norms Type:</p>
                                            <p>{norms_type}</p>
                                        </div>
                                        <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                            <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Owner Name:</p>
                                            <p>{owner_name}</p>
                                        </div>
                                        <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                            <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Rc Number:</p>
                                            <p>{rc_number}</p>
                                        </div>
                                        <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                            <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Registertion Date:</p>
                                            <p>{registration_date}</p>
                                        </div>
                                        <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                            <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Registered At:</p>
                                            <p>{registered_at}</p>
                                        </div>
                                        <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                            <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Vehicle Category:</p>
                                            <p>{vehicle_category}</p>
                                        </div>
                                        <div style={{ borderBottom: '3px solid #e4e4fa' }} className='d-flex justify-content-between'>
                                            <p style={{ fontWeight: 'bold', marginRight: '1rem' }}>Vehicle Category Description:</p>
                                            <p>{vehicle_category_description}</p>
                                        </div>
                                        {
                                            (!isView && !rcDataView) && (
                                                <div>
                                                    <button className='signupBtn' onClick={() => VehicleRcVerify()} >Submit</button>
                                                    <button className='signupBtn text-danger' >Decline</button>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={() => {
                            handleClose()
                            setOpenViewModel(false)
                            setrcDataView(false)
                            setIsActionToVerify(false)
                        }}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}

export default Rc
