import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'auto',
    color: theme.palette.text.secondary,
}));

const DriverShowData = (props) => {


    const [open, setOpen] = React.useState(false);
    const [image, setImage] = useState()

    const handleClickOpen = (image) => {
        setImage(image)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <div>
            <div>
                <Box sx={{ flexGrow: 1, p: 1 }}>


                    {
                        props.AllDriver.length > 0 && (
                            <Grid container spacing={2}>
                                <div style={{padding:10, width:'100%'}} >
                                    
                                    <Typography style={{ marginTop: '6rem', fontSize: '1.3rem', color: '#12124e', fontWeight: 'bold' }}>All Drivers Details</Typography>
                                    <Typography style={{ width: '100%', height: '0.09rem', backgroundColor: '#12124e', position: 'relative', top: '-0.2rem' }} ></Typography>
                                    
                                </div>
                                {
                                    props.AllDriver.map((data) => {
                                        return (
                                            <Grid xs={6} >
                                                <Item style={{ backgroundColor: '#12124e', borderRadius: '0.2rem' }} elevation={1}>
                                                    <Card>
                                                        <div>
                                                            <div className='p-1' style={{ backgroundColor: '#12124e', position: 'relative' }}>
                                                                <div style={{ marginTop: '-0.2rem' }} >
                                                                    <Typography style={{ fontSize: '1.4rem', color: 'white' }}><span style={{ color: 'white', fontSize: '1.13rem' }}>Driver Name: </span> {data.dName}</Typography>
                                                                    <br />
                                                                    <Typography style={{ fontSize: '0.8rem', color: 'white' }}><span style={{ color: 'white', fontSize: '0.8rem' }}>DL: </span> XXXX XXXX {data.DLNumber.substring(data.DLNumber.length + 4, 10)}</Typography>
                                                                    <br />
                                                                    <Typography style={{ fontSize: '0.8rem', color: 'white' }}><span style={{ color: 'white', fontSize: '0.8rem' }}>Mobile: </span>XX XXXX {data.dMobile.substring(data.dMobile.length + 4, 6)}</Typography>
                                                                </div>

                                                                <div className='col-12' style={{ backgroundColor: 'white', borderRadius: '0.2rem', overflow: 'hidden', width: '6rem', position: 'absolute', right: 5, bottom: 10, top: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >

                                                                    <img src={data.uplDLAndAadhar} style={{ height: '4rem' }} />

                                                                </div>
                                                                <div className='col-12' style={{ backgroundColor: 'lightgray', borderRadius: '0.2rem', overflow: 'hidden', width: '6rem', position: 'absolute', right: 5, bottom: 10, top: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', opacity: 0.6 }} >

                                                                </div>
                                                                <div onClick={()=>handleClickOpen(data.uplDLAndAadhar)} className='col-12' style={{ borderRadius: '0.2rem', overflow: 'hidden', width: '6rem', position: 'absolute', right: 5, bottom: 10, top: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'zoom-in' }} >
                                                                    <Typography style={{ border: '1px solid gray', borderRadius: '0.2rem', borderStyle: 'dashed', padding: 10, fontSize: '0.8rem' }} >DL Copy</Typography>
                                                                </div>
                                                                <div>

                                                                    <Dialog
                                                                        open={open}
                                                                        onClose={handleClose}
                                                                        aria-labelledby="alert-dialog-title"
                                                                        aria-describedby="alert-dialog-description"
                                                                    >
                                                                        <DialogTitle id="alert-dialog-title">
                                                                            {"Driver License Copy"}
                                                                        </DialogTitle>
                                                                        <DialogContent   >
                                                                            <DialogContentText style={{ border: '1px solid lightgray', borderStyle: 'dashed', borderRadius: '0.2rem' }} id="alert-dialog-description">
                                                                                <img src={image} style={{ height: '18rem' }} />
                                                                            </DialogContentText>
                                                                        </DialogContent>
                                                                        <DialogActions >

                                                                            <Button style={{ color: 'red' }} onClick={handleClose} autoFocus>
                                                                                Cancel
                                                                            </Button>
                                                                        </DialogActions>
                                                                    </Dialog>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </Item>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        )
                    }


                </Box>
                {
                    (props.AllDriver.length < 0 || props.AllDriver.length === 0) && (
                        <p style={{ position: 'relative', top: '0.7rem', textAlign: 'center', marginTop: '10rem' }}> No Driver </p>
                    )
                }

            </div>
        </div>
    )
}

export default DriverShowData