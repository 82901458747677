import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import SimpleBackdrop from '../../BackDrop/BackDrop'

const UserRegLinkVerification = () => {
    const { userType, token, cur_pass, client_id } = useParams()
    const navigate = useNavigate()
    const [isBackDrop, setIsBackDrop] = useState(false)

    const linkValidation = () => {
        setIsBackDrop(true)
        axios.post('https://pmapi.emedha.in/password/link/verification', {
            token,
        }).then((res) => {
            if (res.data.status === 200) {
                setTimeout(() => setIsBackDrop(false), 2000)
                sessionStorage.setItem('cur_pass', cur_pass)
                setTimeout(() => navigate(`/change-password/${client_id}/${userType}`), 2001)
            }
        })
    }

    useEffect(() => {
        linkValidation()
    }, [])

    return (
        <div>
            {
                isBackDrop && (
                    <SimpleBackdrop />
                )
            }
        </div>
    )
}

export default UserRegLinkVerification