import React from 'react'
import Navbar from './LBDNav/LBDNav'
import Sidebar from './Sidebar/Sidebar'

const LorryBrokerDashboard = () => {
  return (
    <div>
      <Navbar/>
      <Sidebar/>
    </div>
  )
}

export default LorryBrokerDashboard