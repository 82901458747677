import React from 'react';
import { useTimer } from 'react-timer-hook';

function MyTimer({ expiryTimestamp }) {

  const {

    seconds,
    minutes,
    hours,
    
  } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });


  return (
    <div>
      <div style={{marginTop:6, marginLeft:15}} >
        <span style={{fontSize:'0.8rem', color:'red'}}>Expired In:- </span><span style={{fontSize:'0.8rem', backgroundColor:'white', borderRadius:'0.1rem', padding:5}} >{hours} hrs</span> <span style={{fontSize:'0.8rem', backgroundColor:'white', borderRadius:'0.1rem', padding:5}} >{minutes} min</span> <span style={{fontSize:'0.8rem', backgroundColor:'white', borderRadius:'0.1rem', padding:5}}>{seconds} sec</span>
      </div>
      {/* <button onClick={start}>Start</button>
      <button onClick={pause}>Pause</button>
      <button onClick={resume}>Resume</button> */}
      {/* <button onClick={() => {
        const time = new Date();
        time.setSeconds(time.getSeconds() + 300);
        restart(time)
      }}>Restart</button> */}
    </div>
  );
}

export default MyTimer