import React from 'react'
import './index.css'
import { FaBusAlt, FaTruck, FaUsers } from 'react-icons/fa'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'Auto',
    color: theme.palette.text.secondary,
}));

const TopCard = ({ lb, lw, cstmr }) => {

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid style={{ height: '12rem', marginBottom: '0.8rem', }} item xs={3}>
                    <Item className='card1' elevation={1} sx={{ height: '9.5rem', position: 'relative' }}>
                        <h1 style={{ fontSize: 16, marginTop: 14, fontWeight: 'bold', color: '#0d0d4a' }} >
                            Retail Clients
                        </h1>
                        <div style={{ position: 'absolute', right: '0.8rem', top: '0.7rem', padding: '0.4rem', borderRadius: '0.3rem' }}>
                            <FaUsers fontSize={28} style={{ position: 'relative' }} color='skyblue' />
                        </div>
                        <p style={{ fontSize: 29, color: 'blue', fontWeight: 'bold' }}>{cstmr.length}</p>
                        <h1 style={{ fontSize: 10.3, color: 'gray', textTransform: 'uppercase', fontWeight: 'bold', marginTop: '1.4rem' }}>
                            Register All Retail Clients
                        </h1>
                    </Item>
                </Grid>
                <Grid style={{ height: '12rem', marginBottom: '0.8rem' }} item xs={3}>
                    <Item elevation={1} sx={{ height: '9.5rem', position: 'relative' }}>
                        <h1 style={{ fontSize: 16, marginTop: 14, fontWeight: 'bold', color: '#0d0d4a' }} >
                            Corporate Clients
                        </h1>
                        <div style={{ position: 'absolute', right: '0.8rem', top: '0.7rem', padding: '0.4rem', borderRadius: '0.3rem' }}>
                            <FaUsers fontSize={28} style={{ position: 'relative' }} color='skyblue' />
                        </div>
                        <p style={{ fontSize: 29, color: 'blue', fontWeight: 'bold' }}>0</p>
                        <h1 style={{ fontSize: 10.3, color: 'gray', textTransform: 'uppercase', fontWeight: 'bold', marginTop: '1.4rem' }}>
                            Register All Corporate Clients
                        </h1>
                    </Item>
                </Grid>
                <Grid style={{ height: '12rem', marginBottom: '0.8rem' }} item xs={3}>
                    <Item elevation={1} sx={{ height: '9.5rem', position: 'relative' }}>
                        <h1 style={{ fontSize: 16, marginTop: 14, fontWeight: 'bold', color: '#0d0d4a' }} >
                            Cargo Owners
                        </h1>
                        <div style={{ position: 'absolute', right: '0.8rem', top: '0.7rem', padding: '0.4rem', borderRadius: '0.3rem' }}>
                            <FaTruck fontSize={28} style={{ position: 'relative' }} color='gold' />
                        </div>
                        <p style={{ fontSize: 29, color: 'blue', fontWeight: 'bold' }}>{lw.length}</p>
                        <h1 style={{ fontSize: 10.3, color: 'gray', textTransform: 'uppercase', fontWeight: 'bold', marginTop: '1.4rem' }}>
                            Register All Cargo Owners
                        </h1>
                    </Item>
                </Grid>
                <Grid style={{ height: '12rem', marginBottom: '0.8rem' }} item xs={3}>
                    <Item elevation={1} sx={{ height: '9.5rem', position: 'relative' }}>
                        <h1 style={{ fontSize: 16, marginTop: 14, fontWeight: 'bold', color: '#0d0d4a' }} >
                            Cargo Brokers
                        </h1>
                        <div style={{ position: 'absolute', right: '0.8rem', top: '0.7rem', padding: '0.4rem', borderRadius: '0.3rem' }}>
                            <FaBusAlt fontSize={28} style={{ position: 'relative' }} color='gold' />
                        </div>
                        <p style={{ fontSize: 29, color: 'blue', fontWeight: 'bold' }}>{lb.length}</p>
                        <h1 style={{ fontSize: 10.3, color: 'gray', textTransform: 'uppercase', fontWeight: 'bold', marginTop: '1.4rem' }}>
                            Register All Cargo Brokers
                        </h1>
                    </Item>
                </Grid>
            </Grid>
        </Box>
    )

}


export default TopCard